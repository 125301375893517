import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { FetchRpaParamOptionsService } from "../../../../core/data/services/fetchRpaParamOptionsService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { SearchClassificationAssessmentService } from "../../../../core/data/services/searchClassificationAssessmentService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { ValidateMeasurementAttachmentService } from "../../../../core/data/services/validateMeasurementAttachmentService";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { ISearchClassificationAssessmentContract } from "../../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { IValidateMeasurementAttachmentContract } from "../../../../core/domain/contracts/validateMeasurementAttachmentContract";
import { BulkUploadAttachmentService } from "../data/services/bulkUploadAttachmentsService";
import { CheckDocumentNumberService } from "../data/services/checkDocumentNumberService";
import { FetchDestinationsService } from "../data/services/fetchDestinationsService";
import { FetchDocumentStatusService } from "../data/services/fetchDocumentStatusService";
import { FetchPaymentMethodsService } from "../data/services/fetchPaymentMethodsService";
import { GetProviderByIdService } from "../data/services/getProviderByIdService";
import { GetSolicitImportTemplateService } from "../data/services/getSolicitImportTemplateService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { ListRequesterAttachmentTypesService } from "../data/services/listRequesterAttachmentTypesService";
import { ProviderHasBankingDataService } from "../data/services/providerHasBankingDataService";
import { ProviderHasClassificationAccountService } from "../data/services/providerHasClassificationAccountService";
import { SaveImportDataService } from "../data/services/saveImportDataService";
import { SaveSolicitImportFileService } from "../data/services/saveSolicitImportFileService";
import { SearchCompanyPaymentAccountsService } from "../data/services/searchCompanyPaymentAccountsService";
import { SearchProviderClassificationAccountByCompanyIdService } from "../data/services/searchProviderClassificationAccountByCompanyIdService";
import { SearchProviderClassificationAccountService } from "../data/services/searchProviderClassificationAccountContract";
import { SearchProvidersService } from "../data/services/searchProvidersService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { ValidateImportDataService } from "../data/services/validateImportDataService";
import { IBulkUploadAttachmentsContract } from "../domain/contracts/bulkUploadAttachments";
import { ICheckDocumentNumberContract } from "../domain/contracts/checkDocumentNumberContract";
import { IFetchDestinationsContract } from "../domain/contracts/fetchDestinationsContract";
import { IFetchDocumentStatusContract } from "../domain/contracts/fetchDocumentStatusContract";
import { IFetchPaymentMethodsContract } from "../domain/contracts/fetchPaymentMethodsContract";
import { IGetProviderByIdContract } from "../domain/contracts/getProviderByIdContract";
import { IGetSolicitImportTemplateContract } from "../domain/contracts/getSolicitImportTemplateContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IListRequesterAttachmentTypesContract } from "../domain/contracts/listRequesterAttachmentTypes";
import { IProviderHasBankingDataContract } from "../domain/contracts/providerHasBankingDataContract";
import { IProviderHasClassificationAccountContract } from "../domain/contracts/providerHasClassificationAccountContract";
import { ISaveImportDataContract } from "../domain/contracts/saveImportDataContract";
import { ISaveSolicitImportFileContract } from "../domain/contracts/saveSolicitImportFileContract";
import { ISearchCompanyPaymentAccountsContract } from "../domain/contracts/searchCompanyPaymentAccountsContract";
import { ISearchProviderClassificationAccountByCompanyIdContract } from "../domain/contracts/searchProviderClassificationAccountByCompanyIdContract";
import { ISearchProviderClassificationAccountContract } from "../domain/contracts/searchProviderClassificationAccountContract";
import { ISearchProvidersContract } from "../domain/contracts/searchProvidersContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";
import { IValidateImportDataContract } from "../domain/contracts/validateImportDataContract";

export type MakeSolicitImport = IGetSolicitImportTemplateContract &
  ISaveSolicitImportFileContract &
  IFetchPaymentMethodsContract &
  IFetchDocumentStatusContract &
  ISearchProviderClassificationAccountContract &
  ISearchCompanyPaymentAccountsContract &
  ICheckDocumentNumberContract &
  ISearchProvidersContract &
  IUploadAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListRequesterAttachmentTypesContract &
  IUpdateAttachmentsBarcodeContract &
  IFetchRpaParamOptionsContract &
  IBulkUploadAttachmentsContract &
  IValidateImportDataContract &
  IGetProviderByIdContract &
  IProviderHasBankingDataContract &
  IProviderHasClassificationAccountContract &
  ISaveImportDataContract &
  IFetchDestinationsContract &
  ISearchClassificationAssessmentContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchProjectContract &
  ISearchProviderClassificationAccountByCompanyIdContract &
  IValidateMeasurementAttachmentContract;

export function makeSolicitImport(
  api: IApiService,
  barcodeApi: IApiService,
): MakeSolicitImport {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const getDebtImportTemplateService = new GetSolicitImportTemplateService(
    getUserLocalService,
    api,
  );

  const saveDebtImportFileService = new SaveSolicitImportFileService(
    getUserLocalService,
    api,
  );

  const fetchPaymentMethodsService = new FetchPaymentMethodsService(
    getUserLocalService,
    api,
  );

  const fetchDocumentStatusService = new FetchDocumentStatusService(
    getUserLocalService,
    api,
  );

  const searchCompanyPaymentAccountsService =
    new SearchCompanyPaymentAccountsService(getUserLocalService, api);

  const searchProviderService = new SearchProvidersService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountService =
    new SearchProviderClassificationAccountService(getUserLocalService, api);

  const checkDocumentNumberService = new CheckDocumentNumberService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const uploadAttachmentService = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const updateAttachmentsBarcodeService = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    api,
    barcodeApi,
  );

  const fetchRpaParamOptionsService = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const bulkUploadAttachments = new BulkUploadAttachmentService(
    getUserLocalService,
    api,
  );

  const validateImportDataService = new ValidateImportDataService(
    getUserLocalService,
    api,
  );

  const providerHasClassificationAccountService =
    new ProviderHasClassificationAccountService(getUserLocalService, api);

  const getProviderByIdService = new GetProviderByIdService(
    getUserLocalService,
    api,
  );

  const providerHasBankingDataService = new ProviderHasBankingDataService(
    getUserLocalService,
    api,
  );

  const saveImportDataService = new SaveImportDataService(
    getUserLocalService,
    api,
  );

  const fetchDestinationsService = new FetchDestinationsService(
    getUserLocalService,
    api,
  );

  const listRequesterAttachmentTypesService =
    new ListRequesterAttachmentTypesService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountByCompanyIdService =
    new SearchProviderClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );
  const validateMeasurementAttachmentService =
    new ValidateMeasurementAttachmentService(getUserLocalService, api);

  return {
    getSolicitImportTemplate() {
      return getDebtImportTemplateService.getSolicitImportTemplate();
    },
    saveSolicitImportFile(file) {
      return saveDebtImportFileService.saveSolicitImportFile(file);
    },
    fetchPaymentMethods() {
      return fetchPaymentMethodsService.fetchPaymentMethods();
    },
    fetchDocumentStatus() {
      return fetchDocumentStatusService.fetchDocumentStatus();
    },
    checkDocumentNumber({
      companyGroupId,
      documentNumber,
      providerId,
      paymentRequestId,
    }) {
      return checkDocumentNumberService.checkDocumentNumber({
        companyGroupId,
        documentNumber,
        providerId,
        paymentRequestId,
      });
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listRequesterAttachmentTypes() {
      return listRequesterAttachmentTypesService.listRequesterAttachmentTypes();
    },
    uploadAttachments(params) {
      return uploadAttachmentService.uploadAttachments(params);
    },
    updateAttachmentsBarcode(companyGroupId, billetAttachment) {
      return updateAttachmentsBarcodeService.updateAttachmentsBarcode(
        companyGroupId,
        billetAttachment,
      );
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsService.fetchRpaParamOptions();
    },
    bulkUploadAttachments(params) {
      return bulkUploadAttachments.bulkUploadAttachments(params);
    },
    validateImportData(data) {
      return validateImportDataService.validateImportData(data);
    },
    searchCompanyPaymentAccounts(
      companyGroupId,
      companyId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchCompanyPaymentAccountsService.searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProviders(search, companyGroupId, active) {
      return searchProviderService.searchProviders(
        search,
        companyGroupId,
        active,
      );
    },
    searchProviderClassificationAccount(
      companyGroupId,
      providerId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return searchProviderClassificationAccountService.searchProviderClassificationAccount(
        companyGroupId,
        providerId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    getProviderById(providerId) {
      return getProviderByIdService.getProviderById(providerId);
    },
    providerHasBankingData(providerId) {
      return providerHasBankingDataService.providerHasBankingData(providerId);
    },
    providerHasClassificationAccount(companyGoupId, providerId) {
      return providerHasClassificationAccountService.providerHasClassificationAccount(
        companyGoupId,
        providerId,
      );
    },
    saveImportData(params) {
      return saveImportDataService.saveImportData(params);
    },
    fetchDestinations() {
      return fetchDestinationsService.fetchDestinations();
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchProviderClassificationAccountByCompanyId(
      providerId,
      companyId,
      search,
    ) {
      return searchProviderClassificationAccountByCompanyIdService.searchProviderClassificationAccountByCompanyId(
        providerId,
        companyId,
        search,
      );
    },
    validateMeasurementAttachment(value, measurementAttachment) {
      return validateMeasurementAttachmentService.validateMeasurementAttachment(
        value,
        measurementAttachment,
      );
    },
  };
}
