import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { CompetencyEntity } from "../entities/competencyEntity";

export interface IListCompetenciesService {
  listCompetencies(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<CompetencyEntity[]>>;
}

export interface IListCompetenciesUseCase {
  listCompetencies(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<CompetencyEntity[]>>;
}

export class ListCompetencyUseCase implements IListCompetenciesUseCase {
  constructor(private listCompetencyService: IListCompetenciesService) {}

  listCompetencies(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<CompetencyEntity[]>> {
    return this.listCompetencyService.listCompetencies(
      companyGroupId,
      payload,
      actives,
    );
  }
}
