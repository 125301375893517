import { IEntity } from "../../../../../core/domain/entities/entity";
import { IEnum } from "../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { ISolicitImportAssessmentEntity } from "./solicitImportAssessmentEntity";
import { ISolicitImportAttachmentEntity } from "./solicitImportAttachmentEntity";
import {
  ESolicitImportDestination,
  ESolicitImportStatus,
} from "./solicitImportEnums";

export interface ISolicitImportEntity extends IEntity {
  accountAlreadyExists: boolean;
  assessments: ISolicitImportAssessmentEntity[];
  barcode: string;
  company: ITypeaheadOption | null;
  classificationAccount: ITypeaheadOption | null;
  description: string;
  destination: ESolicitImportDestination;
  documentNumber: string;
  documentStatus: IEnum | null;
  getCompanyHasProject: boolean;
  getCompetencyIsUsp: boolean;
  hasAssessmentError: boolean;
  hasError: boolean;
  issueDate: string;
  observation: string;
  payUntil: string;
  paymentMethod: IEnum | null;
  provider: ITypeaheadOption | null;
  status: ESolicitImportStatus;
  storageFiles: ISolicitImportAttachmentEntity[];
  value: number;
  valueAssessmentLeft: number;
  fieldErrors: {
    [key in keyof Omit<
      ISolicitImportEntity,
      | "fieldErrors"
      | "accountAlreadyExists"
      | "accountAlreadyPaid"
      | "getCompanyHasProject"
      | "getCompetencyIsUsp"
      | "hasAssessmentError"
      | "hasError"
    >]?: string;
  };
}

export class SolicitImportEntity implements ISolicitImportEntity {
  id = "";
  active = false;
  accountAlreadyExists = false;
  assessments = [];
  barcode = "";
  classificationAccount = null;
  company = null;
  description = "";
  destination = ESolicitImportDestination.Financial;
  documentNumber = "";
  documentStatus = null;
  getCompanyHasProject = false;
  getCompetencyIsUsp = false;
  hasAssessmentError = false;
  hasError = false;
  issueDate = "";
  observation = "";
  payUntil = "";
  paymentMethod = null;
  project = null;
  provider = null;
  status = ESolicitImportStatus.NotRequested;
  storageFiles = [];
  value = 0;
  valueAssessmentLeft = 0;
  fieldErrors = {};

  constructor(init?: Partial<ISolicitImportEntity>) {
    Object.assign(this, init);
  }

  create(init?: Partial<ISolicitImportEntity>) {
    return new SolicitImportEntity(init);
  }
}
