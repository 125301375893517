import {
  Accordion,
  AccordionEventParams,
  AccordionTab,
} from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useState } from "react";
import { IApiService } from "../../../../core/data/services/apiService";
import { MakeCore, makeCore } from "../../../../core/main/makeCore";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import {
  MakeClassificationAccounts,
  makeClassificationAccounts,
} from "../../../main/makeClassificationAccounts";
import { ClassificationAccountsTab } from "../ClassificationAccountsTab";
import { Container } from "./style";

interface ClassificationAccountsPageProps {
  useClassficationAccounts: MakeClassificationAccounts;
  useCore: MakeCore;
}

function ClassificationAccountsPage({
  useCore,
  useClassficationAccounts,
}: ClassificationAccountsPageProps) {
  const { searchCompany } = useClassficationAccounts;

  const [isPageLoading, setIsPageLoading] = useState(false);

  const [companyData, setCompanyData] = useState<ICompanyEntity[]>();

  const getListCompany = useCallback(async () => {
    setIsPageLoading(true);
    try {
      const data = await searchCompany();
      setCompanyData(data);
    } finally {
      setIsPageLoading(false);
    }
  }, [searchCompany]);

  useEffect(() => {
    getListCompany();
  }, [getListCompany]);

  const [activeIndex, setActiveIndex] = useState<number[]>([]);

  const tabHeaderTemplate = useCallback(
    (company: ICompanyEntity, index: number) => {
      const isActive = activeIndex.includes(index);

      return (
        <>
          <span>{company.assumedName}</span>
          <i className={`pi pi-chevron-${isActive ? "down" : "right"}`} />
        </>
      );
    },
    [activeIndex],
  );

  // Necessária transformação para number[] por typagem errada no Prime React
  const handleOnTabChange = (e: AccordionEventParams) => {
    setActiveIndex(e.index as unknown as number[]);
  };

  return (
    <Container>
      {isPageLoading ? (
        <div className="loading-container">
          <ProgressSpinner strokeWidth="6" />
        </div>
      ) : (
        <Accordion
          multiple
          activeIndex={activeIndex}
          onTabChange={handleOnTabChange}
          expandIcon={null}
          collapseIcon={null}
        >
          {companyData?.map((company, index) => {
            return (
              <AccordionTab
                key={company.id}
                header={tabHeaderTemplate(company, index)}
              >
                <article className="no-padding fill-height">
                  <ClassificationAccountsTab
                    company={company}
                    useClassficationAccounts={useClassficationAccounts}
                    useCore={useCore}
                  />
                </article>
              </AccordionTab>
            );
          })}
        </Accordion>
      )}
    </Container>
  );
}

interface ClassificationAccountsPageFactoryProps {
  api: IApiService;
}

export function ClassificationAccountsPageFactory({
  api,
}: ClassificationAccountsPageFactoryProps) {
  return (
    <ClassificationAccountsPage
      useClassficationAccounts={makeClassificationAccounts(api)}
      useCore={makeCore(api)}
    />
  );
}
