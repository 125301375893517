import {
  EClassificationAccountType,
  IClassificationAccountEntity,
  EClassificationAccountIssueType,
} from "./classificationAccountEntity";

export type IClassificationAccountFormEntity = Pick<
  IClassificationAccountEntity,
  "code" | "name" | "callNumber"
> & {
  type: EClassificationAccountType | string;
  issueType: EClassificationAccountIssueType | string;
};

export class ClassificationAccountFormEntity
  implements IClassificationAccountFormEntity
{
  name = "";
  code = "";
  callNumber = "";
  type = "";
  issueType = "";

  constructor(init?: Partial<IClassificationAccountFormEntity>) {
    Object.assign(this, init);
  }

  static create(params?: Partial<IClassificationAccountEntity>) {
    return new ClassificationAccountFormEntity(params);
  }
}
