import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../../core/presentation/hooks/useDebounceTime";
import {
  IFormComponentProps,
  ISolicitImportForm,
  ITypeaheadState,
} from "../../../../domain/entities/solicitImportTypes";
import { useSolicitImportPage } from "../../../hooks/useSolicitImportPage";
import { FieldWrapper } from "../../FieldWrapper";

export function ClassificationAccountField(props: IFormComponentProps) {
  const { formIndex } = props;

  const fieldKey = `imports.${formIndex}.classificationAccount` as const;

  const debounceTime = useDebounceTimeAsync();
  const { handleInputClassName, useDebtImport } = useSolicitImportPage();
  const { getValues } = useFormContext<ISolicitImportForm>();

  const { searchProviderClassificationAccountByCompanyId } = useDebtImport;

  const [state, setState] = useState<ITypeaheadState>({
    options: [],
    loading: false,
  });

  const handleClassificationAccountSearch = async (search = "") => {
    const provider = getValues(`imports.${formIndex}.provider`);
    const company = getValues(`imports.${formIndex}.company`);
    const providerId = (provider?.rawValue as string) || "";
    const companyId = company?.rawValue as string | "";

    if (!providerId) {
      return;
    }

    await debounceTime(750);

    setState(prevState => {
      return {
        loading: true,
        options: prevState?.options || [],
      };
    });

    try {
      const clsAccounts = await searchProviderClassificationAccountByCompanyId(
        providerId,
        companyId,
        search,
      );

      setState({
        loading: false,
        options: clsAccounts,
      });
    } finally {
      setState(prevState => {
        return {
          loading: false,
          options: prevState?.options || [],
        };
      });
    }
  };

  return (
    <Controller
      name={fieldKey}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const onChange = (event: ISoulTypeaheadChangeEvent) => {
          field.onBlur();
          field.onChange(event);
        };
        return (
          <label className="form-control">
            <span>
              Classificação Contábil{" "}
              {state?.loading && <i className="pi pi-spin pi-spinner" />}
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <SoulTypeahead
                serverSide
                openOnFocus
                onChange={onChange}
                value={field.value}
                loading={state.loading}
                options={state?.options || []}
                placeholder="Classificação Contábil"
                id={`txt-classificationAccount-${formIndex}`}
                onSearchChange={handleClassificationAccountSearch}
                data-testid={`txt-classificationAccount-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={fieldState?.error?.type === "required"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
