import { GERAL_COMPANY_ID } from "../../../classificationAccount/domain/entities/companyEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationAccountLinkedContract } from "../../domain/contracts/getClassificationAccountLinkedContract";

import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";
import { IClassificationAccountLinkedEntity } from "../../domain/entities/classificationAccountLinkedEntity";

export class GetClassificationAccountLinkedService
  implements IGetClassificationAccountLinkedContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getClassificationAccountLinked(paymentAccountId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/PaymentAccounts/${paymentAccountId}/ClassificationAccounts`;

    const response = await this.api.get<IClassificationAccountLinkedEntity>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const newResponse: IClassificationAccountEntity = {
      name: response.name,
      type: response.type,
      issueType: response.issueType,
      code: response.code,
      callNumber: response.callNumber,
      companyGroupName: response.companyGroupName,
      companyId: response.companyId ? response.companyId : GERAL_COMPANY_ID,
      active: response.active,
      id: response.id,
      typeDescription: response.typeDescription,
    };

    return newResponse;
  }
}
