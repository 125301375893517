import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { ITypeaheadOption } from "../../../../../core/domain/entities/typeaheadOption";
import { IUserEntity } from "../../../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../../core/domain/usecases/setServerSideUseCase";
import { ISearchProviderClassificationAccountByCompanyIdContract } from "../../domain/contracts/searchProviderClassificationAccountByCompanyIdContract";

interface IClassificationAccountResponse {
  id: string;
  name: string;
  companyId: string;
}

export class SearchProviderClassificationAccountByCompanyIdService
  implements ISearchProviderClassificationAccountByCompanyIdContract
{
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async searchProviderClassificationAccountByCompanyId(
    providerId: string,
    companyId: string,
    search = "",
  ): Promise<ITypeaheadOption[]> {
    const companyClassificationAccount =
      await this.getClassificationAccountByCompanyId(companyId, search);

    const urlProvider = `/Providers/${providerId}/ClassificationAccounts`;

    const response = await this.api.get<IClassificationAccountResponse[]>(
      urlProvider,
      {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      },
    );

    const providerClassificationAccount = response.map(clsAccount => {
      return {
        label: clsAccount.name,
        rawValue: clsAccount.id,
        metadata: clsAccount,
      };
    });

    let listA = companyClassificationAccount;
    let listB = providerClassificationAccount;

    if (
      providerClassificationAccount.length <=
      companyClassificationAccount.length
    ) {
      listA = providerClassificationAccount;
      listB = companyClassificationAccount;
    }

    const filteredList = listA.filter(obj =>
      listB.some(item => item.rawValue === obj.rawValue),
    );

    const geralClassificationAccount = response
      .filter(clsAccount => clsAccount.companyId === null)
      .map(clsAccount => ({
        label: clsAccount.name,
        rawValue: clsAccount.id,
        metadata: clsAccount,
      }));

    const classificaitonAccounts = [
      ...filteredList,
      ...geralClassificationAccount,
    ];

    return classificaitonAccounts;
  }

  private async getClassificationAccountByCompanyId(
    companyId: string,
    search: string,
  ) {
    const newPayload = {
      draw: 10,
      start: 0,
      length: 50,
      order: [],
      search: {
        regex: false,
        value: search,
      },
      columns: [
        {
          data: "companyGroupName",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "name",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "type",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "issueType",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "code",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "callNumber",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "active",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "",
          },
        },
        {
          data: "companyId",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: companyId,
          },
        },
      ],
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsBody = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsBody);

    const urlCompany = `/Companies/ClassificationAccounts?${params}`;

    const companyResponse = await this.api.get<
      IServerSideResponseModel<IClassificationAccountResponse[]>
    >(urlCompany, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    const classificationAccountByCompany = companyResponse.data.map(
      clsAccount => {
        return {
          label: clsAccount.name,
          rawValue: clsAccount.id,
          metadata: clsAccount,
        };
      },
    );

    return classificationAccountByCompany;
  }
}
