import { useCallback, useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { CompetencyEntity } from "../../domain/entities/competencyEntity";

interface UseCompetencyGridParams {
  openEditModal?(id: string): void;
  toggle?(id: string): Promise<void> | void;
}

export function useCompetencyGrid({
  openEditModal,
  toggle,
}: UseCompetencyGridParams) {
  const editBodyTemplate = useCallback(
    ({ id }: CompetencyEntity, { rowIndex }) => {
      return (
        <div className="button-wrapper">
          <button
            data-tip="Editar competência"
            type="button"
            className="outline-button"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openEditModal?.(id);
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [openEditModal],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: CompetencyEntity, { rowIndex }) => {
      const props: Record<string, string> = {};

      return (
        <div {...props}>
          <Toggle
            value={active}
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            inactiveClassName="toggle-muted"
            onChange={async _active => {
              const text = !_active ? "inativada" : "ativada";
              const result = await dialog.fire({
                icon: "question",
                title: "Você está certo disso?",
                html: `A Competência será ${text}. <br /> Deseja prosseguir?`,
                showCancelButton: true,
                cancelButtonText: "Não",
                preConfirm() {
                  dialog.update({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  dialog.showLoading();
                  return toggle?.(id);
                },
              });

              if (result.isConfirmed) {
                return _active;
              }

              return !_active;
            }}
          />
        </div>
      );
    },
    [dialog, toggle],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        width: "20%",
      },
      {
        field: "name",
        header: "Nome",
        searchable: true,
        orderable: true,
      },
      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "isUspDescription",
        hidden: true,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "activeDescription",
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
