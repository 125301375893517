import { GERAL_COMPANY_ID } from "../../../classificationAccount/domain/entities/companyEntity";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IGetClassificationAccountLinkedContract } from "../../domain/contracts/getClassificationAccountLinkedContract";

import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";
import { IClassificationAccountLinkedEntity } from "../../domain/entities/classificationAccountLinkedEntity";

export class GetClassificationAccountLinkedService
  implements IGetClassificationAccountLinkedContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async getClassificationAccountLinked(providerId: string) {
    const userEntity = this.getUserLocalService.get();

    const url = `/Providers/${providerId}/ClassificationAccounts`;

    const response = await this.api.get<IClassificationAccountLinkedEntity[]>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    const newResponse: Array<IClassificationAccountEntity> = response.map(
      (element: IClassificationAccountEntity) => {
        return {
          name: element.name,
          type: element.type,
          issueType: element.issueType,
          code: element.code,
          callNumber: element.callNumber,
          companyGroupName: element.companyGroupName,
          companyId: element.companyId ? element.companyId : GERAL_COMPANY_ID,
          active: element.active,
          id: element.id,
          typeDescription: element.typeDescription,
        };
      },
    );

    return newResponse;
  }
}
