import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IListClassificationAccountByCompanyIdContract } from "../../domain/contracts/listClassificationAccountByCompanyIdContract";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";

export class ListClassificationAccountByCompanyIdService
  implements IListClassificationAccountByCompanyIdContract
{
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listClassificationAccountByCompanyId(
    payload: IPayloadEntity,
    companyId: string,
    searchTerm: string,
    selectedIds: string[],
  ) {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      ...payload,
      search: {
        ...payload.search,
        value: searchTerm,
      },
      columns: [
        ...payload.columns,
        {
          data: "companyId",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: companyId,
          },
        },
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: selectedIds.join(","),
          },
        },
        {
          data: "active",
          name: "",
          searchable: true,
          orderable: false,
          search: {
            regex: false,
            value: "true",
          },
        },
      ],
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsBody = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsBody);

    const url = `/Companies/ClassificationAccounts?${params}`;

    const response = await this.api.get<
      IResponseEntity<IClassificationAccountEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
