import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetFeeService } from "../data/services/getFeeService";
import { ListFeesService } from "../data/services/listFeesService";
import { SaveFeeService } from "../data/services/saveFeeService";
import { ToggleFeeService } from "../data/services/toggleFeeService";
import { UpdateFeeService } from "../data/services/updateFeeService";
import { SearchProvidersService } from "../data/services/searchProvidersService";
import { SearchClassificationAsssesmentService } from "../data/services/searchClassificationAssessmentService";
import { IGetFeeContract } from "../domain/contracts/getFeeContract";
import { IListFeesContract } from "../domain/contracts/listFeesContract";
import { ISaveFeeContract } from "../domain/contracts/saveFeeContract";
import { ISearchClassificationAssessmentContract } from "../domain/contracts/searchClassificationAssessmentContract";
import { ISearchProvidersContract } from "../domain/contracts/searchProvidersContract";
import { IToggleFeeContract } from "../domain/contracts/toggleFeeContract";
import { IUpdateFeeContract } from "../domain/contracts/updateFeeContract";
import { IListFeeExpirationTypesContract } from "../domain/contracts/listFeeExpirationTypesContract";
import { ListFeeExpirationTypesService } from "../data/services/listFeeExpirationTypesService";
import { ExportFeesService } from "../data/services/exportFeesService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { IExportFeesContract } from "../domain/contracts/exportFeesContract";

export type MakeFee = IGetFeeContract &
  IListFeesContract &
  IToggleFeeContract &
  ISaveFeeContract &
  IUpdateFeeContract &
  ISearchProvidersContract &
  ISearchClassificationAssessmentContract &
  IListFeeExpirationTypesContract &
  IExportFeesContract;

export function makeFee(api: IApiService): MakeFee {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const getFeeService = new GetFeeService(getUserLocalService, api);

  const listFeesService = new ListFeesService(getUserLocalService, api);

  const toggleFeeService = new ToggleFeeService(getUserLocalService, api);

  const saveFeeService = new SaveFeeService(getUserLocalService, api);

  const updateFeeService = new UpdateFeeService(getUserLocalService, api);

  const searchProvidersService = new SearchProvidersService(
    getUserLocalService,
    api,
  );

  const searchClassificationAssessmentService =
    new SearchClassificationAsssesmentService(getUserLocalService, api);

  const listFeeExpirationTypesService = new ListFeeExpirationTypesService(
    getUserLocalService,
    api,
  );

  const exportFeesService = new ExportFeesService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  return {
    getFee(feeId) {
      return getFeeService.getFee(feeId);
    },
    listFees(companyGroupId, payload, actives) {
      return listFeesService.listFees(companyGroupId, payload, actives);
    },
    toggleFee(feeId: string) {
      return toggleFeeService.toggleFee(feeId);
    },
    saveFee(fee) {
      return saveFeeService.saveFee(fee);
    },
    updateFee(fee) {
      return updateFeeService.updateFee(fee);
    },
    searchProviders(search, companyGroupId, active) {
      return searchProvidersService.searchProviders(
        search,
        companyGroupId,
        active,
      );
    },
    searchClassificationAssessment(search, companyGroupId) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        search,
        companyGroupId,
      );
    },
    listFeeExpirationTypes() {
      return listFeeExpirationTypesService.listFeeExpirationTypes();
    },
    exportFees(params) {
      return exportFeesService.exportFees(params);
    },
  };
}
