import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .loading-container {
    text-align: center;
  }

  .no-padding {
    padding: 0;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .accordion-tab-header {
    display: flex;
    padding: 1.25rem;
  }

  .p-accordion {
    margin-bottom: 2rem;

    .p-accordion-content {
      padding: unset;
    }

    .p-accordion-header-text {
      gap: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 600;
      color: var(--soul-color01);
    }
  }
`;
