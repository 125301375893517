import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICustomerEntity } from "../entities/customerEntity";

export interface IListCustomersUseCase {
  listCustomers(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<ICustomerEntity[]>>;
}

export interface IListCustomersService {
  listCustomers(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<ICustomerEntity[]>>;
}

export class ListCustomersUseCase implements IListCustomersUseCase {
  constructor(private listCustomersService: IListCustomersService) {}

  listCustomers(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ) {
    return this.listCustomersService.listCustomers(
      companyGroupId,
      payload,
      actives,
    );
  }
}
