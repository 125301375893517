import { useMemo } from "react";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";

export function useClassificationAccountGrid() {
  const columns = useMemo<SimpleTableColumn[]>(
    () =>
      [
        {
          field: "checkbox",
          header: "",
          orderable: false,
          selectionMode: "multiple",
          style: {
            flex: "0 0 3.5rem",
            paddingLeft: "1rem",
          },
        },
        {
          field: "callNumber",
          header: "Chamada",
          orderable: false,
          searchable: true,
        },
        {
          field: "name",
          header: "Nome",
          orderable: false,
          searchable: true,
        },
        {
          field: "typeDescription",
          header: "Tipo",
          orderable: false,
          sortField: "type",
          searchable: true,
        },
        {
          field: "type",
          hidden: true,
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [],
  );

  return { columns };
}
