import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { IconTooltip } from "../../../../../../core/presentation/components/IconTooltip";
import { InputBalance } from "../../../../../../core/presentation/components/InputBalance";
import { InputDate } from "../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDateValidator } from "../../../../../../core/presentation/hooks/useDateValidator";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import {
  ECustomerDocumentType,
  ICustomerEntity,
} from "../../../../../../customer/domain/entities/customerEntity";
import { MakeCustomer } from "../../../../../../customer/main/makeCustomer";
import { ClassificationAccountLinkFormModal } from "../../../../../../customer/presentation/components/ClassificationAccountLinkFormModal";
import { CustomerFormModal } from "../../../../../../customer/presentation/components/CustomerFormModal";
import {
  EAccountReceivablePaymentMethod,
  EFormMode,
} from "../../../domain/entities/accountReceivableEnums";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";
import { useParcelsGenerator } from "../../hooks/useParcelsGenerator";
import { ContainerSection } from "./styles";
import { useDocumentFormatter } from "../../../../../../core/presentation/hooks/useDocumentFormatter";

interface ISectionAccountInfoState {
  customerModalOpen: boolean;
  newCustomer?: ICustomerEntity;
  classificationModalOpen: boolean;
  documentNumber: {
    loading: boolean;
  };
  customer: {
    options: ITypeaheadOption[] | undefined;
    loading: boolean;
  };
}

interface ISectionAccountInfoProps {
  mode: EFormMode;
  readonly: boolean;
  useCustomer: MakeCustomer;
  useReceivableForm: MakeAccountsReceivableForm;
  options: {
    paymentMethods: IEnum[];
  };
}

export function SectionAccountInfo(props: ISectionAccountInfoProps) {
  const {
    mode,
    readonly,
    useCustomer,
    useReceivableForm,
    options: { paymentMethods },
  } = props;

  const { listLinkedClassificationsAccount } = useCustomer;

  const {
    searchCustomer,
    getCustomerById,
    checkDocumentNumber,
    customerHasClassificationAccount,
  } = useReceivableForm;

  const [state, setState] = useState<ISectionAccountInfoState>({
    newCustomer: undefined,
    customerModalOpen: false,
    classificationModalOpen: false,
    documentNumber: {
      loading: false,
    },
    customer: {
      options: [],
      loading: false,
    },
  });

  const invalidDate = useDateValidator();
  const debounceTime = useDebounceTimeAsync();
  const parcelGenerator = useParcelsGenerator();
  const documentFormatter = useDocumentFormatter();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountReceivableFormEntity>();
  const { receivableId } = useParams<"receivableId">();

  const {
    watch,
    control,
    trigger,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = form;

  const customer = watch("customer");
  const paymentMethod = watch("paymentMethod");
  const companyId = watch("company.rawValue") as string;

  const value = watch("value");
  const initialReceipt = watch("initialReceipt");
  const numberOfParcels = watch("numberOfParcels");
  const parcelList = watch("accountReceivableParcels");

  const customerId = customer?.rawValue as string | undefined;

  const methodCantParcel =
    !!paymentMethod?.key &&
    paymentMethod.key !== EAccountReceivablePaymentMethod.CreditCard;

  const disabledParcelField = ![EFormMode.Create, EFormMode.Edit].includes(
    mode,
  );

  const isNumberOfParcelsDisabled = disabledParcelField || methodCantParcel;

  const validateDocumentNumber = useCallback(
    async (documentNumberValue: string) => {
      await debounceTime(750);

      if (!companyId || !documentNumberValue) {
        return true;
      }

      setState(prevState => ({
        ...prevState,
        documentNumber: {
          ...prevState.documentNumber,
          loading: true,
        },
      }));

      let success = true;

      try {
        await checkDocumentNumber({
          companyId,
          documentNumber: documentNumberValue,
          receivableId: mode === EFormMode.Create ? "" : receivableId,
        });
      } catch {
        success = false;
      } finally {
        setState(prevState => ({
          ...prevState,
          documentNumber: {
            ...prevState.documentNumber,
            loading: false,
          },
        }));
      }

      return success;
    },
    [debounceTime, companyId, checkDocumentNumber, mode, receivableId],
  );

  const openClassificationAccountLinkModal = (newCustomer: ICustomerEntity) => {
    setState(prevState => ({
      ...prevState,
      newCustomer,
      classificationModalOpen: true,
    }));
  };

  const handleCloseCustomerModal = () => {
    setState(prevState => ({
      ...prevState,
      customerModalOpen: false,
    }));
  };

  const handleCustomerCreated = async (newCustomer: ICustomerEntity) => {
    const companyGroupId = currentCompanyGroup.id;

    const clsAccountList = await listLinkedClassificationsAccount(
      companyGroupId,
      newCustomer.id,
    );

    const { id, documentType, document, name } = newCustomer;

    const isOtherDocType = documentType === ECustomerDocumentType.other;

    const docValue = isOtherDocType ? document : documentFormatter(document);

    const label = `${name} ${document ? `- ${docValue}` : ""}`.trim();

    setValue(
      "customer",
      {
        label,
        rawValue: id,
        metadata: newCustomer,
      },
      {
        shouldValidate: true,
      },
    );

    if (Array.isArray(clsAccountList.data) && clsAccountList.data.length) {
      return;
    }

    openClassificationAccountLinkModal(newCustomer);
  };

  const handleCloseClassificationModal = (hasLinked = false) => {
    setState(prevState => ({
      ...prevState,
      newCustomer: undefined,
      classificationModalOpen: false,
    }));

    if (!hasLinked) {
      setValue("customer", null, { shouldValidate: true });
      setValue("classificationAccount", null, { shouldValidate: true });
    }
  };

  const handleNotFoundOptionSelected = () => {
    setState(prevState => ({
      ...prevState,
      customerModalOpen: true,
    }));
  };

  const handleSearchCustomer = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        loading: true,
      },
    }));

    const companyGroupId = currentCompanyGroup.id;

    try {
      const response = await searchCustomer(companyGroupId, search, 100, true);

      const customers = response.data;

      setState(prevState => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          options: customers,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          loading: false,
        },
      }));
    }
  };

  const handleCustomerChange = async (event: ISoulTypeaheadChangeEvent) => {
    const selectedCustomer = event?.target?.value;

    setValue("classificationAccount", null, { shouldValidate: true });

    trigger("documentNumber");

    if (!selectedCustomer) {
      return;
    }

    const selectedCustomerId = selectedCustomer.rawValue as string;

    setState(prevState => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        loading: true,
      },
    }));

    const companyGroupId = currentCompanyGroup.id;

    try {
      const customerHasClsAccount = await customerHasClassificationAccount(
        companyGroupId,
        selectedCustomerId,
      );

      if (customerHasClsAccount) {
        return;
      }

      const customerEntity = await getCustomerById(selectedCustomerId);

      openClassificationAccountLinkModal(customerEntity);
    } finally {
      setState(prevState => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          loading: false,
        },
      }));
    }
  };

  const handleEditCustomer = () => {
    if (!customer) {
      return;
    }

    setState(prevState => ({ ...prevState, customerModalOpen: true }));
  };

  const isCustomerDisabled = useMemo(() => {
    const customerIdFromApi = getValues("customer.rawValue");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!customerIdFromApi,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isDocumentNumberDisabled = useMemo(() => {
    const documentNumber = getValues("documentNumber");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!documentNumber,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isValueDisabled = useMemo(() => {
    const isValueNotZero = getValues("value") !== 0;

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: true,
      [EFormMode.ParcelsBlocked]: true,
      [EFormMode.EditAttachments]: true,
      [EFormMode.Conditional]: isValueNotZero,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  return (
    <Card>
      <header>Informações do lançamento</header>
      <ContainerSection>
        <div className="form-row">
          <label className="col-8 form-control">
            <span className="with-tooltip">
              Cliente
              <IconTooltip
                position="right"
                icon="pi pi-question-circle"
                title="Cliente"
                text="Você pode pesquisar pela Razão Social, Nome Fantasia ou pelo
                  documento (CPF/CNPJ sem pontuação)."
              />
              {state.customer.loading && (
                <span>
                  <i className="pi pi-spin pi-spinner" />
                </span>
              )}
            </span>
            <Controller
              control={control}
              name="customer"
              rules={{
                validate: {
                  required: val => !!val?.rawValue,
                },
              }}
              render={({ field, fieldState }) => {
                const onChange = (event: ISoulTypeaheadChangeEvent) => {
                  field.onChange(event);
                  handleCustomerChange(event);
                };

                return (
                  <div className="customer-field">
                    <SoulTypeahead
                      serverSide
                      id="txt-customer"
                      value={field.value}
                      onChange={onChange}
                      placeholder="Cliente"
                      data-testid="txt-customer"
                      disabled={isCustomerDisabled}
                      options={state.customer.options}
                      loading={state.customer.loading}
                      onSearchChange={handleSearchCustomer}
                      notFoundOptionLabel="+ Adicionar Cliente"
                      onNotFoundOptionSelected={handleNotFoundOptionSelected}
                      className={fieldState?.error ? "isInvalid" : undefined}
                    />
                    <button
                      type="button"
                      data-effect="solid"
                      id="btn-edit-customer"
                      data-tip="Editar Cliente"
                      data-testid="btn-edit-customer"
                      className={`btn-edit-customer  ${
                        !customer ? "disabled" : ""
                      }`}
                      onClick={handleEditCustomer}
                    >
                      <FaEdit />
                    </button>
                  </div>
                );
              }}
            />
            <InvalidFeedback
              message="Este campo é obrigatório"
              condition={(errors?.customer as FieldError)?.type === "required"}
            />
          </label>
          <label className="col-4 form-control">
            <span>
              N° documento{" "}
              {state.documentNumber.loading && (
                <i className="pi pi-spin pi-spinner" />
              )}
            </span>
            <input
              type="text"
              id="documentNumber"
              placeholder="N° documento"
              data-testid="txt-documentNumber"
              disabled={isDocumentNumberDisabled}
              className={errors.documentNumber ? "isInvalid" : ""}
              {...register("documentNumber", {
                validate: {
                  required: txtValue => !!txtValue,
                  existingDocument: validateDocumentNumber,
                },
              })}
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.documentNumber?.type === "existingDocument"}
              message="Este nº de documento já existe para esse cliente."
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Descrição <small>(opcional)</small>
            </span>
            <Controller
              name="description"
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };

                return (
                  <input
                    {...field}
                    disabled={readonly}
                    onChange={onChange}
                    id="txt-description"
                    placeholder="Descrição"
                    data-testid="txt-description"
                  />
                );
              }}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-3 form-control">
            <span>Valor</span>
            <Controller
              control={control}
              name="value"
              rules={{
                min: 0.01,
              }}
              render={({ field, fieldState }) => {
                const onChange = (event: number | null) => {
                  field.onChange(event);
                  parcelGenerator({
                    parcelList,
                    initialReceipt,
                    numberOfParcels,
                    value: event || 0,
                    isInconsistent: mode === EFormMode.Conditional,
                  });
                };

                return (
                  <>
                    <InputBalance
                      {...field}
                      resetToZero
                      id="txt-value"
                      onChange={onChange}
                      placeholder="000,00"
                      data-testid="txt-value"
                      disabled={isValueDisabled}
                      className={fieldState.error ? "isInvalid" : ""}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "min"}
                      message="O valor deve ser maior que zero"
                    />
                  </>
                );
              }}
            />
          </label>
          <label className="col-5 form-control">
            <span>Método de pagamento</span>
            <Controller
              control={control}
              name="paymentMethod"
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                const handleChange = (
                  event: ChangeEvent<HTMLSelectElement>,
                ) => {
                  const selVal = event.target.value;

                  const sel = paymentMethods.find(
                    option => option.key === Number(selVal),
                  );

                  field.onChange(sel);

                  if (sel?.key !== EAccountReceivablePaymentMethod.CreditCard) {
                    setValue("numberOfParcels", 1);
                    parcelGenerator({
                      value,
                      parcelList,
                      initialReceipt,
                      numberOfParcels: 1,
                      isInconsistent: mode === EFormMode.Conditional,
                    });
                  }
                };

                const selVal = field.value?.key || "";

                return (
                  <>
                    <select
                      {...field}
                      value={selVal}
                      id="txt-paymentMethod"
                      onChange={handleChange}
                      disabled={disabledParcelField}
                      data-testid="txt-paymentMethod"
                      placeholder="Método de pagamento"
                      className={fieldState.error ? "isInvalid" : ""}
                    >
                      <option value="" disabled hidden>
                        Método de pagamento
                      </option>
                      {paymentMethods.map(option => {
                        const { key } = option;
                        const optVal = option.value;

                        return (
                          <option key={key} value={key}>
                            {optVal}
                          </option>
                        );
                      })}
                    </select>
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
          <label className="col-4 form-control">
            <span>Número de parcelas</span>
            <Controller
              control={control}
              name="numberOfParcels"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventValue = event.target.value;
                  const filteredValue = eventValue.replace(/\D/g, "");
                  const finalValue = Number(filteredValue) || "";

                  field.onChange(finalValue);

                  parcelGenerator({
                    value,
                    parcelList,
                    initialReceipt,
                    numberOfParcels: finalValue,
                    isInconsistent: mode === EFormMode.Conditional,
                  });
                };

                return (
                  <>
                    <input
                      {...field}
                      maxLength={2}
                      onChange={onChange}
                      id="txt-numberOfParcels"
                      placeholder="Número de parcelas"
                      data-testid="txt-numberOfParcels"
                      disabled={isNumberOfParcelsDisabled}
                      className={fieldState?.error ? "isInvalid" : ""}
                    />
                    <InvalidFeedback
                      condition={fieldState?.error?.type === "required"}
                      message="Este campo é obrigatório"
                    />
                  </>
                );
              }}
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-3 form-control">
            <span>Dt. emissão</span>
            <InputDate
              type="text"
              id="txt-issueDate"
              placeholder="00/00/0000"
              data-testid="txt-issueDate"
              disabled={readonly}
              className={errors.issueDate ? "isInvalid" : ""}
              {...register("issueDate", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                },
              })}
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.issueDate?.type === "validDate"}
              message="Formato de data inválida"
            />
          </label>
          <label className="col-5 form-control">
            <span className="with-tooltip">
              Recebimento incial
              <IconTooltip
                position="right"
                icon="pi pi-question-circle"
                title="Recebimento inicial"
                text='Ponto de partida para a criação das parcelas. Após isso, o que
                  vale é a data de "Receber Em" de cada uma delas.'
              />
            </span>
            <InputDate
              type="text"
              id="txt-initialReceipt"
              placeholder="00/00/0000"
              disabled={disabledParcelField}
              data-testid="txt-initialReceipt"
              className={errors.initialReceipt ? "isInvalid" : ""}
              {...register("initialReceipt", {
                validate: {
                  required: dtValue => !!dtValue,
                  validDate: dtValue =>
                    !invalidDate(dtValue || "", "dd/MM/yyyy"),
                },
                onChange(event: ChangeEvent<HTMLInputElement>) {
                  parcelGenerator({
                    value,
                    parcelList,
                    numberOfParcels,
                    initialReceipt: event.target.value,
                    isInconsistent: mode === EFormMode.Conditional,
                  });
                },
              })}
            />
            <InvalidFeedback
              condition={errors.initialReceipt?.type === "required"}
              message="Este campo é obrigatório"
            />
            <InvalidFeedback
              condition={errors.initialReceipt?.type === "validDate"}
              message="Formato de data inválida"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="col-12 form-control">
            <span>
              Observação <small>(opcional)</small>
            </span>
            <Controller
              name="observation"
              render={({ field }) => {
                const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                  const eventClone = { ...event };
                  const cursorEnd = event.target.selectionEnd;
                  const eventValue = event.target.value?.toUpperCase() || "";

                  eventClone.target.value = eventValue;
                  eventClone.target.setSelectionRange(cursorEnd, cursorEnd);

                  field.onChange(eventClone);
                };
                return (
                  <input
                    {...field}
                    onChange={onChange}
                    disabled={readonly}
                    id="txt-observation"
                    placeholder="Observação"
                    data-testid="txt-observation"
                  />
                );
              }}
            />
          </label>
        </div>
      </ContainerSection>
      <CustomerFormModal
        useCustomer={useCustomer}
        currentId={customerId || ""}
        isOpen={state.customerModalOpen}
        onCustomerCreated={handleCustomerCreated}
        onRequestClose={handleCloseCustomerModal}
      />
      <ClassificationAccountLinkFormModal
        isRequired
        useCustomer={useCustomer}
        customerEntity={state.newCustomer}
        isOpen={state.classificationModalOpen}
        onRequestClose={handleCloseClassificationModal}
      />
    </Card>
  );
}
