import { StatusCodes } from "http-status-codes";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import {
  ApiService,
  IApiError,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import { EUserProfile } from "../../../../../../core/domain/entities/userEntity";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../../core/presentation/hooks/useUserLocal";
import {
  MakeProvider,
  makeProvider,
} from "../../../../../../provider/main/makeProvider";
import {
  IPaymentRequestFormEntity,
  PaymentRequestFormEntity,
} from "../../../domain/entities/paymentRequestFormEntity";
import {
  MakePaymentRequestForm,
  makePaymentRequestForm,
} from "../../../main/makePaymentRequestForm";
import { ReviewSectionAccountInfo } from "../ReviewSectionAccountInfo";
import { ReviewSectionAditionalInfo } from "../ReviewSectionAditionalInfo";
import { ReviewSectionCompanyData } from "../ReviewSectionCompanyData";
import { ReviewSectionFooter } from "../ReviewSectionFooter";
import { ReviewSectionFuspPurchaseOrderId } from "../ReviewSectionFuspInformation";
import { SectionAssessment } from "../SectionAssessment";
import { SectionAttachment } from "../SectionAttachment";
import { ReviewSectionProjectInfo } from "../ReviewSectionProjectInfo";
import { Container } from "./styles";

interface IReviewPaymentRequestPageState {
  isLoading: boolean;
  userNameCreated: string;
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
  };
}

interface IReviewPaymentRequestPageProps {
  useProvider: MakeProvider;
  usePaymentRequestForm: MakePaymentRequestForm;
}

function ReviewPaymentRequestPage(props: IReviewPaymentRequestPageProps) {
  const { usePaymentRequestForm, useProvider } = props;

  const [state, setState] = useState<IReviewPaymentRequestPageState>({
    isLoading: true,
    userNameCreated: "",
    options: {
      documentStatuses: [],
      paymentMethods: [],
    },
  });

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const { paymentRequestId } = useParams<"paymentRequestId">();
  const {
    user: { profile },
  } = useUserLocal();

  const isRequesterOrManager = [
    EUserProfile.manager,
    EUserProfile.requester,
  ].includes(profile);

  const form = useForm<IPaymentRequestFormEntity>({
    mode: "all",
    defaultValues: new PaymentRequestFormEntity(),
  });

  const { watch, reset } = form;

  const company = watch("company") as ITypeaheadOption<{
    hasProject: boolean;
  }> | null;

  const hasProject = company?.metadata?.hasProject || false;

  const {
    fetchPaymentMethods,
    fetchDocumentStatus,
    getPaymentRequestForReview,
  } = usePaymentRequestForm;

  const loadFormData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    if (!paymentRequestId) {
      navigate(SoulRoutes.PAYMENT_REQUESTS.path, { replace: true });
      return;
    }

    try {
      const response = await getPaymentRequestForReview(paymentRequestId);

      const paymentMethodOptions = await fetchPaymentMethods();
      const documentStatusOptions = await fetchDocumentStatus();

      const { paymentRequest, userNameCreated } = response;

      reset(paymentRequest);

      setState(prevState => ({
        ...prevState,
        userNameCreated,
        isLoading: false,
        options: {
          ...prevState.options,
          paymentMethods: paymentMethodOptions,
          documentStatuses: documentStatusOptions,
        },
      }));
    } catch (err) {
      const typedError = err as IApiError;

      if (!typedError?.response) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: (
            <>
              Não é possível revisar essa solicitação de pagamento.
              <br />
              Por favor, verifique os dados da solicitação e tente novamente.
            </>
          ),
        });
      } else if (typedError?.response?.status === StatusCodes.NOT_FOUND) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: (
            <>
              Solicitação de pagamento não encontrada.
              <br />
              Por favor, verifique os dados e tente novamente.
            </>
          ),
        });
      }

      navigate(SoulRoutes.PAYMENT_REQUESTS.path, { replace: true });
    }
  }, [
    reset,
    dialog,
    navigate,
    paymentRequestId,
    fetchDocumentStatus,
    fetchPaymentMethods,
    getPaymentRequestForReview,
  ]);

  useEffect(() => {
    /**
     * UGLY- Usuários de perfil Supervisor e Gestor não devem ter acesso a rota
     * de revisão de solicitação de pagamento.
     * Isso deve ser feito pelo componente <GuardedRoute />, não sendo
     * responsabilidade do componente de lidar com isso.
     */
    if (isRequesterOrManager) {
      navigate(SoulRoutes.PAYMENT_REQUESTS.path, { replace: true });
      return;
    }

    loadFormData();
  }, [isRequesterOrManager, loadFormData, navigate]);

  const companyIsFusp = company?.label === "FUSP";

  return (
    <Container>
      <Page>
        <header>
          <h4>Revisando solicitação</h4>
        </header>
        {state.isLoading && (
          <div className="loading-container">
            <SoulSpinner
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        )}
        {!state.isLoading && (
          <FormProvider {...form}>
            <article className="custom-article fill-height">
              <form className="form-container">
                <div className="row">
                  <div className="col-6">
                    <Card>
                      <section>
                        <div className="form-row user-requested">
                          <p>
                            Solicitado por:{" "}
                            <strong>{state.userNameCreated}</strong>
                          </p>
                        </div>
                        <ReviewSectionCompanyData
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <ReviewSectionAccountInfo
                          options={state.options}
                          useProvider={useProvider}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        {hasProject && (
                          <ReviewSectionProjectInfo
                            usePaymentRequestForm={usePaymentRequestForm}
                          />
                        )}
                        {companyIsFusp && <ReviewSectionFuspPurchaseOrderId />}
                        <ReviewSectionAditionalInfo
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <SectionAttachment
                          isReview
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                      </section>
                    </Card>
                  </div>
                  <div className="col-6 right-col">
                    <SectionAssessment
                      usePaymentRequestForm={usePaymentRequestForm}
                    />
                  </div>
                </div>
                <ReviewSectionFooter
                  useProvider={useProvider}
                  usePaymentRequestForm={usePaymentRequestForm}
                />
              </form>
            </article>
          </FormProvider>
        )}
      </Page>
    </Container>
  );
}

interface IReviewPaymentRequestPageFactoryProps {
  api: IApiService;
}

export function ReviewPaymentRequestPageFactory({
  api,
}: IReviewPaymentRequestPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const viaCepApi = new ViaCepApiService();

  const useProvider = makeProvider(api, viaCepApi);
  const useAccountsPayableForm = makePaymentRequestForm(api, barcodeApi);

  return (
    <ReviewPaymentRequestPage
      usePaymentRequestForm={useAccountsPayableForm}
      useProvider={useProvider}
    />
  );
}
