import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../core/domain/usecases/setServerSideUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { ISearchCompanyContract } from "../../domain/contracts/searchCompanyContract";
import { ICompanyEntity } from "../../domain/entities/companyEntity";

export class SearchCompanyService implements ISearchCompanyContract {
  constructor(
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async searchCompany() {
    const userEntity = this.getUserLocalService.get();

    const newPayload = {
      draw: 10,
      start: 0,
      length: 50,
      order: [],
      search: {
        regex: false,
        value: "",
      },
      columns: [],
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      newPayload,
    );

    const paramsBody = {
      body: serverSideId,
    };

    const params = new URLSearchParams(paramsBody);
    const url = `/ClassificationAccounts/SearchClassificationAccount?${params}`;

    const response = await this.api.get<IResponseEntity<ICompanyEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response.data;
  }
}
