import { useCallback, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationAssessmentEntity } from "../../../domain/entities/classificationAssessment";
import {
  makeClassificationAssessment,
  MakeClassificationAssessment,
} from "../../../main/makeClassificationAssessment";
import { useClassificationAssessmentGrid } from "../../hooks/useClassificationAssessmentGrid";
import { ClassificationAssessmentFormModal } from "../ClassificationAssessmentFormModal";
import { Container } from "./styles";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { ExportingModalContent } from "../../../../core/presentation/components/ExportingModalContent";

interface ClassificationAssessmentPageProps {
  useClassificationAssessment: MakeClassificationAssessment;
}

function ClassificationAssessmentPage({
  useClassificationAssessment,
}: ClassificationAssessmentPageProps) {
  const table = useRef<ISimpleTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [
    isClassificationAssessmentModalOpen,
    setIsClassificationAssessmentModalOpen,
  ] = useState<boolean>(false);
  const [
    currentClassificationAssessmentId,
    setCurrentClassificationAssessmentId,
  ] = useState("");
  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity(),
  );
  const [showActivesOnly, setShowActivesOnly] = useState<boolean>(false);

  const openClassificationAssessmentFormModal = useCallback(
    (currentId = "") => {
      setCurrentClassificationAssessmentId(currentId);
      setIsClassificationAssessmentModalOpen(true);
    },
    [],
  );

  const closeClassificationAssessmentFormModal = useCallback(() => {
    setIsClassificationAssessmentModalOpen(false);
    setCurrentClassificationAssessmentId("");
    table.current?.reload();
  }, []);

  const {
    listClassificationAssessments,
    toggleClassificationAssessment,
    exportClassificationAssessment,
  } = useClassificationAssessment;

  const dialog = useSoulDialog();

  const toggle = useCallback(
    async currentId => {
      try {
        const { active } = await toggleClassificationAssessment(currentId);
        const text = active ? "ativada" : "inativada";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Classificação de Rateio ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleClassificationAssessment],
  );

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useClassificationAssessmentGrid({
    openEditModal: openClassificationAssessmentFormModal,
    toggle,
  });
  const { generatePayload } = useTables();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    IResponseEntity<IClassificationAssessmentEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setPfsEvent(_pfsEvent);

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const classificationAssessmentsList =
          await listClassificationAssessments(id, payload, showActivesOnly);

        if (!mountedRef.current) {
          return;
        }

        setData(classificationAssessmentsList);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setLoading(false);

        // Isso é necessário pois temos elementos dinamicos
        // com tooltip e o ReactTooltip precisa escanea-los
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      generatePayload,
      id,
      listClassificationAssessments,
      mountedRef,
      showActivesOnly,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setShowActivesOnly(prevState => !prevState);

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportClassificationAssessment({ payload, showActivesOnly });
    } finally {
      dialog.close();
    }
  };

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const allowedProfiles = useAllowedProfiles();

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={showActivesOnly}
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                onClick={handleExportSheet}
                className="dropdown-item"
                type="button"
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
            </SoulDropdown>
            {allowedProfiles(EUserProfile.master, EUserProfile.supervisor) ? (
              <button
                type="button"
                className="default-button"
                id="btn-add"
                onClick={() => openClassificationAssessmentFormModal()}
              >
                <FaPlus /> Adicionar Classificação de Rateio
              </button>
            ) : null}
          </GridOptionsContainer>
          <ClassificationAssessmentFormModal
            isOpen={isClassificationAssessmentModalOpen}
            onRequestClose={closeClassificationAssessmentFormModal}
            currentId={currentClassificationAssessmentId}
            useClassificationAssessment={useClassificationAssessment}
          />
        </header>
        <article className="no-padding">
          <SimpleTable<IClassificationAssessmentEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ClassificationAssessmentPageFactoryProps {
  api: IApiService;
}

export function ClassificationAssessmentPageFactory({
  api,
}: ClassificationAssessmentPageFactoryProps) {
  return (
    <ClassificationAssessmentPage
      useClassificationAssessment={makeClassificationAssessment(api)}
    />
  );
}
