import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IClassificationAssessmentEntity } from "../../domain/entities/classificationAssessment";
import { IListClassificationAssessmentsService } from "../../domain/usecases/listClassificationAssessmentsUseCase";

export class ListClassificationAssessmentsService
  implements IListClassificationAssessmentsService
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async listClassificationAssessments(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives = false,
  ): Promise<IServerSideResponseModel<IClassificationAssessmentEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/ClassificationAssessments${
      actives ? "/Actives" : ""
    }?${params}`;

    const response = await this.api.get<
      IServerSideResponseModel<IClassificationAssessmentEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
