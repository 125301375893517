import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #f6f9fc;
  & > div {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;
