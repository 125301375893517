import { DataTableSelectionChangeParams } from "primereact/datatable";
import { useCallback, useRef, useState } from "react";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { SimpleTable } from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import { MakeProvider } from "../../../main/makeProvider";
import { useClassificationAccountGrid } from "../../hooks/useClassificationAccountGrid";
import { Container } from "./styles";
import { GERAL_COMPANY_ID } from "../../../../classificationAccount/domain/entities/companyEntity";

interface ClassificationAccountLinkFormModalProps {
  companyId: string;
  useProvider: MakeProvider;
  search: string;
  linkedClsAccounts: IClassificationAccountEntity[];
  onSelectionUpdate(currenteSelection: IClassificationAccountEntity[]): void;
  showSelectedOnly: boolean;
}

export function ClassificationAccountTab({
  companyId,
  useProvider,
  search,
  linkedClsAccounts,
  onSelectionUpdate,
  showSelectedOnly,
}: ClassificationAccountLinkFormModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { generatePayload } = useTables();

  const [tableData, setTableData] =
    useState<IResponseEntity<IClassificationAccountEntity[]>>();

  const { columns } = useClassificationAccountGrid();

  const { listClassificationAccountByCompanyId } = useProvider;

  const linkedClsAccountsRef = useRef(linkedClsAccounts);

  const handleSelectionChange = (event: DataTableSelectionChangeParams) => {
    if (event.type === "all" && event.value.length === 0) {
      const itensIguais = linkedClsAccounts.filter(obj => {
        return !tableData?.data.map(dado => dado.id).includes(obj.id);
      });
      onSelectionUpdate(itensIguais);
      linkedClsAccountsRef.current = itensIguais;
      return;
    }
    if (event.type === "all") {
      const listaInteira = linkedClsAccounts.concat(event.value);
      const listsUnica = listaInteira.filter((current, i, array) => {
        const indexAtual = array.findIndex(a => a.id === current.id);
        return indexAtual === i;
      });

      onSelectionUpdate(listsUnica);
      linkedClsAccountsRef.current = listsUnica;

      return;
    }

    onSelectionUpdate(event.value);
    linkedClsAccountsRef.current = event.value;
  };

  const getListClassificationAccountByCompanyId = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setIsLoading(true);
      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);

      let selectedIds = showSelectedOnly
        ? linkedClsAccountsRef.current.map(obj => obj.id)
        : [];
      if (showSelectedOnly && selectedIds.length === 0) {
        selectedIds = ["empty"];
      }
      const classificatonAccountsdata =
        await listClassificationAccountByCompanyId(
          payload,
          companyId,
          search,
          selectedIds,
        );

      if (companyId === GERAL_COMPANY_ID && showSelectedOnly) {
        const geralClassificationData = classificatonAccountsdata.data.filter(
          obj => selectedIds.includes(obj.id),
        );
        classificatonAccountsdata.data = geralClassificationData;
        classificatonAccountsdata.recordsTotal = geralClassificationData.length;
      }

      setTableData(classificatonAccountsdata);
      setIsLoading(false);
    },
    [
      columns,
      companyId,
      generatePayload,
      listClassificationAccountByCompanyId,
      search,
      showSelectedOnly,
    ],
  );

  return (
    <Container>
      <SimpleTable
        paginator
        columns={columns}
        data={tableData}
        emptyMessage="Nenhum registro encontrado"
        selectionMode="multiple"
        selection={linkedClsAccounts}
        onSelectionChange={handleSelectionChange}
        getList={getListClassificationAccountByCompanyId}
        loading={isLoading}
        pageLinkSize={3}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Container>
  );
}
