import { IEntity } from "../../../core/domain/entities/entity";

export enum ECustomerType {
  foreign, // estrangeiro
  individualPerson, // pessoa fisica
  legalPerson, // pessoa juridica
}

export enum ECustomerDocumentType {
  other = 0,
  CPF = 1,
  CNPJ = 2,
}

export interface ICustomerEntity extends IEntity {
  type: ECustomerType;
  typeDescription: string;

  document: string;
  documentType: ECustomerDocumentType;

  name: string; // nome / nome fantasia
  corporationName: string; // razao social

  phoneNumber: string;
  email: string;
  municipalRegistration: string; // inscricao municipal
  stateRegistration: string; // inscricao estadual
  isStateRegistrationExempt: boolean; // isento

  birthDate?: string;
  identityCard?: string;
  pisPasep?: string;
  profession?: string;

  countryId: string;
  countryName: string;

  zipCode: string;
  street: string;
  placeNumber: string;

  stateId: string;
  stateName: string;

  cityId: string;
  cityName: string;

  // bairro
  neighborhood: string;
  // complemento
  complement: string;

  companyGroupId: string;
  companyGroupName: string;
  hasCompanyTax: boolean;

  id: string;
}

export class CustomerEntity implements ICustomerEntity {
  type = ECustomerType.individualPerson;
  typeDescription = "";
  document = "";
  documentType = ECustomerDocumentType.CPF;
  name = "";
  corporationName = "";
  phoneNumber = "";
  email = "";
  municipalRegistration = "";
  stateRegistration = "";
  isStateRegistrationExempt = false;
  countryId = "";
  countryName = "";
  zipCode = "";
  street = "";
  placeNumber = "";
  stateId = "";
  stateName = "";
  cityId = "";
  cityName = "";
  neighborhood = "";
  complement = "";
  companyGroupId = "";
  companyGroupName = "";
  hasCompanyTax = false;

  id = "";
  active = true;

  birthDate?: string | undefined;
  identityCard?: string | undefined;
  pisPasep?: string | undefined;
  profession?: string | undefined;

  constructor(params?: Partial<ICustomerEntity>) {
    if (params) {
      Object.assign(this, params);
    }
  }

  static create(params?: Partial<ICustomerEntity>) {
    return new CustomerEntity(params);
  }
}

export interface ICheckDocumentEntity {
  Code?: number;
  message?: string;
  success?: boolean;
}
