import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveClassificationAccountLinkedContract } from "../../domain/contracts/saveClassificationAcountLinkedContract";
import { ISaveClassificationAccountLinkedEntity } from "../../domain/entities/saveClassificationAcountLinkedEntity";

export class SaveClassificationAccountLinkedService
  implements ISaveClassificationAccountLinkedContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveClassificationAccountLinked(
    paymentAccountId: string,
    classificationAccountLinked: string,
  ) {
    const userEntity = this.getUserLocalService.get();

    const params = classificationAccountLinked;
    const url = `/PaymentAccounts/${paymentAccountId}/ClassificationAccounts/${params}`;

    return this.api.patch<ISaveClassificationAccountLinkedEntity>(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
