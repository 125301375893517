import { Controller } from "react-hook-form";
import { ChangeEvent } from "react";
import { useDebtImportPage } from "../../../hooks/useDebtImportPage";
import { IFormComponentProps } from "../../../../domain/entities/debtImportTypes";
import { IconTooltip } from "../../../../../../../core/presentation/components/IconTooltip";
import { InputDate } from "../../../../../../../core/presentation/components/InputDate";
import { InvalidFeedback } from "../../../../../../../core/presentation/components/InvalidFeedback";
import { FieldWrapper } from "../../FieldWrapper";
import { useDateValidator } from "../../../../../../../core/presentation/hooks/useDateValidator";

export function TerminationDateField(props: IFormComponentProps) {
  const { formIndex } = props;

  const invalidDate = useDateValidator();

  const fieldKey = `imports.${formIndex}.terminationDate` as const;

  const { handleInputClassName } = useDebtImportPage();

  return (
    <Controller
      name={fieldKey}
      rules={{
        validate: { validDate: v => !invalidDate(v || "", "dd/MM/yyyy") },
      }}
      render={({ field, fieldState }) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
          field.onBlur();
          field.onChange(event);
        };

        return (
          <label className="form-control">
            <span className="with-tooltip">
              Data de Baixa{" "}
              <IconTooltip
                icon="pi pi-question-circle"
                title="Data de baixa"
                text='Ao preencher o campo de data de baixa, o lançamento será
                  cadastrado como "Pago".'
              />
            </span>
            <FieldWrapper className={handleInputClassName(fieldState)}>
              <InputDate
                {...field}
                onChange={onChange}
                placeholder="00/00/0000"
                id={`txt-terminationDate-${formIndex}`}
                data-testid={`txt-terminationDate-${formIndex}`}
              />
            </FieldWrapper>
            <InvalidFeedback
              message="Formato de data inválida"
              condition={fieldState?.error?.type === "validDate"}
            />
            <InvalidFeedback
              message={fieldState?.error?.message || ""}
              condition={fieldState?.error?.type === "custom"}
            />
          </label>
        );
      }}
    />
  );
}
