import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IEnum } from "../../../../core/domain/entities/enum";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { MakeCore } from "../../../../core/main/makeCore";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import {
  ClassificationAccountFormEntity,
  IClassificationAccountFormEntity,
} from "../../../domain/entities/classificationAccountFormEntity";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import { MakeClassificationAccounts } from "../../../main/makeClassificationAccounts";
import { useClassificationAccountForm } from "../../hooks/useClassificationAccountForm";
import { Container } from "./style";

export interface ClassificationAccountFormModalProps {
  isOpen: boolean;
  useCore: MakeCore;
  onClose: (shouldRefresh: boolean | unknown) => void;
  useClassificationAccounts: MakeClassificationAccounts;
  currentClassificationAccount?: Partial<IClassificationAccountEntity>;
  company: ICompanyEntity;
}

const updateAlertOptions = {
  allowEscapeKey: false,
  allowOutsideClick: false,
  showConfirmButton: false,
  html: (
    <>
      <p
        style={{
          fontWeight: 600,
          marginTop: "0.5em",
          fontSize: "1.25rem",
          marginBottom: "0.5em",
        }}
      >
        Processando dados...
      </p>
      <p style={{ fontSize: "1.125rem" }}>
        Aguarde enquanto os dados de relatórios são atualizados.
      </p>
      <SoulSpinner
        style={{
          width: "50px",
          height: "50px",
          marginTop: "1rem",
          marginBottom: "0.5rem",
        }}
      />
    </>
  ),
};

export function ClassificationAccountFormModal(
  props: ClassificationAccountFormModalProps,
) {
  const {
    isOpen,
    useCore,
    onClose,
    useClassificationAccounts,
    currentClassificationAccount,
    company,
  } = props;

  const { getClassificationAccountTypes, getClassificationAccountIssueTypes } =
    useCore;

  const {
    getClassificationAccount,
    saveClassificationAccount,
    updateClassificationAccount,
  } = useClassificationAccounts;

  const { id: currentId } = currentClassificationAccount ?? {
    id: "",
    active: true,
  };

  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();
  const asyncDebounce = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const createClassificationAccountForm = useClassificationAccountForm();

  const isDisabled = useMemo(() => {
    return !allowedProfiles(
      EUserProfile.financialAccounting,
      EUserProfile.supervisor,
    );
  }, [allowedProfiles]);

  const formMethods = useForm({
    mode: "all",
    defaultValues: ClassificationAccountFormEntity.create(),
  });

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = formMethods;

  /** Estado de carregamento das informações da classificação */
  const [isLoading, setIsLoading] = useState(false);
  /** Armazena lista de tipos de classificação contábil */
  const [typesList, setTypesList] = useState<IEnum[]>();
  /** Armazena lista de tipos de lançamento de classificação contábil */
  const [issueTypesList, setIssueTypesList] = useState<IEnum[]>();

  /**
   * Lida com a abertura do modal.
   */
  const handleModalOpening = useCallback(async () => {
    setIsLoading(true);

    try {
      const typesResponse = await getClassificationAccountTypes();
      const issueResponse = await getClassificationAccountIssueTypes();

      setTypesList(typesResponse);
      setIssueTypesList(issueResponse);

      if (!currentId) return;

      const response = await getClassificationAccount(currentId);

      const formValues = createClassificationAccountForm(response);

      reset(formValues);
    } catch (err) {
      onClose(false);
    } finally {
      setIsLoading(false);
      ReactTooltip.rebuild();
    }
  }, [
    reset,
    onClose,
    currentId,
    getClassificationAccount,
    getClassificationAccountTypes,
    createClassificationAccountForm,
    getClassificationAccountIssueTypes,
  ]);

  /**
   * Lida com a submissão do formulário.
   */
  const submitForm = useCallback(
    async (values: IClassificationAccountFormEntity) => {
      const actionText = currentId ? "atualizada" : "cadastrada";

      if (!currentId) {
        await saveClassificationAccount(values, company.id);
      } else {
        await Promise.race([
          dialog.fire(updateAlertOptions),
          updateClassificationAccount(values, company.id, currentId),
        ]);
        await asyncDebounce(4000);
      }

      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Classificação Contábil ${actionText} com sucesso.`,
      });

      onClose(true);
    },
    [
      asyncDebounce,
      company.id,
      currentId,
      dialog,
      onClose,
      saveClassificationAccount,
      updateClassificationAccount,
    ],
  );

  /**
   * Lida com o fechamento do modal resetando os valores do formulário.
   */
  const handleModalClose = useCallback(() => {
    /** Reseta para os valores padrão */
    reset(ClassificationAccountFormEntity.create());
  }, [reset]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={!isLoading}
      onAfterClose={handleModalClose}
      className="react-modal-content"
      onAfterOpen={handleModalOpening}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={!isLoading}
    >
      <Container>
        <div className="react-modal-header">
          <h4>{currentId ? "Editar" : "Nova"} Classificação Contábil</h4>
          {!isLoading ? (
            <button
              type="button"
              id="btn-crud-cross"
              data-testid="btn-crud-cross"
              className="react-modal-close"
              onClick={onClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="crud-header">
              <p>
                Este registro {currentId ? "está" : "será"} vinculado ao grupo
                de empresa
              </p>
              <h3>{currentCompanyGroup.name}</h3>
            </div>
            <div className="react-modal-body">
              <FormProvider {...formMethods}>
                <form
                  className="form-container"
                  id="classification-account-crud-form"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>Código</span>
                      <Controller
                        name="code"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\d+(\.{1}\d+)*(?!\.)$/,
                        }}
                        render={({ field }) => {
                          const onChange = (
                            e: ChangeEvent<HTMLInputElement>,
                          ) => {
                            const { value } = e.target;
                            const correctValue = value.replace(
                              /(?![.])\D/g,
                              "",
                            );
                            field.onChange(correctValue);
                          };

                          return (
                            <input
                              {...field}
                              id="txt-code"
                              maxLength={50}
                              autoComplete="off"
                              onChange={onChange}
                              placeholder="Código"
                              disabled={isDisabled}
                              data-testid="txt-code"
                              className={errors?.code ? "isInvalid" : ""}
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        condition={errors.code?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                      <InvalidFeedback
                        condition={errors.code?.type === "pattern"}
                        message="Código inválido"
                      />
                      {errors?.code ? null : (
                        <em className="code-info">
                          Este campo aceita apenas números e ponto.
                        </em>
                      )}
                    </label>
                    <label className="col-6 form-control">
                      <span>Chamada</span>
                      <Controller
                        name="callNumber"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^[0-9]*$/,
                        }}
                        render={({ field }) => {
                          const onChange = (
                            e: ChangeEvent<HTMLInputElement>,
                          ) => {
                            const { value } = e.target;
                            const correctValue = value.replace(/\D/g, "");
                            field.onChange(correctValue);
                          };

                          return (
                            <input
                              {...field}
                              id="txt-callNumber"
                              maxLength={15}
                              autoComplete="off"
                              onChange={onChange}
                              placeholder="Chamada"
                              disabled={isDisabled}
                              data-testid="txt-callNumber"
                              className={errors?.callNumber ? "isInvalid" : ""}
                            />
                          );
                        }}
                      />
                      <InvalidFeedback
                        condition={errors.callNumber?.type === "required"}
                        message="Este campo é obrigatório"
                      />
                      <InvalidFeedback
                        condition={errors.callNumber?.type === "pattern"}
                        message="Código inválido"
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-12 form-control">
                      <span>Nome</span>
                      <input
                        maxLength={100}
                        id="txt-name"
                        placeholder="Nome"
                        disabled={isDisabled}
                        data-testid="txt-name"
                        className={errors?.name ? "isInvalid" : ""}
                        {...register("name", { required: true })}
                      />
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.name?.type === "required"}
                      />
                    </label>
                  </div>
                  <div className="form-row">
                    <label className="col-6 form-control">
                      <span>Tipo</span>
                      <select
                        id="sel-type"
                        defaultValue=""
                        placeholder="Tipo"
                        disabled={isDisabled}
                        data-testid="sel-type"
                        className={errors?.type ? "isInvalid" : ""}
                        {...register("type", { required: true })}
                      >
                        <option value="" hidden disabled>
                          Selecione um tipo
                        </option>
                        {typesList?.map(t => (
                          <option key={t.value} value={t.key}>
                            {t.value}
                          </option>
                        ))}
                      </select>
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.type?.type === "required"}
                      />
                    </label>
                    <label className="col-6 form-control">
                      <span>Tipo de Lançamento</span>
                      <select
                        defaultValue=""
                        id="sel-issueType"
                        disabled={isDisabled}
                        data-testid="sel-issueType"
                        placeholder="Tipo de Lançamento"
                        className={errors?.issueType ? "isInvalid" : ""}
                        {...register("issueType", { required: true })}
                      >
                        <option value="" hidden disabled>
                          Selecione um tipo de lançamento
                        </option>
                        {issueTypesList?.map(i => (
                          <option key={i.value} value={i.key}>
                            {i.value}
                          </option>
                        ))}
                      </select>
                      <InvalidFeedback
                        message="Este campo é obrigatório"
                        condition={errors.issueType?.type === "required"}
                      />
                    </label>
                  </div>
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={onClose}
                id="btn-crud-close"
                data-testid="btn-crud-close"
                className="form-button red-bkg"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-crud-save"
                data-testid="btn-crud-save"
                disabled={isSubmitting || isDisabled}
                form="classification-account-crud-form"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                {isSubmitting ? "Salvando " : "Salvar "}
                {isSubmitting ? <FaSpinner className="spinner" /> : null}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
