import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  ApiService,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { EUserProfile } from "../../../../../../core/domain/entities/userEntity";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../../core/presentation/hooks/useUserLocal";
import {
  MakeProvider,
  makeProvider,
} from "../../../../../../provider/main/makeProvider";
import {
  EFormMode,
  EPaymentRequestDestination,
  EPaymentRequestStatus,
} from "../../../domain/entities/paymentRequestEnums";
import {
  IPaymentRequestFormEntity,
  PaymentRequestFormEntity,
} from "../../../domain/entities/paymentRequestFormEntity";
import {
  MakePaymentRequestForm,
  makePaymentRequestForm,
} from "../../../main/makePaymentRequestForm";
import { FormSectionAccountInfo } from "../FormSectionAccountInfo";
import { FormSectionAditionalInfo } from "../FormSectionAditionalInfo";
import { FormSectionCompanyData } from "../FormSectionCompanyData";
import { FormSectionFooter } from "../FormSectionFooter";
import { SectionAssessment } from "../SectionAssessment";
import { SectionAttachment } from "../SectionAttachment";
import { Container } from "./styles";

interface ICreatePaymentRequestPageState {
  mode: EFormMode;
  isLoading: boolean;
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
    userDestinations: EPaymentRequestDestination[];
  };
}

interface ICreatePaymentRequestPageProps {
  useProvider: MakeProvider;
  usePaymentRequestForm: MakePaymentRequestForm;
}

function CreatePaymentRequestPage(props: ICreatePaymentRequestPageProps) {
  const { usePaymentRequestForm, useProvider } = props;

  const {
    getPaymentRequest,
    fetchPaymentMethods,
    fetchDocumentStatus,
    fetchPaymentRequestsDestinations,
  } = usePaymentRequestForm;

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<ICreatePaymentRequestPageState>(() => {
    return {
      isLoading: true,
      mode: EFormMode.Create,
      options: {
        paymentMethods: [],
        documentStatuses: [],
        userDestinations: [],
      },
    };
  });

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const {
    user: { profile },
  } = useUserLocal();

  const isRequesterOrManager = [
    EUserProfile.manager,
    EUserProfile.requester,
  ].includes(profile);

  const form = useForm<IPaymentRequestFormEntity>({
    mode: "all",
    defaultValues: new PaymentRequestFormEntity({
      duplicatePaymentRequestId: searchParams.get("duplicate"),
    }),
  });

  const { reset, getValues } = form;

  const defineFormMode = useCallback(async () => {
    const duplicatePaymentRequestId = getValues("duplicatePaymentRequestId");

    if (!duplicatePaymentRequestId) {
      return EFormMode.Create;
    }

    const paymentRequest = await getPaymentRequest(
      duplicatePaymentRequestId,
      currentCompanyGroup.id,
    );

    const { assessments } = paymentRequest;

    const resetAssessments = assessments.map(assessmentObject => {
      return {
        ...assessmentObject,
        value: "0",
        percentage: "0",
        accountPayableId: null,
      };
    });

    reset({
      ...paymentRequest,
      active: true,
      payUntil: "",
      destination: null,
      documentNumber: "",
      duplicatePaymentRequestId,
      assessments: resetAssessments,
      status: EPaymentRequestStatus.NotRequested,
    });

    return EFormMode.Duplicate;
  }, [currentCompanyGroup.id, getPaymentRequest, getValues, reset]);

  const loadFormData = useCallback(async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const mode = await defineFormMode();
      const paymentMethodOptions = await fetchPaymentMethods();
      const documentStatusOptions = await fetchDocumentStatus();
      const userDestinations = await fetchPaymentRequestsDestinations();

      setState(prevState => ({
        ...prevState,
        mode,
        isLoading: false,
        options: {
          userDestinations,
          paymentMethods: paymentMethodOptions,
          documentStatuses: documentStatusOptions,
        },
      }));
    } catch (err) {
      const isHttpError = axios.isAxiosError(err);

      if (!isHttpError && err instanceof Error) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          text: err?.message || "Algo deu errado.",
        });
      }

      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`, { replace: true });
    }
  }, [
    dialog,
    navigate,
    defineFormMode,
    fetchDocumentStatus,
    fetchPaymentMethods,
    fetchPaymentRequestsDestinations,
  ]);

  const handleFormInitialization = useCallback(() => {
    const urlHasSearchParam = searchParams.has("duplicate");

    /**
     * Encerra o processo de montagem do formulário caso exista um query param
     * na URL.
     *
     * Isso é necessário para que o componente abra com a URL "limpa".
     *
     * Caso exista um ID de duplicação, ele fica salvo na inicialização do
     * estado do formulário.
     */
    if (urlHasSearchParam) {
      const blankParams = new URLSearchParams();
      setSearchParams(blankParams, { replace: true });
      return;
    }

    loadFormData();
  }, [loadFormData, searchParams, setSearchParams]);

  useEffect(() => {
    /**
     * UGLY- Usuários de perfil diferente de Supervisor e Gestor não devem ter
     * acesso a rota de criação de solicitação de pagamento.
     * Isso deve ser feito pelo componente <GuardedRoute />, não sendo
     * responsabilidade do componente de lidar com isso.
     */
    if (!isRequesterOrManager) {
      navigate(`${SoulRoutes.PAYMENT_REQUESTS.path}`, { replace: true });
      return;
    }

    handleFormInitialization();
  }, [isRequesterOrManager, handleFormInitialization, navigate]);

  return (
    <Container>
      <Page>
        <header>
          <h4>Adicionando solicitação</h4>
        </header>
        {state.isLoading && (
          <div className="loading-container">
            <SoulSpinner
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        )}
        {!state.isLoading && (
          <FormProvider {...form}>
            <article className="custom-article fill-height">
              <form className="form-container">
                <div className="row">
                  <div className="col-6">
                    <Card>
                      <section>
                        <FormSectionCompanyData
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <FormSectionAccountInfo
                          options={state.options}
                          useProvider={useProvider}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <FormSectionAditionalInfo
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <SectionAttachment
                          isRequesterOrManager
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                      </section>
                    </Card>
                  </div>
                  <div className="col-6 right-col">
                    <SectionAssessment
                      usePaymentRequestForm={usePaymentRequestForm}
                    />
                  </div>
                </div>
                <FormSectionFooter
                  mode={state.mode}
                  useProvider={useProvider}
                  isRequesterOrManager={isRequesterOrManager}
                  usePaymentRequestForm={usePaymentRequestForm}
                  availableDestinations={state.options.userDestinations}
                />
              </form>
            </article>
          </FormProvider>
        )}
      </Page>
    </Container>
  );
}

interface ICreatePaymentRequestPageFactoryProps {
  api: IApiService;
}

export function CreatePaymentRequestPageFactory({
  api,
}: ICreatePaymentRequestPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const viaCepApi = new ViaCepApiService();

  const useProvider = makeProvider(api, viaCepApi);
  const useAccountsPayableForm = makePaymentRequestForm(api, barcodeApi);

  return (
    <CreatePaymentRequestPage
      useProvider={useProvider}
      usePaymentRequestForm={useAccountsPayableForm}
    />
  );
}
