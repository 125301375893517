import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { DataTableSelectionChangeParams } from "primereact/datatable";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaCaretDown, FaDatabase, FaFileExcel, FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { MakeCore, makeCore } from "../../../../core/main/makeCore";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useOnClickOutside } from "../../../../core/presentation/hooks/useOnClickOutside";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { ICostCenterEntity } from "../../../domain/entities/costCenterEntity";
import { MakeCostCenter, makeCostCenter } from "../../../main/makeCostCenter";
import { useCostCenterGrid } from "../../hooks/useCostCenterGrid";
import { CostCenterFormModal } from "../CostCenterFormModal";
import { CostCenterLockDateModal } from "../CostCenterLockDateModal";
import { CostCenterUsersModal } from "../CostCenterUsersModal";
import { ExportingModalContent } from "../ExportingModalContent";
import { Container } from "./style";

interface CostCenterPageProps {
  useCore: MakeCore;
  useCostCenter: MakeCostCenter;
}

export enum CostCenterPageModalType {
  None,
  Crud,
  Users,
  LockDate,
}

export enum OnCloseModalAction {
  Refresh = 1,
  RefreshAndDeselect,
}

interface CostCenterPageState {
  search: string;
  isLoading: boolean;
  globalFilter: string;
  showActivesOnly: boolean;
  pfsEvent: IPFSEventEntity;
  selectAllRegisters: boolean;
  selection: ICostCenterEntity[];
  currentCostCenter?: ICostCenterEntity;
  currentModal: CostCenterPageModalType;
  data?: IResponseEntity<ICostCenterEntity[]>;
}

function CostCenterPage({ useCore, useCostCenter }: CostCenterPageProps) {
  const { listAuthUsers } = useCore;
  const {
    listCostCenters,
    toggleCostCenter,
    exportCostCenters,
    searchCostCenter,
    changeSelectedCostCentersLockDates,
    changeFilteredCostCentersLockDates,
  } = useCostCenter;

  const dialog = useSoulDialog();
  const mountedRef = useIsMounted();
  const debounceTime = useDebounceTime();
  const { generatePayload } = useTables();
  const allowedProfiles = useAllowedProfiles();
  const { currentCompanyGroup, companyGroupList } = useCurrentCompanyGroup();

  const canAdd = useMemo(
    () =>
      companyGroupList.length &&
      allowedProfiles(
        EUserProfile.financialManagement,
        EUserProfile.supervisor,
      ),
    [allowedProfiles, companyGroupList.length],
  );

  const table = useRef<ISimpleTableHandle>(null);
  const tooltipContainerRef = useRef<HTMLButtonElement>(null);

  const [state, setState] = useState<CostCenterPageState>({
    search: "",
    selection: [],
    isLoading: false,
    globalFilter: "",
    showActivesOnly: false,
    selectAllRegisters: false,
    pfsEvent: new PFSEventEntity(),
    currentModal: CostCenterPageModalType.None,
  });

  const handleToogleCostCenter = useCallback(
    async (payload: ICostCenterEntity) => {
      try {
        const { active } = await toggleCostCenter(payload);
        const text = active ? "ativado" : "desativado";

        dialog.close({
          isDenied: false,
          isConfirmed: true,
          isDismissed: false,
        });

        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Centro de Custo ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleCostCenter],
  );

  const handleOpenModal = useCallback(
    (info: ICostCenterEntity, type: CostCenterPageModalType) => {
      setState(prevState => ({
        ...prevState,
        currentModal: type,
        currentCostCenter: info,
      }));
    },
    [],
  );

  const handleCloseCurrentModal = useCallback((action?: OnCloseModalAction) => {
    if (action) {
      table.current?.reload();
    }

    const shouldDeselect = action === OnCloseModalAction.RefreshAndDeselect;

    setState(prevState => ({
      ...prevState,
      currentCostCenter: undefined,
      currentModal: CostCenterPageModalType.None,
      selection: shouldDeselect ? [] : prevState.selection,
      selectAllRegisters: shouldDeselect ? false : prevState.selectAllRegisters,
    }));
  }, []);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setState(prevState => ({
        ...prevState,
        search: e.target.value,
      }));
      debounceTime(() => {
        setState(prevState => ({
          ...prevState,
          globalFilter: e.target.value,
        }));
      }, 700);
    },
    [debounceTime],
  );

  const { columns } = useCostCenterGrid({
    handleOpenModal,
    searchCostCenter,
    handleToogleCostCenter,
  });

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        isLoading: true,
        pfsEvent: _pfsEvent,
      }));

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const response = await listCostCenters(
          payload,
          currentCompanyGroup.id,
          state.showActivesOnly,
        );

        setState(prevState => ({
          ...prevState,
          data: response,
        }));
      } catch (err) {
        return;
      } finally {
        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }));
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      mountedRef,
      listCostCenters,
      generatePayload,
      state.showActivesOnly,
      currentCompanyGroup.id,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showActivesOnly: value,
    }));

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(state.pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportCostCenters({
        payload,
        companyGroupId: currentCompanyGroup.id,
        showActivesOnly: state.showActivesOnly,
      });
    } finally {
      dialog.close();
    }
  };

  const handleSelectAllRegistersChange = useCallback(
    (checked: CheckboxChangeParams["checked"]) => {
      setState(prevState => ({
        ...prevState,
        selection: [],
        selectAllRegisters: checked,
      }));
    },
    [],
  );

  const handleSelectionChange = (event: DataTableSelectionChangeParams) => {
    if (event?.type === "row") {
      return;
    }

    const typedValue = event.value as ICostCenterEntity[];

    setState(prevState => {
      let selection = [];

      if (event.type === "checkbox") {
        selection = typedValue;
      } else if (!typedValue.length) {
        selection = prevState.selection.filter(oldItem => {
          return !prevState.data?.data.find(
            currentPageData => currentPageData.id === oldItem.id,
          );
        });
      } else {
        const mixedList = prevState.selection.concat(typedValue);
        const uniqueList = mixedList.filter((current, i, array) => {
          const currentIndex = array.findIndex(a => a.id === current.id);
          return currentIndex === i;
        });

        selection = Array.from(uniqueList);
      }

      return {
        ...prevState,
        selection,
      };
    });
  };

  const handleRowClassName = () => {
    return {
      "disabled-checkbox": state.selectAllRegisters,
    };
  };

  const handleChangeLockDate = () => {
    setState(prevState => ({
      ...prevState,
      currentModal: CostCenterPageModalType.LockDate,
    }));
  };

  useEffect(() => {
    if (tooltipContainerRef.current) {
      const method =
        state.selection?.length || state.selectAllRegisters ? "show" : "hide";
      ReactTooltip[method](tooltipContainerRef.current);
    }
    ReactTooltip.rebuild();
  }, [state.selectAllRegisters, state.selection?.length]);

  useOnClickOutside(tooltipContainerRef, tar => {
    const target = tar as HTMLElement;

    if (target?.classList?.value?.includes("p-checkbox")) {
      return;
    }

    if (tooltipContainerRef.current) {
      ReactTooltip.hide(tooltipContainerRef.current);
    }
  });

  return (
    <Container>
      <Page>
        <header>
          <Checkbox
            data-tip
            data-for="checkbox-tooltip"
            id="chk-all-registers-filtered"
            checked={state.selectAllRegisters}
            disabled={!state.data?.data.length}
            data-testid="chk-all-registers-filtered"
            onChange={e => handleSelectAllRegistersChange(e.checked)}
          />
          <ReactTooltip
            place="right"
            effect="solid"
            id="checkbox-tooltip"
            disable={!state.data?.data.length}
          >
            Selecionar todos os registros filtrados
          </ReactTooltip>
          <InputSearch
            id="txt-search"
            value={state.search}
            data-testid="txt-search"
            onChange={handleSearchChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={state.showActivesOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-place="top"
                  data-event="none"
                  data-effect="solid"
                  data-event-off="click"
                  ref={tooltipContainerRef}
                  data-testid="btn-options"
                  className="default-button options-context"
                  data-tip="Acesse as opções para fazer<br />ações em lotes"
                  data-tip-disable={
                    !state.selection?.length && !state.selectAllRegisters
                  }
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                type="button"
                className="dropdown-item"
                onClick={handleExportSheet}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
              <button
                type="button"
                className="dropdown-item"
                onClick={handleChangeLockDate}
                disabled={!state.selection?.length && !state.selectAllRegisters}
              >
                <FaDatabase />
                <span title="Alterar travamento">
                  Alterar data de travamento
                </span>
              </button>
            </SoulDropdown>
            {canAdd ? (
              <button
                id="btn-add"
                type="button"
                data-testid="btn-add"
                className="default-button"
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    currentModal: CostCenterPageModalType.Crud,
                  }));
                }}
              >
                <FaPlus /> Adicionar Centro de Custo
              </button>
            ) : null}
          </GridOptionsContainer>
          <CostCenterFormModal
            useCostCenter={useCostCenter}
            onClose={handleCloseCurrentModal}
            searchCostCenter={searchCostCenter}
            currentId={state?.currentCostCenter?.id || ""}
            isOpen={state.currentModal === CostCenterPageModalType.Crud}
          />
          <CostCenterUsersModal
            listAuthUsers={listAuthUsers}
            useCostCenter={useCostCenter}
            onClose={handleCloseCurrentModal}
            currentCostCenter={state.currentCostCenter}
            isOpen={state.currentModal === CostCenterPageModalType.Users}
          />
          <CostCenterLockDateModal
            onClose={handleCloseCurrentModal}
            currentFilter={state.globalFilter}
            selectedCostCenters={state.selection}
            isOpen={state.currentModal === CostCenterPageModalType.LockDate}
            changeSelectedCostCentersLockDates={
              changeSelectedCostCentersLockDates
            }
            changeFilteredCostCentersLockDates={
              changeFilteredCostCentersLockDates
            }
          />
        </header>
        <article className="no-padding fill-height">
          <SimpleTable
            tableRef={table}
            columns={columns}
            data={state.data}
            getList={getList}
            scrollable={false}
            selectionMode="multiple"
            loading={state.isLoading}
            selectionAutoFocus={false}
            globalFilter={state.globalFilter}
            rowClassName={handleRowClassName}
            onSelectionChange={handleSelectionChange}
            className={
              state.selectAllRegisters ? "disabled-header-checkbox" : ""
            }
            selection={
              state.selectAllRegisters ? state.data?.data : state.selection
            }
          />
        </article>
      </Page>
    </Container>
  );
}

interface CostCenterPageFactoryProps {
  api: IApiService;
}

export function CostCenterPageFactory({ api }: CostCenterPageFactoryProps) {
  return (
    <CostCenterPage
      useCore={makeCore(api)}
      useCostCenter={makeCostCenter(api)}
    />
  );
}
