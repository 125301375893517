import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { ITypeaheadOption } from "../../../../../../core/domain/entities/typeaheadOption";
import {
  ISoulTypeaheadChangeEvent,
  SoulTypeahead,
} from "../../../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../../../core/presentation/hooks/useDebounceTime";
import { InvalidFeedback } from "../../../../../../core/presentation/components/InvalidFeedback";
import { MakeAccountsReceivableForm } from "../../../main/makeAccountsReceivableForm";
import { IAccountReceivableFormEntity } from "../../../domain/entities/accountReceivableFormEntity";
import { EFormMode } from "../../../domain/entities/accountReceivableEnums";
import { ICostCenterEntity } from "../../../../../../costCenter/domain/entities/costCenterEntity";

interface ICompanyMetadata {
  hasProject: boolean;
}

interface ISectionCompanyDataState {
  company: {
    options: ITypeaheadOption[] | undefined;
    isLoading: boolean;
  };
  competency: {
    options: ITypeaheadOption[] | undefined;
    isLoading: boolean;
  };
}

interface ISectionCompanyDataProps {
  mode: EFormMode;
  useReceivableForm: MakeAccountsReceivableForm;
}

export function SectionCompanyData(props: ISectionCompanyDataProps) {
  const { mode, useReceivableForm } = props;

  const { searchCompany, searchCompetency } = useReceivableForm;

  const [state, setState] = useState<ISectionCompanyDataState>({
    company: {
      isLoading: false,
      options: [],
    },
    competency: {
      isLoading: false,
      options: [],
    },
  });

  const debounceTime = useDebounceTimeAsync();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useFormContext<IAccountReceivableFormEntity>();

  const { watch, setValue, getValues } = form;

  const company = watch("company") as ITypeaheadOption<ICompanyMetadata>;

  const shouldShowCompetencyField = !!company?.metadata?.hasProject;

  const isCompanyDisabled = useMemo(() => {
    const companyId = getValues("company.rawValue");

    const disabledByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: true,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!companyId,
    };

    return disabledByMode[mode];
  }, [getValues, mode]);

  const isCompetencyDisabled = useMemo(() => {
    const companyId = getValues("company.rawValue");
    const competencyId = getValues("competency.rawValue");
    const costCenter = getValues(
      "costCenter",
    ) as ITypeaheadOption<ICostCenterEntity>;
    const isActive = costCenter?.metadata?.active;

    const disableByMode = {
      [EFormMode.Edit]: false,
      [EFormMode.Create]: false,
      [EFormMode.Limited]: false,
      [EFormMode.EditAttachments]: true,
      [EFormMode.ParcelsBlocked]: false,
      [EFormMode.Conditional]: !!companyId && !!competencyId,
    };

    if (
      costCenter &&
      mode !== EFormMode.Create &&
      mode !== EFormMode.Limited &&
      mode !== EFormMode.Conditional &&
      mode !== EFormMode.EditAttachments
    ) {
      return !isActive;
    }

    return disableByMode[mode];
  }, [getValues, mode]);

  /**
   * Reseta campos que são dependentes do valor da empresa.
   */
  const handleCompanyChange = () => {
    setValue("project", null, { shouldValidate: true });
    setValue("competency", null, { shouldValidate: true });
    setValue("paymentAccount", null, { shouldValidate: true });
    setValue("classificationUsp", null, { shouldValidate: true });
    setValue("classificationAccount", null, { shouldValidate: true });
  };

  const handleSearchCompanyChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      company: {
        ...prevState.company,
        isLoading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompany({
        search,
        companyGroupId,
        activesOnly: true,
        showMetadata: true,
        payloadOptions: {
          length: 100,
        },
      });

      const companies = response.data;

      setState(prevState => ({
        ...prevState,
        company: {
          ...prevState.company,
          options: companies,
          isLoading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        company: {
          ...prevState.company,
          isLoading: false,
        },
      }));
    }
  };

  const handleSearchCompetencyChange = async (search = "") => {
    await debounceTime(750);

    setState(prevState => ({
      ...prevState,
      competency: {
        ...prevState.competency,
        isLoading: true,
      },
    }));

    try {
      const companyGroupId = currentCompanyGroup.id;
      const response = await searchCompetency({
        search,
        companyGroupId,
        activesOnly: true,
        showMetadata: true,
        payloadOptions: {
          length: 100,
        },
      });

      const competencies = response.data;

      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          options: competencies,
          isLoading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        competency: {
          ...prevState.competency,
          isLoading: false,
        },
      }));
    }
  };

  return (
    <div className="form-row">
      <Controller
        name="company"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          const { isLoading, options } = state.company;

          const onChange = (event: ISoulTypeaheadChangeEvent) => {
            field.onChange(event);
            handleCompanyChange();
          };

          return (
            <label className="col-6 form-control">
              <span>
                Empresa{" "}
                {isLoading ? <i className="pi pi-spin pi-spinner" /> : null}
              </span>
              <SoulTypeahead
                serverSide
                openOnFocus
                id="txt-company"
                options={options}
                value={field.value}
                loading={isLoading}
                onChange={onChange}
                placeholder="Empresa"
                data-testid="txt-company"
                disabled={isCompanyDisabled}
                onSearchChange={handleSearchCompanyChange}
                className={fieldState?.error ? "isInvalid" : ""}
              />
              <InvalidFeedback
                message="Este campo é obrigatório"
                condition={fieldState?.error?.type === "required"}
              />
            </label>
          );
        }}
      />
      {shouldShowCompetencyField ? (
        <Controller
          name="competency"
          shouldUnregister
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const { isLoading, options } = state.competency;

            const onChange = (event: ISoulTypeaheadChangeEvent) => {
              field.onChange(event);
              /** Reseta a classificação USP ao trocar a competência. */
              setValue("classificationUsp", null, { shouldValidate: true });
            };

            return (
              <label className="col-6 form-control">
                <span>
                  Competência{" "}
                  {isLoading && <i className="pi pi-spin pi-spinner" />}
                </span>
                <SoulTypeahead
                  serverSide
                  openOnFocus
                  options={options}
                  id="txt-competency"
                  value={field.value}
                  loading={isLoading}
                  onChange={onChange}
                  placeholder="Competência"
                  data-testid="txt-competency"
                  disabled={isCompetencyDisabled}
                  onSearchChange={handleSearchCompetencyChange}
                  className={fieldState?.error ? "isInvalid" : ""}
                />
                <InvalidFeedback
                  message="Este campo é obrigatório"
                  condition={fieldState?.error?.type === "required"}
                />
              </label>
            );
          }}
        />
      ) : null}
    </div>
  );
}
