import styled from "styled-components";

export const Container = styled.div`
  width: 805px;

  .loading-container {
    text-align: center;
    padding-bottom: 2rem;
  }

  .classification-accounts-header {
    padding: 1rem;
    margin: 0 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-size: 1rem;
      color: var(--text-muted);
    }
  }

  .react-modal-body {
    .table-header {
      padding: 1rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      label {
        display: grid;
        grid-template-columns: 60px 220px;
        grid-gap: 0.2rem;
      }

      p {
        color: #32325d;
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .main-table > tbody > tr {
      &.p-datatable-row-expansion {
        > td:first-child {
          padding: unset;
        }

        :hover {
          background: unset !important;
        }
      }

      &.p-row-odd {
        background: #fff;
      }
    }

    .simple-table-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .card-header-border {
    p {
      font-size: 0.8125rem;
      color: #8898aa;
      font-weight: 600;
    }

    h3 {
      font-size: 1.0625rem;
      color: #32325d;
      font-weight: 600;
    }

    h5 {
      font-size: 0.8125rem;
      color: #5e72e4;
      font-weight: 600;
    }

    & + section {
      margin-top: 1.5rem;
    }
  }

  .react-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;
