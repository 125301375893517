import { IEntity } from "../../../../../core/domain/entities/entity";

export enum EProfessorType {
  Empty = "",
  Usp = 0,
  NotUsp = 1,
}

export enum ProviderType {
  foreign = 0,
  individualPerson = 1,
  legalPerson = 2,
}

export enum ProviderDocumentType {
  other = 0,
  CPF = 1,
  CNPJ = 2,
}

export enum IBankAccountType {
  checkingAccount = 1,
  savingAccount = 2,
}

export interface IProviderEntity extends IEntity {
  retainFee: boolean;

  typeDescription?: string;
  type: ProviderType;
  professorType: EProfessorType; // tipo de professor

  document: string;
  documentType: ProviderDocumentType;

  // nome | nome fantasia
  name: string;
  // razao social
  corporationName?: string;

  phoneNumber: string;
  email: string;
  // inscricao municipal
  municipalRegistration: string;
  // inscricao estadual
  stateRegistration: string;
  // inscricao estadual isento
  isStateRegistrationExempt?: boolean;

  birthDate?: string;
  identityCard?: string;
  pisPasep?: string;
  profession?: string;

  // Dados bancarios

  // documento do titular da conta (para fornecedor pessoa juridica)
  documentHolder?: string;
  // numero do banco
  bank?: number;
  bankDescription?: string;
  // numero da agencia
  bankBranch?: string;
  // digito da agencia
  bankBranchDigit?: string;
  // tipo de conta bancaria
  bankAccountType?: IBankAccountType;
  // numero da conta
  bankAccount?: string;
  // digito da conta
  bankAccountDigit?: string;

  countryId: string;
  countryName: string;

  zipCode: string;
  street: string;
  // numero
  placeNumber: string;

  stateId: string;
  stateName: string;

  cityId: string;
  cityName: string;

  // bairro
  neighborhood: string;
  // complemento
  complement: string;

  companyGroupId: string;
  companyGroupName?: string;
  hasCompanyTax?: boolean;
}

export interface ICheckDocumentEntity {
  Code?: number;
  message?: string;
  success?: boolean;
}
