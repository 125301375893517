import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { ICompanyEntity } from "../../../../../company/domain/entities/companyEntity";
import { IServerSideResponseModel } from "../../../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../../../core/data/services/apiService";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import {
  EUserProfile,
  IUserEntity,
} from "../../../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IClassificationAccountEntity } from "../../../../../provider/domain/entities/classificationAccountEntity";
import { IGetPaymentRequestContract } from "../../domain/contracts/getPaymentRequestContract";
import { IPaymentRequestsAssessmentEntity } from "../../domain/entities/paymentRequestAssessmentEntity";
import { IPaymentRequestAttachmentEntity } from "../../domain/entities/paymentRequestAttachmentEntity";
import { EPaymentRequestStatus } from "../../domain/entities/paymentRequestEnums";
import { PaymentRequestFormEntity } from "../../domain/entities/paymentRequestFormEntity";
import { IProviderEntity } from "../../domain/entities/providerEntity";
import { IPaymentRequestsAssessmentModel } from "../models/paymentRequestAssessmentModel";
import { IPaymentRequestModel } from "../models/paymentRequestModel";

export class GetPaymentRequestService implements IGetPaymentRequestContract {
  private readonly endpoint = "/PaymentRequests";
  private readonly userEntity: IUserEntity | null = null;

  constructor(
    getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    this.userEntity = getUserLocalService.get();
  }

  async getPaymentRequest(paymentRequestId: string, companyGroupId: string) {
    const profile = this.userEntity?.profile;

    const requestData = await this.getData(paymentRequestId);

    const { status } = requestData;

    const isRequesterOrManager = [
      EUserProfile.manager,
      EUserProfile.requester,
    ].includes(profile || EUserProfile.master);

    /**
     * Os status *"Cancelado"*, *"Não solicitado"* e *"Solicitado"* são
     * inacessíveis para perfis que **não sejam** do tipo *Supervisor* ou *Gestor*
     * no **modo de edição**.
     *
     * Portanto, caso o usuário e a solicitação atendam as respectivas
     * situações, o processo deverá ser **encerrado**.
     */
    const isStatusNotAccessibleByOtherProfiles = [
      EPaymentRequestStatus.Canceled,
      EPaymentRequestStatus.Requested,
      EPaymentRequestStatus.NotRequested,
    ].includes(status);

    if (!isRequesterOrManager && isStatusNotAccessibleByOtherProfiles) {
      const isRequested = status === EPaymentRequestStatus.Requested;
      const defaultErrorMessage =
        "Não é possível acessar essa solicitação de pagamento.";
      const requestedErrorMessage =
        "Não é possível editar uma solicitação de pagamento que foi solicitada.";
      const errorMessage = isRequested
        ? requestedErrorMessage
        : defaultErrorMessage;

      return Promise.reject(new Error(errorMessage));
    }

    const dtIssueDate = new Date(requestData.issueDate);
    const dtPayUntil = new Date(requestData.payUntil);

    const assessments = await this.getAssessments(paymentRequestId);
    const storageFiles = await this.getStorageFiles(paymentRequestId);
    const company = await this.getCompany(
      requestData.companyId,
      companyGroupId,
    );
    const provider = await this.getProvider(requestData.providerId);
    const classificationAccount = await this.getClassificationAccount(
      requestData,
    );

    const billetAttachment = storageFiles.find(att => {
      return att.type === EAttachmentType.Billet;
    });

    const paymentRequest = new PaymentRequestFormEntity({
      assessments,
      storageFiles,
      duplicatePaymentRequestId: "",
      value: requestData.value,
      payUntil: format(dtPayUntil, "dd/MM/yyyy"),
      issueDate: format(dtIssueDate, "dd/MM/yyyy"),
      documentNumber: requestData.documentNumber,
      description: requestData.description || "",
      observation: requestData.observation || "",
      barcode: billetAttachment ? billetAttachment.barcode || null : null,
      classificationAccount: classificationAccount
        ? {
            metadata: classificationAccount,
            label: classificationAccount?.name || "",
            rawValue: classificationAccount?.id || "",
          }
        : null,
      paymentMethod: {
        value: "",
        key: requestData.paymentMethod,
      },
      documentStatus: {
        value: "",
        key: requestData.documentStatus,
      },
      status: requestData.status,
      destination: requestData.destination,
      company: {
        metadata: company,
        rawValue: company?.id || "",
        label: company?.assumedName || "",
      },
      provider: {
        metadata: provider,
        rawValue: provider?.id || "",
        label: provider?.name || "",
      },
    });

    return paymentRequest;
  }

  private async getData(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}`;

    return this.api.get<IPaymentRequestModel>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getProvider(providerId: string) {
    const url = `/Providers/${providerId}`;

    return this.api.get<IProviderEntity>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private async getCompany(companyId: string, companyGroupId: string) {
    const endpoint = `/CompanyGroups/${companyGroupId}/Companies`;

    const response = await this.api.get<
      IServerSideResponseModel<ICompanyEntity[]>
    >(endpoint, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });

    return response.data.find(c => c.id === companyId);
  }

  private async getAssessments(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}/Assessments`;

    const response = await this.api.get<IPaymentRequestsAssessmentModel[]>(
      url,
      {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      },
    );

    return response.map<IPaymentRequestsAssessmentEntity>(assessmentObject => {
      return {
        accountPayableId: null,
        id: assessmentObject.id,
        observation: assessmentObject.observation,
        value: new BigNumber(assessmentObject.value).toJSON(),
        percentage: new BigNumber(assessmentObject.percentage).toJSON(),
        classificationAssessment: {
          label: assessmentObject.classificationAssessmentName,
          rawValue: assessmentObject.classificationAssessmentId,
        },
        costCenter: {
          label: assessmentObject.costCenterName,
          rawValue: assessmentObject.costCenterId,
        },
      };
    });
  }

  private async getStorageFiles(paymentRequestId: string) {
    const url = `${this.endpoint}/${paymentRequestId}/Attachments`;

    return this.api.get<IPaymentRequestAttachmentEntity[]>(url, {
      headers: {
        Authorization: `Bearer ${this.userEntity?.token}`,
      },
    });
  }

  private getClassificationAccount(receivableData: IPaymentRequestModel) {
    const classificationAccountId = receivableData?.classificationAccountId;

    if (classificationAccountId) {
      const url = `/ClassificationAccounts/${classificationAccountId}`;

      return this.api.get<IClassificationAccountEntity>(url, {
        headers: {
          Authorization: `Bearer ${this.userEntity?.token}`,
        },
      });
    }

    return null;
  }
}
