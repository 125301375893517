import {
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { FaExclamationCircle, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";

import { ColumnProps } from "primereact/column";
import { ClientSoulTable } from "../../../../core/presentation/components/ClientSoulTable";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useClassName } from "../../../../core/presentation/hooks/useClassName";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";
import { IPaymentAccountResumedEntity } from "../../../domain/entities/paymentAccountEntity";
import { MakePaymentAccounts } from "../../../main/makePaymentAccounts";
import { ClassificationAccountTab } from "../ClassificationAccountTab";
import { Container } from "./styles";

interface ClassificationAccountLinkFormModalProps {
  isOpen: boolean;
  isRequired?: boolean;
  usePaymentAccounts: MakePaymentAccounts;
  paymentAccountEntity?: IPaymentAccountResumedEntity;
  onRequestClose: (hasLinked?: boolean) => void;
}
interface ClassificationAccountModalState {
  search: string;
  isLoading: boolean;
  expandedRows?: DataTableExpandedRows;
  isLoaddingButton: boolean;
  linkedClsAccounts?: IClassificationAccountEntity;
  showSelectedOnly: boolean;
  tableData: ICompanyEntity[];
}

export function ClassificationAccountLinkFormModal({
  isOpen,
  usePaymentAccounts,
  paymentAccountEntity,
  onRequestClose,
  isRequired = false,
}: ClassificationAccountLinkFormModalProps) {
  const { getClassificationAccountLinked, saveClassificationAccountLinked } =
    usePaymentAccounts;

  const dialog = useSoulDialog();

  const [state, setState] = useState<ClassificationAccountModalState>({
    search: "",
    isLoading: false,
    isLoaddingButton: false,
    linkedClsAccounts: undefined,
    showSelectedOnly: false,
    tableData: [],
  });

  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        expander: true,
        style: {
          width: "3%",
        },
        bodyTemplate(
          rowData: unknown,
          options: Record<string, Record<string, unknown>>,
        ) {
          return options.expander.element;
        },
      },
      {
        header: "Empresa",
        field: "assumedName",
      },
    ],
    [],
  );

  const handleClose = useCallback(() => {
    onRequestClose(false);
  }, [onRequestClose]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({ ...prevState, search: e.target.value }));
  };

  const handleAfterOpen = useCallback(async () => {
    if (paymentAccountEntity && paymentAccountEntity.id) {
      const classificatonAccountsLinked = await getClassificationAccountLinked(
        paymentAccountEntity?.id,
      );

      const currentCompany = [
        {
          assumedName: paymentAccountEntity.companyName,
          id: paymentAccountEntity.companyId,
          classificationAccounts: [],
        },
      ];

      setState(prevState => ({
        ...prevState,
        tableData: currentCompany,
        linkedClsAccounts: classificatonAccountsLinked,
      }));
    }
  }, [paymentAccountEntity, getClassificationAccountLinked]);

  const handleSubmit = useCallback(async () => {
    if (!state.linkedClsAccounts) {
      return;
    }

    const linkedClsAccountsId = state.linkedClsAccounts?.id;

    if (paymentAccountEntity && state.linkedClsAccounts) {
      setState(prevState => ({
        ...prevState,
        isLoaddingButton: true,
      }));
      try {
        await saveClassificationAccountLinked(
          paymentAccountEntity.id,
          linkedClsAccountsId,
        );

        setState(prevState => ({
          ...prevState,
          isLoaddingButton: false,
        }));

        await dialog.fire({
          title: "Feito!",
          text: "Classificação(ões) contábil(eis) vinculada(s) com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        });

        onRequestClose(true);
      } catch {
        dialog.close();
      }
    }
  }, [
    dialog,
    onRequestClose,
    paymentAccountEntity,
    saveClassificationAccountLinked,
    state.linkedClsAccounts,
  ]);

  const submitClassName = useClassName(
    {
      "form-button": true,
      "invalid-bkg": !state.linkedClsAccounts,
      "green-bkg": !!state.linkedClsAccounts,
    },
    [state.linkedClsAccounts],
  );

  const handleRowToggle = async ({ data }: DataTableRowToggleParams) => {
    setState(prevState => ({
      ...prevState,
      expandedRows: data as DataTableExpandedRows,
    }));
  };

  // Callback para receber dados do componente filho
  const handleSelectionUpdate = (info: IClassificationAccountEntity) => {
    setState(prevState => ({
      ...prevState,
      linkedClsAccounts: info,
    }));
  };

  const handleInvalidOnlyChange = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showSelectedOnly: value,
    }));
    return value;
  };

  const rowExpansionTemplate = (data: ICompanyEntity) => {
    return (
      <ClassificationAccountTab
        companyId={data.id}
        usePaymentAccounts={usePaymentAccounts}
        search={state.search}
        linkedClsAccounts={state.linkedClsAccounts}
        onSelectionUpdate={handleSelectionUpdate}
        showSelectedOnly={state.showSelectedOnly}
      />
    );
  };

  const handleAfterClose = () => {
    setState({
      search: "",
      isLoading: false,
      isLoaddingButton: false,
      linkedClsAccounts: undefined,
      showSelectedOnly: false,
      tableData: [],
      expandedRows: undefined,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.isLoading}
      shouldCloseOnOverlayClick={!state.isLoading}
      onAfterClose={handleAfterClose}
      onAfterOpen={handleAfterOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Vínculo de classificações contábeis ao cliente</h4>
          {!state.isLoading ? (
            <button
              className="react-modal-close"
              type="button"
              id="btn-cross-class-account"
              data-testid="btn-cross-class-account"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        <div className="classification-accounts-header">
          {isRequired ? (
            <div className="required-warning">
              <FaExclamationCircle />
              <p>
                Para prosseguir é obrigatório vincular pelo menos uma
                Classificação Contábil ao Cliente.
              </p>
            </div>
          ) : null}
          <div className="card card-header-border">
            <p>Vinculando classificações contábeis ao cliente</p>
            <h3>{paymentAccountEntity?.name}</h3>
            <h5>
              Clique sobre a classificação contábil e depois clique em &ldquo;
              Salvar&ldquo; para vinculá-la ao cliente.
            </h5>
          </div>
        </div>
        <div className="react-modal-body">
          <div className="table-header">
            <InputSearch value={state.search} onChange={handleSearchChange} />
            <label>
              <Toggle
                id="btn-toggle-invalid"
                value={state.showSelectedOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleInvalidOnlyChange}
              />
              Exibir somente selecionados
            </label>
          </div>
          <div className="simple-table-wrapper">
            <ClientSoulTable
              paginator={false}
              rowHover
              dataKey="id"
              data={state.tableData}
              columns={columns}
              expandedRows={state.expandedRows}
              onRowToggle={handleRowToggle}
              rowsPerPageOptions={[5, 10, 50]}
              tableClassName="main-table"
              emptyMessage="Nenhum registro encontrado"
              rowExpansionTemplate={rowExpansionTemplate}
            />
          </div>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            className="form-button red-bkg"
            id="btn-close-class-account"
            onClick={handleClose}
          >
            Fechar
          </button>
          <button
            type="submit"
            className={submitClassName}
            id="btn-save-class-account"
            disabled={state.isLoaddingButton || !state.linkedClsAccounts}
            onClick={handleSubmit}
          >
            Salvar {state.isLoaddingButton && <FaSpinner className="spinner" />}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
