import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveSolicitImportFileContract } from "../../domain/contracts/saveSolicitImportFileContract";
import { ISolicitImportAssessmentEntity } from "../../domain/entities/solicitImportAssessmentEntity";
import { SolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import { ISolicitImportResponseModel } from "../models/solicitImportModel";
import { MainSolicitImportService } from "./mainSolicitImportService";

export class SaveSolicitImportFileService
  extends MainSolicitImportService
  implements ISaveSolicitImportFileContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {
    super();
  }

  async saveSolicitImportFile(file: File) {
    const userEntity = this.getUserLocalService.get();
    const formData = new FormData();

    formData.append("file", file);

    const url = "/PaymentRequestImportations";

    const importList = await this.api.post<ISolicitImportResponseModel[]>(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    /** É necessário que as linhas inconsistentes sejam as primeiras linhas. */
    const orderedList = importList.sort(a => {
      return a.hasError ? -1 : 0;
    });

    const importData = orderedList.map(data => {
      const datePayUntil = new Date(data.payUntil);
      const dateIssueDate = new Date(data.issueDate);

      const company = this.buildTypeaheadObject(data, "company");
      const competency = this.buildTypeaheadObject(data, "competency");

      if (company) {
        company.metadata = {
          hasProject: data.getCompanyHasProject,
        };
      }

      if (competency) {
        competency.metadata = {
          isUsp: data.getCompetencyIsUsp,
        };
      }

      return new SolicitImportEntity({
        id: data.id,
        accountAlreadyExists: data?.accountAlreadyExists || false,
        assessments:
          data?.assessments?.map<ISolicitImportAssessmentEntity>(
            assessment => ({
              hasError: assessment.hasError,
              fieldErrors: assessment.fieldErrors,
              observation: assessment.observation || "",
              value: new BigNumber(assessment.value).toJSON(),
              valueAssessmentLeft: assessment.valueAssessmentLeft,
              percentage: new BigNumber(assessment.percentage).toJSON(),
              costCenter: this.buildTypeaheadObject(assessment, "costCenter"),
              classificationAssessment: this.buildTypeaheadObject(
                assessment,
                "classificationAssessment",
              ),
            }),
          ) || [],
        barcode: data?.barcode || "",
        classificationAccount: this.buildTypeaheadObject(
          data,
          "classificationAccount",
        ),
        company,
        description: data?.description?.toUpperCase() || "",
        documentNumber: data?.documentNumber?.toUpperCase() || "",
        documentStatus: {
          key: data?.documentStatus ?? "",
          value: data.documentStatusDescription,
        },
        getCompanyHasProject: data.getCompanyHasProject,
        getCompetencyIsUsp: data.getCompetencyIsUsp,
        hasAssessmentError: data.hasAssessmentError,
        hasError: data.hasError,
        issueDate: format(dateIssueDate, "dd/MM/yyyy"),
        observation: data?.observation?.toUpperCase() || "",
        payUntil: format(datePayUntil, "dd/MM/yyyy"),
        paymentMethod: {
          key: data?.paymentMethod ?? "",
          value: data.paymentMethodDescription,
        },
        provider: this.buildTypeaheadObject(data, "provider"),
        storageFiles: data?.storageFiles,
        value: data.value,
        valueAssessmentLeft: data.valueAssessmentLeft,
        fieldErrors: data.fieldErrors,
      });
    });

    return importData;
  }
}
