import {
  MenuItem,
  MenuItemCommandParams,
  MenuItemOptions,
} from "primereact/menuitem";
import { useMemo } from "react";
import {
  FaArrowAltCircleLeft,
  FaCheckCircle,
  FaDatabase,
  FaEye,
  FaPaperclip,
  FaRegClone,
  FaTimesCircle,
  FaUndo,
} from "react-icons/fa";
import { SoulRoutes } from "../../../../../admin/domain/entities/soulRoutes";
import { EUserProfile } from "../../../../../core/domain/entities/userEntity";
import { useUserLocal } from "../../../../../core/presentation/hooks/useUserLocal";
import {
  EAccountReceivableStatus,
  EInvoiceStatus,
  EReturnStatus,
  IAccountReceivableParcelListItemEntity,
} from "../../domain/entities/accountReceivableListItemEntity";
import { ContextMenuItem } from "../components/AccountsReceivablePageContextMenu/styles";
import { useAccountsReceivablePage } from "./useAccountsReceivablePage";

export function useAccountsReceivableContextMenu() {
  const {
    menuRef,
    handleTerminateItemClick,
    handleRemoveTerminationItemClick,
    handleReturnItemClick,
    handleUndoReturnItemClick,
    handleAttachmentItemClick,
    handleCancelItemClick,
    handleInvoiceDenialReasonEmitterClick,

    ...rest
  } = useAccountsReceivablePage();

  const { contextMenuData } = rest.state;

  const {
    user: { profile },
  } = useUserLocal();

  return useMemo(() => {
    const status = contextMenuData?.status;
    const returnStatus = contextMenuData?.returnStatus;
    const accountReceivableId = contextMenuData?.accountReceivableId || "";
    const anyParcelPaid = contextMenuData?.anyParcelPaid;
    const isInconsistent =
      contextMenuData?.status === EAccountReceivableStatus.Inconsistent;
    const invoiceStatusDenied =
      contextMenuData?.invoiceStatus === EInvoiceStatus.Denied;

    /**
     * Botão "Editar".
     * Visível quando a conta não se tratar de uma taxa e não tenha
     * sido devolvida.
     */
    const editButton: MenuItem = {
      label: "Editar",
      target: "_blank",
      data: contextMenuData,
      disabled: returnStatus !== EReturnStatus.NotReturned,
      visible: returnStatus === EReturnStatus.NotReturned,
      url: `${SoulRoutes.ACCOUNTS_RECEIVABLE.path}/${accountReceivableId}`,
      icon: (
        <FaDatabase
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    /**
     * Botão "Visualizar".
     * Visível caso a conta ainda não tenha sido devolvida.
     */
    const viewButton: MenuItem = {
      label: "Ver",
      // sempre que presente está habilitado
      disabled: false,
      data: contextMenuData,
      visible: returnStatus !== EReturnStatus.NotReturned,
      url: `${SoulRoutes.ACCOUNTS_RECEIVABLE.path}/${accountReceivableId}`,
      icon: (
        <FaEye
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    /**
     * Botão "Baixar".
     * Sempre visível.
     */
    const terminateButton: MenuItem = {
      data: contextMenuData,
      disabled:
        status === EAccountReceivableStatus.Paid ||
        status === EAccountReceivableStatus.Canceled ||
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link terminate ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Baixar">
              <FaCheckCircle />
              Baixar
            </span>
          </ContextMenuItem>
        );
      },
      command(event) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleTerminateItemClick(accountReceivable);
      },
    };

    /**
     * Botão "Retirar baixar".
     * Sempre visível para perfis diferentes de "interno".
     */
    const removeTerminationButton: MenuItem = {
      data: contextMenuData,
      visible: profile !== EUserProfile.internal,
      disabled:
        status === EAccountReceivableStatus.Open ||
        status === EAccountReceivableStatus.Canceled ||
        returnStatus !== EReturnStatus.NotReturned,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link remove-termination ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Retirar baixa">
              <FaArrowAltCircleLeft />
              Retirar baixa
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleRemoveTerminationItemClick(accountReceivable);
      },
    };

    /**
     * Botão "Devolver".
     * Visível caso a conta não tenha sido retornada e ela já tenha sido baixada.
     * Somente para perfis diferentes de "interno".
     */
    const returnButton: MenuItem = {
      data: contextMenuData,
      disabled: returnStatus !== EReturnStatus.NotReturned,
      visible:
        profile !== EUserProfile.internal &&
        returnStatus === EReturnStatus.NotReturned &&
        status === EAccountReceivableStatus.Paid,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Devolução">
              <FaUndo />
              Devolução
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleReturnItemClick(accountReceivable);
      },
    };

    /**
     * Botão "Desfazer devolução".
     * Visível apenas caso a conta não seja a respeito de uma taxa, além de não
     * ter sido devolvida. Somente para perfis diferentes de "interno"
     */
    const undoReturnButton: MenuItem = {
      data: contextMenuData,
      // sempre que presente esta habilitado
      disabled: false,
      visible:
        profile !== EUserProfile.internal &&
        returnStatus !== EReturnStatus.NotReturned &&
        status === EAccountReceivableStatus.Paid,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Desfazer Devolução">
              <FaUndo />
              Desfazer Devolução
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountPayable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountPayable) {
          return;
        }

        handleUndoReturnItemClick(accountPayable);
      },
    };

    /**
     * Botão "Cancelar".
     * Sempre visível.
     */
    const cancelButton: MenuItem = {
      data: contextMenuData,
      disabled:
        status === EAccountReceivableStatus.Paid ||
        returnStatus !== EReturnStatus.NotReturned ||
        status === EAccountReceivableStatus.Canceled,
      visible: !anyParcelPaid && !isInconsistent,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link cancel ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Cancelar">
              <FaTimesCircle />
              Cancelar
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleCancelItemClick(accountReceivable);
      },
    };

    const invoiceDenialReasonEmitte: MenuItem = {
      data: contextMenuData,
      disabled: false,
      visible: invoiceStatusDenied && !isInconsistent,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Motivo de Negação NF">
              <FaEye />
              Motivo de Negação NF
            </span>
          </ContextMenuItem>
        );
      },
      command(event: MenuItemCommandParams) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleInvoiceDenialReasonEmitterClick(accountReceivable);
      },
    };

    /**
     * Botão "Alterar anexos".
     * Sempre visível.
     */
    const changeAttachmentsButton: MenuItem = {
      data: contextMenuData,
      disabled: status === EAccountReceivableStatus.Canceled,
      template(_item: MenuItem, { onClick }: MenuItemOptions) {
        const disabled = _item.disabled ? "disabled" : "";

        return (
          <ContextMenuItem
            onClick={onClick}
            className={`p-menuitem-link ${disabled}`.trim()}
          >
            <span className="p-menuitem-text" title="Alterar anexos">
              <FaPaperclip />
              Alterar anexos
            </span>
          </ContextMenuItem>
        );
      },
      command(event) {
        const accountReceivable = event?.item
          ?.data as IAccountReceivableParcelListItemEntity;

        if (!accountReceivable) {
          return;
        }

        handleAttachmentItemClick(accountReceivable);
      },
    };

    /**
     * Botão "Duplicar".
     * Sempre visível.
     */
    const duplicateButton: MenuItem = {
      label: "Duplicar",
      target: "_blank",
      data: contextMenuData,
      disabled: false,
      url: `${SoulRoutes.ACCOUNTS_RECEIVABLE.path}/new?duplicate=${accountReceivableId}`,
      icon: (
        <FaRegClone
          className="p-menuitem-icon"
          style={{
            fill: "var(--soul-color02)",
          }}
        />
      ),
    };

    const separator = { separator: true };

    const menuModel: MenuItem[] = [
      editButton,
      viewButton,
      separator,
      terminateButton,
      removeTerminationButton,
      returnButton,
      undoReturnButton,
      cancelButton,
      separator,
      changeAttachmentsButton,
      duplicateButton,
      invoiceDenialReasonEmitte,
    ];

    return {
      menuModel,
      menuRef,
    };
  }, [
    contextMenuData,
    handleAttachmentItemClick,
    handleRemoveTerminationItemClick,
    handleReturnItemClick,
    handleTerminateItemClick,
    handleUndoReturnItemClick,
    handleCancelItemClick,
    menuRef,
    profile,
    handleInvoiceDenialReasonEmitterClick,
  ]);
}
