import { format, parse } from "date-fns";
import { IApiService } from "../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../core/domain/usecases/getUserLocalUseCase";
import { ISetServerSideService } from "../../../../core/domain/usecases/setServerSideUseCase";
import { IPFSEventEntity } from "../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  ISimpleColumn,
  ISimpleHiddenColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { IPayloadEntity } from "../../../../simpleTable/domain/entities/simplePayloadEntity";
import { IGeneratePayloadUseCase } from "../../../../simpleTable/domain/usecases/generatePayloadUseCase";
import { IGenerateAccountFileContract } from "../../domain/contracts/generateAccountFileContract";
import { IFiltersValue } from "../../domain/contracts/listPaymentAccountReportContract";
import { URLSearchParamsType } from "./listPaymentAccountReportService";

export class GenerateAccountFileService
  implements IGenerateAccountFileContract
{
  constructor(
    private advGeneratePayloadExportUseCase: IGeneratePayloadUseCase,
    private setServerSideService: ISetServerSideService,
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async generateAccountFile(
    companyGroupId: string,
    pfsEvent: IPFSEventEntity,
    selectedColumns: ISimpleColumn[],
    orderedColumnNames: string[],
    filters: IFiltersValue,
  ) {
    const userEntity = this.getUserLocalService.get();
    // primeiro colocamos no payload somente as colunas que NAO SAO hidden
    // é importante percorrer as colunas selecionadas retirando as hidden
    // pois o orderedColumnNames vem com as hidden inclusas
    let payloadColumns = selectedColumns
      .reduce((accumulatorArray, selCol) => {
        const col = selCol as unknown as ISimpleHiddenColumn;
        const index = orderedColumnNames.findIndex(
          name => name === col.field && !col.hidden,
        );
        const retArr = [...accumulatorArray];

        if (index !== -1) {
          retArr[index] = selCol;
        }

        return retArr;
      }, [] as ISimpleColumn[])
      .filter((col: ISimpleColumn): col is ISimpleColumn => !!col);

    // Aqui nao precisamos de todas as colunas que estao configuradas como
    // obrigatorias (hidden) para a UI, só precisamos das que vao aparecer
    // no relatorio
    const hiddenColumns = [
      { field: "numberOfParcels", hidden: true },
      { field: "originBarcode", hidden: true },
      { field: "originId", hidden: true },
      { field: "origin", hidden: true },
      { field: "type", hidden: true },
    ];

    payloadColumns = [
      ...payloadColumns,
      ...(hiddenColumns as unknown as ISimpleColumn[]),
    ];

    const payload = this.advGeneratePayloadExportUseCase.generatePayload(
      pfsEvent,
      payloadColumns,
    ) as unknown as Record<string, object>;

    // este bloco de codigo insere o mapa de colunas que ajuda a
    // API a saber valor de qual coluna deve utilizar no excel
    payload.orderableStringField = {
      totalValueString: "totalValue",
      operationValueString: "operationValue",
      operationDateString: "operationDate",
      issueDateString: "issueDate",
      documentStatusDescription: "documentStatus",
      statusDescription: "status",
      returnStatusDescription: "returnStatus",
      percentageString: "percentage",
      dateCreatedString: "dateCreated",
      dateModifiedString: "dateModified",
      deleteDateString: "deleteDate",
      typeDescription: "type",
    };

    const serverSideId = await this.setServerSideService.setServerSide(
      payload as unknown as IPayloadEntity,
    );

    let startDate: Date | null = null;
    if (filters.startDate) {
      startDate = parse(filters.startDate, "dd/MM/yyyy", new Date());
    }

    let endDate: Date | null = null;
    if (filters.endDate) {
      endDate = parse(filters.endDate, "dd/MM/yyyy", new Date());
    }

    const paramsOptions: URLSearchParamsType = {
      body: serverSideId,
      InitialFilterDate: startDate ? format(startDate, "yyyy-MM-dd") : "",
      FinalFilterDate: endDate ? format(endDate, "yyyy-MM-dd") : "",
      ProjectId: filters?.projectId,
      ReportFilterDate: filters.reportFilterDate,
      PaymentAccountId: filters?.paymentAccountId,
    };

    const params = new URLSearchParams(paramsOptions);

    const url = `/Reports/AccountingArchive?${params}`;

    try {
      await this.api.get?.(url, {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
          "Company-Group-Id": companyGroupId,
        },
      });
    } catch (err) {
      Promise.reject(err);
    }
  }
}
