import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { IClassificationUspEntity } from "../entities/classificationUspEntity";

export interface IListClassificationUspService {
  listClassificationUsp(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<IClassificationUspEntity[]>>;
}

export interface IListClassificationUspUseCase {
  listClassificationUsp(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<IClassificationUspEntity[]>>;
}

export class ListClassificationUspUseCase
  implements IListClassificationUspUseCase
{
  constructor(
    private listClassificationUspService: IListClassificationUspService,
  ) {}

  listClassificationUsp(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives?: boolean,
  ): Promise<IResponseEntity<IClassificationUspEntity[]>> {
    return this.listClassificationUspService.listClassificationUsp(
      companyGroupId,
      payload,
      actives,
    );
  }
}
