import { useCallback, useMemo } from "react";
import { FaEdit, FaLink } from "react-icons/fa";
import { toast } from "react-toastify";
import { useCurrentCompanyGroup } from "../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useAllowedProfiles } from "../../../core/presentation/hooks/useAllowedProfiles";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ClassificationAccountByCompanyIdEntity,
  IClassificationAccountByCompanyIdEntity,
} from "../../domain/entities/classificationAccountByCompanyIdEntity";
import {
  GERAL_COMPANY_ID,
  ICompanyEntity,
} from "../../domain/entities/companyEntity";

export interface UseClassificationAccountsGridProps {
  openClassificationAccountModal(
    info: IClassificationAccountByCompanyIdEntity,
  ): void;
  toggle(classificationAccountId: string, actionText: string): Promise<void>;
  company: ICompanyEntity;
}

export function useClassificationAccountsGrid({
  toggle,
  openClassificationAccountModal,
  company,
}: UseClassificationAccountsGridProps) {
  const dialog = useSoulDialog();
  const allowedProfiles = useAllowedProfiles();
  const { currentCompanyGroup } = useCurrentCompanyGroup();

  /**
   * Indica se o usuário atual pode realizar o "soft delete", ou seja, inativar
   * um projeto.
   */
  const canDeleteClassificationAccount = useMemo(
    () =>
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ) && company.active,
    [allowedProfiles, company],
  );

  /**
   * Constrói a célula de edição de uma classificação contábil.
   */
  const editBodyTemplate = useCallback(
    (
      classificationAccount: IClassificationAccountByCompanyIdEntity,
      { rowIndex },
    ) => {
      return (
        <div className="table-actionbar">
          {company.id !== GERAL_COMPANY_ID ? (
            <button
              type="button"
              id={`btn-edit-${rowIndex}`}
              className="outline-button tool"
              data-testid={`btn-edit-${rowIndex}`}
              data-tip="Editar Classificação Contábil"
              onClick={() => {
                openClassificationAccountModal(classificationAccount);
              }}
            >
              <FaEdit />
            </button>
          ) : null}
          <button
            type="button"
            id={`btn-copy-id-${rowIndex}`}
            className="outline-button tool"
            data-testid={`btn-copy-id-${rowIndex}`}
            data-tip="Copiar ID da Classificação Contábil"
            onClick={() => {
              navigator.clipboard.writeText(classificationAccount.id);
              toast.success(
                "O ID da Classificação Contábil foi copiado para sua área de transferência",
                {
                  autoClose: 2000,
                },
              );
            }}
          >
            <FaLink />
          </button>
        </div>
      );
    },
    [company.id, openClassificationAccountModal],
  );

  /**
   * Constrói a célula de ativar/desativar uma classificação contábil.
   */

  const activeBodyTemplate = useCallback(
    ({ id, active }: IClassificationAccountByCompanyIdEntity, { rowIndex }) => {
      const isGeral = company.id === GERAL_COMPANY_ID;
      const isCompanyActive = company.active;

      return (
        <Toggle
          value={active}
          inactiveClassName="toggle-muted"
          id={`btn-tgl-status-${rowIndex}`}
          readonly={!canDeleteClassificationAccount}
          data-testid={`btn-tgl-status-${rowIndex}`}
          disabled={isGeral || !isCompanyActive}
          onChange={async _active => {
            const actionText = !_active ? "inativada" : "ativada";
            const result = await dialog.fire({
              icon: "question",
              title: "Você está certo disso?",
              html: `A Classificação Contábil será ${actionText}. <br /> Deseja prosseguir?`,
              showCancelButton: true,
              cancelButtonText: "Não",
              async preConfirm() {
                dialog.update({
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                });
                dialog.showLoading();
                await toggle(id, actionText);
              },
            });

            if (result.isConfirmed) {
              return _active;
            }

            return !_active;
          }}
        />
      );
    },
    [
      canDeleteClassificationAccount,
      company.active,
      company.id,
      dialog,
      toggle,
    ],
  );

  const columns = useMemo<SimpleTableColumn[]>(
    () =>
      [
        {
          field: "companyGroupName",
          header: "Grupo de Empresa",
          searchable: false,
          orderable: false,
          bodyTemplate: (item: ClassificationAccountByCompanyIdEntity) => {
            if (item.companyGroupName === undefined) {
              return currentCompanyGroup.name;
            }
            return item.companyGroupName;
          },
        },
        {
          field: "name",
          header: "Nome",
          searchable: true,
          orderable: true,
        },
        {
          header: "Tipo",
          field: "typeDescription",
          searchable: true,
          orderable: true,
        },
        {
          header: "Lançamento",
          field: "issueTypeDescription",
          searchable: true,
          orderable: true,
        },

        { field: "code", header: "Código", searchable: true, orderable: true },
        {
          field: "callNumber",
          header: "Chamada",
          searchable: true,
          orderable: true,
        },
        {
          header: "",
          bodyTemplate: editBodyTemplate,
        },
        {
          field: "active",
          header: "Ativo",
          searchable: false,
          orderable: false,
          bodyTemplate: activeBodyTemplate,
        },
        {
          hidden: true,
          field: "issueType",
          searchable: false,
          orderable: false,
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [activeBodyTemplate, currentCompanyGroup.name, editBodyTemplate],
  );

  return { columns };
}
