import { ColumnProps } from "primereact/column";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FaPaperclip, FaTrash } from "react-icons/fa";
import { EAttachmentType } from "../../../../../core/domain/entities/attachmentsGridTypes";
import { ISolicitImportEntity } from "../../domain/entities/solicitImportEntity";
import { ISolicitImportForm } from "../../domain/entities/solicitImportTypes";
import { BarcodeField } from "../components/Fields/BarcodeField";
import { CompanyField } from "../components/Fields/CompanyField";
import { DescriptionField } from "../components/Fields/DescriptionField";
import { DocumentNumberField } from "../components/Fields/DocumentNumberField";
import { DocumentStatusField } from "../components/Fields/DocumentStatusField";
import { IssueDateField } from "../components/Fields/IssueDateField";
import { ObservationField } from "../components/Fields/ObservationField";
import { PayUntilField } from "../components/Fields/PayUntilField";
import { PaymentMethodField } from "../components/Fields/PaymentMethodField";
import { ClassificationAccountField } from "../components/Fields/ClassificationAccountField";
import { ProviderField } from "../components/Fields/ProviderFIeld";
import { ValueField } from "../components/Fields/ValueField";
import { useSolicitImportPage } from "./useSolicitImportPage";

interface UseValidationStepGridProps {
  handleFormsRemoval(formsIds: string[]): boolean;
  handleOpenAttachmentModal(formIndex: number): void;
}

export function useValidationStepGrid(props: UseValidationStepGridProps) {
  const { handleOpenAttachmentModal, handleFormsRemoval } = props;

  const { getFormIndex, handleRemoveForm } = useSolicitImportPage();

  const { getValues, getFieldState, formState } =
    useFormContext<ISolicitImportForm>();

  const handleRowClassName = ({ id }: ISolicitImportEntity) => {
    const formIndex = getFormIndex(id);
    const fieldState = getFieldState(`imports.${formIndex}`, formState);

    const isInvalid = !!fieldState.error;
    const isEdited = fieldState.isDirty && fieldState.isTouched;

    return {
      "row-edited": isEdited,
      "row-invalid": isInvalid,
      "row-valid": !isEdited && !isInvalid,
    };
  };

  const handleRowExpansionTemplate = useCallback(
    (data: ISolicitImportEntity) => {
      const formIndex = getFormIndex(data.id);

      const formRowData = getValues(`imports.${formIndex}`);

      const billetAttachment = formRowData?.storageFiles?.find(a => {
        return Number(a.type) === EAttachmentType.Billet;
      });

      return (
        <div className="expanded-template">
          <div className="form-fields">
            <DescriptionField formIndex={formIndex} />
            <PaymentMethodField formIndex={formIndex} />
            <DocumentStatusField formIndex={formIndex} />
            <IssueDateField formIndex={formIndex} />
            <PayUntilField formIndex={formIndex} />
            <ObservationField formIndex={formIndex} />
            <ClassificationAccountField formIndex={formIndex} />
          </div>
          {billetAttachment ? <BarcodeField formIndex={formIndex} /> : null}
        </div>
      );
    },
    [getFormIndex, getValues],
  );

  const columns: ColumnProps[] = useMemo(() => {
    return [
      {
        field: "expander",
        expander: true,
        style: {
          width: "3%",
        },
      },
      {
        header: "Empresa",
        field: "companyName",
        style: {
          width: "15%",
        },
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <CompanyField formIndex={formIndex} />;
        },
      },
      {
        header: "Nº do Documento",
        field: "documentNumber",
        style: {
          width: "15%",
        },
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <DocumentNumberField formIndex={formIndex} />;
        },
      },
      {
        header: "Fornecedor",
        field: "providerName",
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <ProviderField formIndex={formIndex} />;
        },
      },
      {
        header: "Valor",
        field: "value",
        style: {
          width: "15%",
        },
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          return <ValueField formIndex={formIndex} />;
        },
      },
      {
        field: "edit-attachments",
        style: {
          width: "3%",
        },
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          const storageFiles = getValues(`imports.${formIndex}.storageFiles`);
          return (
            <button
              type="button"
              data-effect="solid"
              data-tip="Editar anexos"
              id={`btn-attachment-${formIndex}`}
              data-testid={`btn-attachment-${formIndex}`}
              className="outline-button attachment-button"
              onClick={() => {
                handleOpenAttachmentModal(formIndex);
              }}
            >
              <FaPaperclip />
              <span>{storageFiles?.length || 0}</span>
            </button>
          );
        },
      },
      {
        field: "remove-row",
        style: {
          width: "3%",
        },
        body(data: ISolicitImportEntity) {
          const formIndex = getFormIndex(data.id);
          return (
            <button
              type="button"
              data-tip="Remover"
              data-effect="solid"
              className="p-link p-row-toggler"
              id={`btn-remove-row-${formIndex}`}
              data-testid={`btn-remove-row-${formIndex}`}
              onClick={() => {
                handleRemoveForm([data.id], handleFormsRemoval);
              }}
            >
              <FaTrash />
            </button>
          );
        },
      },
    ];
  }, [
    getValues,
    getFormIndex,
    handleRemoveForm,
    handleFormsRemoval,
    handleOpenAttachmentModal,
  ]);

  return {
    columns,
    handleRowClassName,
    handleRowExpansionTemplate,
  };
}
