import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .no-padding {
    padding: 0;
  }

  .actives-only {
    display: flex;
    gap: 0.5rem;
  }
`;
