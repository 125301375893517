import { DataTableSelectionChangeParams } from "primereact/datatable";
import { useCallback, useRef, useState } from "react";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { SimpleTable } from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import { MakePaymentAccounts } from "../../../main/makePaymentAccounts";
import { Container } from "./style";
import { useClassificationAccountGrid } from "../../hooks/useClassificationAccountsGrid";
import { GERAL_COMPANY_ID } from "../../../../classificationAccount/domain/entities/companyEntity";

interface ClassificationAccountLinkFormModalProps {
  companyId: string;
  usePaymentAccounts: MakePaymentAccounts;
  search: string;
  linkedClsAccounts: IClassificationAccountEntity | undefined;
  onSelectionUpdate(currenteSelection: IClassificationAccountEntity): void;
  showSelectedOnly: boolean;
}

export function ClassificationAccountTab({
  companyId,
  usePaymentAccounts,
  search,
  linkedClsAccounts,
  onSelectionUpdate,
  showSelectedOnly,
}: ClassificationAccountLinkFormModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { generatePayload } = useTables();

  const [tableData, setTableData] =
    useState<IResponseEntity<IClassificationAccountEntity[]>>();

  const { columns } = useClassificationAccountGrid();

  const { listClassificationAccountByCompanyId } = usePaymentAccounts;

  const linkedClsAccountsRef = useRef(linkedClsAccounts);

  const handleSelectionChange = (event: DataTableSelectionChangeParams) => {
    onSelectionUpdate(event.value);
    linkedClsAccountsRef.current = event.value;
  };

  const getListClassificationAccountByCompanyId = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setIsLoading(true);
      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);

      let selectedIds =
        showSelectedOnly && linkedClsAccountsRef.current
          ? [linkedClsAccountsRef.current.id]
          : [];

      if (showSelectedOnly && selectedIds.length === 0) {
        selectedIds = ["empty"];
      }
      const classificatonAccountsdata =
        await listClassificationAccountByCompanyId(
          payload,
          companyId,
          search,
          selectedIds,
        );

      if (companyId === GERAL_COMPANY_ID && showSelectedOnly) {
        const geralClassificationData = classificatonAccountsdata.data.filter(
          obj => selectedIds.includes(obj.id),
        );
        classificatonAccountsdata.data = geralClassificationData;
        classificatonAccountsdata.recordsTotal = geralClassificationData.length;
      }

      setTableData(classificatonAccountsdata);
      setIsLoading(false);
    },
    [
      columns,
      companyId,
      generatePayload,
      listClassificationAccountByCompanyId,
      search,
      showSelectedOnly,
    ],
  );

  return (
    <Container>
      <SimpleTable
        paginator
        columns={columns}
        data={tableData}
        emptyMessage="Nenhum registro encontrado"
        selection={linkedClsAccounts}
        onSelectionChange={handleSelectionChange}
        getList={getListClassificationAccountByCompanyId}
        loading={isLoading}
        pageLinkSize={3}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Container>
  );
}
