import { ParsePanoramaAngularService } from "../../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../../advTable/data/services/transformPanoramaAngularService";
import { AdvGeneratePayloadExportUseCase } from "../../../../advTable/domain/usecases/advGeneratePayloadExportUseCase";
import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../../core/data/services/listAttachmentTypesService";
import { SearchClassificationAccountService } from "../../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchPaymentAccountService } from "../../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SearchProviderService } from "../../../../core/data/services/searchProviderService";
import { SearchUserService } from "../../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { ValidateMeasurementAttachmentService } from "../../../../core/data/services/validateMeasurementAttachmentService";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationAccountContract } from "../../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchPaymentAccountContract } from "../../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { ISearchProviderContract } from "../../../../core/domain/contracts/searchProviderContract";
import { ISearchUserContract } from "../../../../core/domain/contracts/searchUserContract";
import { IValidateMeasurementAttachmentContract } from "../../../../core/domain/contracts/validateMeasurementAttachmentContract";
import { CancelPaymentRequestService } from "../data/services/cancelPaymentRequestService";
import { DeletePaymentRequestPanoramaService } from "../data/services/deletePaymentRequestPanoramaService";
import { ExportPaymentRequestService } from "../data/services/exportPaymentRequestService";
import { GenerateReportByCostCenterService } from "../data/services/generateReportByCostCenterService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { GetStoredPaymentRequestPanoramaIdService } from "../data/services/getStoredPaymentRequestPanoramaIdService";
import { ListPaymentRequestPanoramasService } from "../data/services/listPaymentRequestPanoramasService";
import { ListPaymentRequestsService } from "../data/services/listPaymentRequestsService";
import { ListRequesterAttachmentTypesService } from "../data/services/listRequesterAttachmentTypesService";
import { ListUserRequestsService } from "../data/services/listUserRequestsService";
import { RequestPaymentRequestReviewService } from "../data/services/requestPaymentRequestReviewService";
import { SavePaymentRequestPanoramaService } from "../data/services/savePaymentRequestPanoramaService";
import { StorePaymentRequestPanoramaIdService } from "../data/services/storePaymentRequestPanoramaIdService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { ICancelPaymentRequestContract } from "../domain/contracts/cancelPaymentRequestContract";
import { IDeletePaymentRequestPanoramaContract } from "../domain/contracts/deletePaymentRequestPanoramaContract";
import { IExportPaymentRequestContract } from "../domain/contracts/exportPaymentRequestContract";
import { IGenerateReportByCostCenterContract } from "../domain/contracts/generateReportByCostCenterContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IGetStoredPaymentRequestPanoramaIdContract } from "../domain/contracts/getStoredPaymentRequestPanoramaIdContract";
import { IListPaymentRequestPanoramasContract } from "../domain/contracts/listPaymentRequestPanoramasContract";
import { IListPaymentRequestsContract } from "../domain/contracts/listPaymentRequestsContract";
import { IListRequesterAttachmentTypesContract } from "../domain/contracts/listRequesterAttachmentTypes";
import { IListUserRequestsContract } from "../domain/contracts/listUserRequestsContract";
import { IRequestPaymentRequestReviewContract } from "../domain/contracts/requestPaymentRequestReviewContract";
import { ISavePaymentRequestPanoramaContract } from "../domain/contracts/savePaymentRequestPanoramaContract";
import { IStorePaymentRequestPanoramaIdContract } from "../domain/contracts/storePaymentRequestPanoamaIdContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";

export type MakePaymentRequests = IDeletePaymentRequestPanoramaContract &
  IGetStoredPaymentRequestPanoramaIdContract &
  IListPaymentRequestPanoramasContract &
  ISavePaymentRequestPanoramaContract &
  IStorePaymentRequestPanoramaIdContract &
  IListUserRequestsContract &
  ICancelPaymentRequestContract &
  IRequestPaymentRequestReviewContract &
  IListPaymentRequestsContract &
  IGenerateReportByCostCenterContract &
  IListAttachmentTypesContract &
  IGetStorageFilebyIdContract &
  IUpdateAttachmentsBarcodeContract &
  IUploadAttachmentsContract &
  IGetAttachmentsContract &
  IListRequesterAttachmentTypesContract &
  ISearchClassificationAccountContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract &
  ISearchProviderContract &
  IValidateMeasurementAttachmentContract &
  IExportPaymentRequestContract;

export function makePaymentRequests(
  api: IApiService,
  authApi: IApiService,
  barcodeApi: IApiService,
): MakePaymentRequests {
  const decryptService = new DecryptService();
  const parsePanoramaAngularSvc = new ParsePanoramaAngularService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const transformPanoramaAngularSvc = new TransformPanoramaAngularService();

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listPaymentRequestPanoramasService =
    new ListPaymentRequestPanoramasService(
      parsePanoramaAngularSvc,
      getUserLocalService,
      api,
    );

  const getStoredPaymentRequestPanoramaIdService =
    new GetStoredPaymentRequestPanoramaIdService();

  const savePaymentRequestPanoramaService =
    new SavePaymentRequestPanoramaService(
      transformPanoramaAngularSvc,
      getUserLocalService,
      api,
    );

  const storePaymentRequestPanoramaIdService =
    new StorePaymentRequestPanoramaIdService();

  const deletePaymentRequestPanoramaService =
    new DeletePaymentRequestPanoramaService(getUserLocalService, api);

  const listPaymentRequestsService = new ListPaymentRequestsService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const listUserRequestsService = new ListUserRequestsService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const cancelPaymentRequestService = new CancelPaymentRequestService(
    getUserLocalService,
    api,
  );

  const requestPaymentRequestReviewService =
    new RequestPaymentRequestReviewService(getUserLocalService, api);

  const generateReportsByCostCenterService =
    new GenerateReportByCostCenterService(getUserLocalService, api);

  const listAttachmentTypesService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const updateAttachmentsBarcodeService = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    barcodeApi,
  );

  const uploadAttachmentsService = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const getAttachmentsService = new GetAttachmentsService(
    getUserLocalService,
    api,
  );

  const listRequesterAttachmentTypesService =
    new ListRequesterAttachmentTypesService(getUserLocalService, api);

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  const searchProviderService = new SearchProviderService(
    getUserLocalService,
    api,
  );

  const validateMeasurementAttachmentService =
    new ValidateMeasurementAttachmentService(getUserLocalService, api);
  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const exportPaymentRequestService = new ExportPaymentRequestService(
    advGeneratePayloadExportUseCase,
    setServerSideService,
    getUserLocalService,
    api,
  );

  return {
    listPaymentRequestPanoramas(userId, columns) {
      return listPaymentRequestPanoramasService.listPaymentRequestPanoramas(
        userId,
        columns,
      );
    },
    getStoredPaymentRequestPanoramaId() {
      return getStoredPaymentRequestPanoramaIdService.getStoredPaymentRequestPanoramaId();
    },
    savePaymentRequestPanorama(columns, panorama) {
      return savePaymentRequestPanoramaService.savePaymentRequestPanorama(
        columns,
        panorama,
      );
    },
    storePaymentRequestPanoramaId(panoramaId) {
      storePaymentRequestPanoramaIdService.storePaymentRequestPanoramaId(
        panoramaId,
      );
    },
    deletePaymentRequestPanorama(panoramaId) {
      return deletePaymentRequestPanoramaService.deletePaymentRequestPanorama(
        panoramaId,
      );
    },
    listPaymentRequests(companyGroupId, payload) {
      return listPaymentRequestsService.listPaymentRequests(
        companyGroupId,
        payload,
      );
    },
    listUserRequests(companyGroupId, payload) {
      return listUserRequestsService.listUserRequests(companyGroupId, payload);
    },
    cancelPaymentRequest(paymentRequestId, deleteReason) {
      return cancelPaymentRequestService.cancelPaymentRequest(
        paymentRequestId,
        deleteReason,
      );
    },
    requestPaymentRequestReview(paymentRequestId) {
      return requestPaymentRequestReviewService.requestPaymentRequestReview(
        paymentRequestId,
      );
    },
    generateReportByCostCenter(params) {
      return generateReportsByCostCenterService.generateReportByCostCenter(
        params,
      );
    },
    listAttachmentTypes() {
      return listAttachmentTypesService.listAttachmentTypes();
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    updateAttachmentsBarcode(companyGroupId, attachmentFormInput) {
      return updateAttachmentsBarcodeService.updateAttachmentsBarcode(
        companyGroupId,
        attachmentFormInput,
      );
    },
    uploadAttachments(params) {
      return uploadAttachmentsService.uploadAttachments(params);
    },
    getAttachments(paymentRequestId) {
      return getAttachmentsService.getAttachments(paymentRequestId);
    },
    listRequesterAttachmentTypes() {
      return listRequesterAttachmentTypesService.listRequesterAttachmentTypes();
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchProvider(params) {
      return searchProviderService.searchProvider(params);
    },
    validateMeasurementAttachment(value, measurementAttachment) {
      return validateMeasurementAttachmentService.validateMeasurementAttachment(
        value,
        measurementAttachment,
      );
    },
    exportPaymentRequest(
      currentCompanyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColNames,
    ) {
      return exportPaymentRequestService.exportPaymentRequest(
        currentCompanyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColNames,
      );
    },
  };
}
