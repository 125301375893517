import { ColumnProps } from "primereact/column";
import { FormEvent, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { IRelationshipFilterOption } from "../../../../../advTable/domain/entities/advTableColumn";
import { InvalidFeedback } from "../../../../../core/presentation/components/InvalidFeedback";
import { useSoulDialog } from "../../../../../core/presentation/hooks/useSoulDialog";
import {
  ICostCenterLinkEntity,
  IReturnAccountErrorEntity,
  IReturnAccountReceivableFormEntity,
} from "../../domain/entities/returnAccountReceivableFormEntity";
import { InputSearchCostCenter } from "../components/InputSearchCostCenter";
import {
  EReturnFormModalType,
  useAccountsReceivablePage,
} from "./useAccountsReceivablePage";
import { useDateValidator } from "../../../../../core/presentation/hooks/useDateValidator";

export function useAccountReceivableReturnFormModal() {
  const {
    state,
    reload,
    useAccountsReceivable,
    handleAccountReceivableReturnFormModalClose,
  } = useAccountsReceivablePage();

  const {
    contextMenuData,
    returnFormModalType,
    accountReceivableReturnFormModalOpen,
  } = state;

  const companyName = contextMenuData?.companyName;
  const hasProject = !!contextMenuData?.projectName;
  const launchValue = contextMenuData?.launchValue || 0;
  const accountReceivableParcelId = contextMenuData?.id;
  const isPartialReturn = returnFormModalType === EReturnFormModalType.Partial;

  const {
    searchCostCenter,
    checkReturnInvoiceTaxes,
    returnTotalAccountReceivable,
    returnPartialAccountReceivable,
    returnAccountReceivableInvoiceTaxes,
  } = useAccountsReceivable;

  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();

  const returnForm = useForm<IReturnAccountReceivableFormEntity>({
    mode: "all",
    defaultValues: {
      returnDate: "",
      returnValue: 0,
      taxReturnDate: "",
      costCenterLinks: null,
    },
  });

  const {
    reset,
    control,
    trigger,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { isValid },
  } = returnForm;

  const handleModalTexts = () => {
    const textType = isPartialReturn ? "parcial" : "total";
    const headerMessage = isPartialReturn
      ? "parcial do lançamento com data e valor informados abaixo"
      : "do valor total do lançamento na data selecionada abaixo";

    return {
      title: `Devolução ${textType} de Conta a Receber`,
      headerText: `
        Você realmente deseja fazer a devolução? Todas as parcelas desse
        lançamento já estão ou serão baixadas automaticamente caso você
        prossiga. Isso fará com que seja criado um novo registro nos
        relatórios indicando a devolução ${headerMessage}.
      `,
    };
  };

  const handleAfterOpen = () => {
    setValue("returnValue", isPartialReturn ? 0 : launchValue);
    setValue("accountReceivableId", contextMenuData?.accountReceivableId || "");
  };

  const handleClose = () => {
    handleAccountReceivableReturnFormModalClose();
  };

  const handleAfterClose = () => {
    reset();
  };

  const handleAccountReturnErrors = async (
    returnError: IReturnAccountErrorEntity,
  ) => {
    const {
      costCenterLinks,
      companyLockUntil,
      returnDateIsValid,
      taxReturnDateIsValid,
      companyClosedForReleases,
    } = returnError;

    if (companyClosedForReleases || costCenterLinks) {
      await dialog.fire({
        icon: "error",
        title: "Erro!",
        html: (
          <>
            {companyClosedForReleases ? (
              <div>Empresa fechada para lançamentos.</div>
            ) : null}
            {costCenterLinks ? (
              <div>
                <div key={costCenterLinks.costCenterDisabled.id}>
                  O centro de custo {costCenterLinks.costCenterDisabled.name}{" "}
                  está inativo.
                </div>
              </div>
            ) : null}
          </>
        ),
      });
    }

    const returnDateErrors = new Map();
    const taxReturnDateErrors = new Map();
    const costCenterLinksFieldIsEmpty = getValues("costCenterLinks") === null;
    const companyClosedErrorMessage = `Os lançamentos na empresa ${companyName} estão travados até ${companyLockUntil}, assim essa devolução só pode ser realizada após essa data.`;

    if (companyClosedForReleases) {
      returnDateErrors.set("companyCanRelease", companyClosedErrorMessage);

      if (hasProject) {
        taxReturnDateErrors.set("companyCanRelease", companyClosedErrorMessage);
      }
    }

    if (costCenterLinks && costCenterLinksFieldIsEmpty) {
      setValue("costCenterLinks", costCenterLinks);
      trigger("costCenterLinks.costCenterDestination");
    }

    if (!returnDateIsValid) {
      const dateErrorMessage = `A data da devolução deve ser maior ou igual que a data de baixa.`;
      returnDateErrors.set("isAfterOrEqualTerminationDate", dateErrorMessage);
    }

    if (returnDateErrors.size) {
      const types = Object.fromEntries(returnDateErrors);
      setError("returnDate", { types });
    }

    if (!taxReturnDateIsValid) {
      const dateErrorMessage = `A data da devolução de taxa deve ser maior ou igual que a data de baixa.`;
      taxReturnDateErrors.set(
        "isAfterOrEqualTerminationDate",
        dateErrorMessage,
      );
    }

    if (taxReturnDateErrors.size) {
      const types = Object.fromEntries(taxReturnDateErrors);
      setError("taxReturnDate", { types });
    }
  };

  const handleReturnInvoiceTaxes = async (
    formValues: IReturnAccountReceivableFormEntity,
  ) => {
    if (!formValues?.accountReceivableId) {
      return;
    }

    const result = await dialog.fire({
      icon: "question",
      title: "Atenção!",
      showCancelButton: true,
      cancelButtonText: "Não, mantê-las",
      confirmButtonText: "Sim, devolvê-las",
      html: (
        <div>
          Detectamos a existência de taxas geradas na emissão da NF para esse
          lançamento. Deseja devolvê-las também?
        </div>
      ),
    });

    if (result.isConfirmed) {
      await returnAccountReceivableInvoiceTaxes(formValues);
    }
  };

  const shouldChangeReturnTypeToTotalDialog = () => {
    return dialog.fire({
      icon: "question",
      title: "Atenção!",
      showCancelButton: true,
      cancelButtonText: "Não",
      html: (
        <>
          <div>
            Você informou o <strong>valor total</strong> em uma{" "}
            <strong>devolução parcial</strong>. Portanto, a devolução será{" "}
            <strong>
              automaticamente <strong>convertida</strong> para uma devolução
              total
            </strong>
          </div>
          <br />
          <div>Deseja prosseguir?</div>
        </>
      ),
    });
  };

  const onValid = async (formValues: IReturnAccountReceivableFormEntity) => {
    if (!formValues?.accountReceivableId) {
      return;
    }

    if (!accountReceivableParcelId) {
      return;
    }

    const { returnValue, accountReceivableId } = formValues;
    const isReturnValueEqualsLaunchValue = returnValue === launchValue;

    /**
     * A duplicação do tipo de devolução é feita para que o usuário
     * possa alterá-lo sem gerar impacto no valor definido previamente.
     */
    let isReturnTypePartial = isPartialReturn;

    if (isPartialReturn && isReturnValueEqualsLaunchValue) {
      const result = await shouldChangeReturnTypeToTotalDialog();

      if (result.dismiss) {
        return;
      }

      isReturnTypePartial = false;
    }

    try {
      const { hasInvoiceTaxes } = await checkReturnInvoiceTaxes(
        accountReceivableId,
      );

      if (isReturnTypePartial) {
        await returnPartialAccountReceivable(formValues);
      } else {
        await returnTotalAccountReceivable(formValues);
      }

      if (hasInvoiceTaxes) {
        await handleReturnInvoiceTaxes(formValues);
      }

      const dialogMessage = isReturnTypePartial ? "parcial" : "total";

      await dialog.fire({
        icon: "success",
        title: "Feito!",
        html: (
          <>
            Devolução <strong>{dialogMessage}</strong> efetuada com sucesso!
          </>
        ),
      });

      reload();
      handleAccountReceivableReturnFormModalClose();
    } catch (error) {
      const returnError = error as IReturnAccountErrorEntity;

      if (returnError.hasFormErrors) {
        handleAccountReturnErrors(returnError);
        return;
      }

      dialog.fire({
        icon: "error",
        title: "Erro!",
        text: returnError.message,
      });
    }
  };

  const handleFormSubmission = (event: FormEvent) => {
    event.preventDefault();

    if (!isValid) {
      return;
    }

    handleSubmit(onValid)(event);
  };

  const columns = useMemo<ColumnProps[]>(() => {
    const renderCostCenterDisabledNameBody = (
      rowData: ICostCenterLinkEntity,
    ) => {
      return (
        <span className="text-truncate" title={rowData.costCenterDisabled.name}>
          {rowData.costCenterDisabled.name}
        </span>
      );
    };

    const renderCostCenterDestinationBody = () => {
      return (
        <Controller
          control={control}
          name="costCenterLinks.costCenterDestination"
          rules={{
            validate: {
              required: v => !!v?.id && !!v?.name,
            },
          }}
          render={({ field, fieldState }) => {
            const handleOnChange = (value: IRelationshipFilterOption) => {
              const finalValue = value
                ? { name: value.label, id: value.rawValue }
                : null;

              field.onChange(finalValue);
            };

            return (
              <label>
                <InputSearchCostCenter
                  onChange={handleOnChange}
                  searchFn={searchCostCenter}
                  className={fieldState?.error ? "isInvalid" : undefined}
                />
                <InvalidFeedback
                  message="Este campo é obrigatório"
                  condition={fieldState?.error?.type === "required"}
                />
              </label>
            );
          }}
        />
      );
    };

    return [
      {
        field: "costCenterDisabled.name",
        header: "Centro de Custo inativo",
        style: { maxWidth: "160px" },
        body: renderCostCenterDisabledNameBody,
      },
      {
        field: "costCenterDestination",
        header: "Centro de Custo destino",
        body: renderCostCenterDestinationBody,
      },
    ];
  }, [control, searchCostCenter]);

  return {
    onValid,
    columns,
    hasProject,
    returnForm,
    handleClose,
    launchValue,
    dateValidator,
    isPartialReturn,
    handleAfterOpen,
    handleModalTexts,
    handleAfterClose,
    handleFormSubmission,
    accountReceivableReturnFormModalOpen,
  };
}
