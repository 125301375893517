import { EDaysOfWeek } from "./daysOfWeekEnum";

export enum EGenerateTaxStatus {
  Empty = "",
  Terminated = 1,
  NfIssue = 2,
}

export interface ICompanyTaxEntity {
  id: string;
  active: boolean;

  tax: number;
  name: string;
  isUsp: boolean;
  endDate: string;
  startDate: string;
  generateTaxDescription: string;
  generateTax: EGenerateTaxStatus;

  companyId: string;
  providerId: string;
  paymentAccountId: string;
  classificationUspId: string;
  classificationAccountId: string;
  classificationAssessmentId: string;

  fuspTax: {
    chargeWeekDay: EDaysOfWeek;
    initialWeekDay: EDaysOfWeek;
  } | null;

  dateCreated?: string;
  dateCreatedString?: string;

  userCreated?: string;
  userNameCreated?: string;

  dateModified?: string;
  dateModifiedString?: string;

  userModified?: string;
  userNameModified?: string;
}

export interface ICompanyTaxFormattedEntity
  extends Omit<ICompanyTaxEntity, "tax" | "isUsp" | "generateTax"> {
  tax: string;
  isUsp: string;
  generateTax: string;
}
