import { useCallback, useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { Toggle } from "../../../core/presentation/components/Toggle";
import { useSoulDialog } from "../../../core/presentation/hooks/useSoulDialog";
import {
  ISimpleHiddenColumn,
  ISimpleSortableColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../simpleTable/domain/entities/simpleColumnEntity";
import { IClassificationAssessmentEntity } from "../../domain/entities/classificationAssessment";

interface UseClassificationAssessmentGridParams {
  openEditModal?(id: string): void;
  toggle?(id: string): Promise<void> | void;
}

export function useClassificationAssessmentGrid({
  openEditModal,
  toggle,
}: UseClassificationAssessmentGridParams) {
  const editBodyTemplate = useCallback(
    ({ id }: IClassificationAssessmentEntity, { rowIndex }) => {
      return (
        <div className="button-wrapper">
          <button
            data-tip="Editar Classificação de Rateio"
            type="button"
            className="outline-button"
            id={`btn-edit-${rowIndex}`}
            data-testid={`btn-edit-${rowIndex}`}
            onClick={() => {
              openEditModal?.(id);
            }}
          >
            <FaEdit />
          </button>
        </div>
      );
    },
    [openEditModal],
  );

  const dialog = useSoulDialog();

  const activeBodyTemplate = useCallback(
    ({ id, active }: IClassificationAssessmentEntity, { rowIndex }) => {
      return (
        <div>
          <Toggle
            value={active}
            inactiveClassName="toggle-muted"
            id={`btn-tgl-status-${rowIndex}`}
            data-testid={`btn-tgl-status-${rowIndex}`}
            onChange={async _active => {
              const text = !_active ? "inativada" : "ativada";
              const result = await dialog.fire({
                icon: "question",
                title: "Você está certo disso?",
                html: `A Classificação de Rateio será ${text}. <br /> Deseja prosseguir?`,
                showCancelButton: true,
                cancelButtonText: "Não",
                async preConfirm() {
                  dialog.update({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  });
                  dialog.showLoading();

                  return toggle?.(id);
                },
              });

              if (result.isConfirmed) {
                return _active;
              }

              return !_active;
            }}
          />
        </div>
      );
    },
    [dialog, toggle],
  );

  const columns = useMemo<SimpleTableColumn[]>(() => {
    return [
      {
        field: "companyGroupName",
        header: "Grupo de empresa",
        width: "20%",
        searchable: true,
        orderable: true,
      },
      {
        field: "name",
        header: "Nome",
        searchable: true,
        orderable: true,
      },
      {
        field: "typeDescription",
        header: "Tipo",
        searchable: true,
        orderable: true,
        sortField: "type",
      } as ISimpleSortableColumn,
      // NOTE por algum motivo a coluna pela qual uma coluna string/description
      // ordena deve ficar juntdo a coluna string/description. Precisamos dar
      // uma olhada no generatePayload e compreender qual a razão disso e melhorar
      {
        field: "type",
        hidden: true,
      } as ISimpleHiddenColumn,
      {
        header: "",
        bodyTemplate: editBodyTemplate,
      },
      {
        field: "active",
        header: "Ativo",
        bodyTemplate: activeBodyTemplate,
      },
      {
        field: "paymentTypeDescription",
        hidden: true,
        searchable: false,
        orderable: false,
      },
      {
        field: "activeDescription",
        searchable: false,
        orderable: false,
        hidden: true,
      },
    ].map(colParams => new SimpleColumn(colParams));
  }, [activeBodyTemplate, editBodyTemplate]);

  return {
    columns,
  };
}
