import { IEnum } from "../../../core/domain/entities/enum";

export enum EDaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const daysOfWeek: IEnum[] = [
  {
    key: EDaysOfWeek.Sunday,
    value: "Domingo",
  },
  {
    key: EDaysOfWeek.Monday,
    value: "Segunda-Feira",
  },
  {
    key: EDaysOfWeek.Tuesday,
    value: "Terça-Feira",
  },
  {
    key: EDaysOfWeek.Wednesday,
    value: "Quarta-Feira",
  },
  {
    key: EDaysOfWeek.Thursday,
    value: "Quinta-Feira",
  },
  {
    key: EDaysOfWeek.Friday,
    value: "Sexta-Feira",
  },
  {
    key: EDaysOfWeek.Saturday,
    value: "Sábado",
  },
];
