import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SearchBrazilianCityService } from "../../core/data/services/searchBrazilianCityService";
import { SearchBrazilianStateService } from "../../core/data/services/searchBrazilianStateService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { ISearchBrazilianCityContract } from "../../core/domain/contracts/searchBrazilianCityContract";
import { ISearchBrazilianStateContract } from "../../core/domain/contracts/searchBrazilianStateContract";
import { ChangeFilteredCompaniesLockDatesService } from "../data/services/changeFilteredCompaniesLockDatesService";
import { ChangeSelectedCompanyLockDatesService } from "../data/services/changeSelectedCompaniesLockDatesService";
import { CheckCnpjService } from "../data/services/checkCnpjService";
import { ExportCompaniesService } from "../data/services/exportCompaniesService";
import { GetClassificationAccountService } from "../data/services/getClassificationAccountService";
import { GetClassificationAssessmentService } from "../data/services/getClassificationAssessmentService";
import { GetClassificationUspService } from "../data/services/getClassificationUspService";
import { GetCompanyService } from "../data/services/getCompanyService";
import { GetCompanyTaxesService } from "../data/services/getCompanyTaxesService";
import { GetPaymentAccountService } from "../data/services/getPaymentAccountService";
import { GetProviderService } from "../data/services/getProviderService";
import { ListCnaeService } from "../data/services/listCnaeService";
import { ListCompaniesService } from "../data/services/listCompaniesService";
import { ListMunicipalActivityService } from "../data/services/listMunicipalActivityService";
import { SaveCompanyService } from "../data/services/saveCompanyService";
import { SaveCompanyTaxesService } from "../data/services/saveCompanyTaxesService";
import { SearchClassificationAccountsService } from "../data/services/searchClassificationAccountsService";
import { SearchClassificationAsssesmentsService } from "../data/services/searchClassificationAssessmentsService";
import { SearchClassificationUspService } from "../data/services/searchClassificationUspService";
import { SearchPaymentAccountsService } from "../data/services/searchPaymentAccountsService";
import { SearchProvidersService } from "../data/services/searchProvidersService";
import { ToggleCompanyService } from "../data/services/toggleCompanyService";
import { UpdateCompanyInvoiceParametersService } from "../data/services/updateCompanyInvoiceParametersService";
import { UpdateCompanyService } from "../data/services/updateCompanyService";
import { IChangeFilteredCompaniesLockDatesContract } from "../domain/contracts/changeFilteredCompaniesLockDatesContract";
import { IChangeSelectedCompaniesLockDatesContract } from "../domain/contracts/changeSelectedCompaniesLockDatesContract";
import { IExportCompaniesContract } from "../domain/contracts/exportCompaniesContract";
import { IGetCompanyContract } from "../domain/contracts/getCompanyContract";
import { IListCnaeContract } from "../domain/contracts/listCnaeContract";
import { IListMunicipalActivityContract } from "../domain/contracts/listMunicipalActivityContract";
import { ISaveCompanyContract } from "../domain/contracts/saveCompanyContract";
import { IUpdateCompanyContract } from "../domain/contracts/updateCompanyContract";
import { IUpdateCompanyInvoiceParametersContract } from "../domain/contracts/updateCompanyInvoiceParametersContract";
import {
  CheckCnpjUseCase,
  ICheckCnpjUseCase,
} from "../domain/usecases/checkCnpjUseCase";
import {
  GetClassificationAccountUseCase,
  IGetClassificationAccountUseCase,
} from "../domain/usecases/getClassificationAccountUseCase";
import {
  GetClassificationAssessmentUseCase,
  IGetClassificationAssessmentUseCase,
} from "../domain/usecases/getClassificationAssessmentUseCase";
import {
  GetClassificationUspUseCase,
  IGetClassificationUspUseCase,
} from "../domain/usecases/getClassificationUspUseCase";
import {
  GetCompanyTaxesUseCase,
  IGetCompanyTaxesUseCase,
} from "../domain/usecases/getCompanyTaxesUseCase";
import {
  GetPaymentAccountUseCase,
  IGetPaymentAccountUseCase,
} from "../domain/usecases/getPaymentAccountUseCase";
import {
  GetProviderUseCase,
  IGetProviderUseCase,
} from "../domain/usecases/getProviderUseCase";
import {
  IListCompaniesUseCase,
  ListCompaniesUseCase,
} from "../domain/usecases/listCompaniesUseCase";
import {
  ISaveCompanyTaxesUseCase,
  SaveCompanyTaxesUseCase,
} from "../domain/usecases/saveCompanyTaxesUseCase";
import {
  ISearchClassificationAccountsUseCase,
  SearchClassificationAccountsUseCase,
} from "../domain/usecases/searchClassificationAccountsUseCase";
import {
  ISearchClassificationAssessmentsUseCase,
  SearchClassificationAssessmentsUseCase,
} from "../domain/usecases/searchClassificationAssessmentsUseCase";
import {
  ISearchClassificationUspUseCase,
  SearchClassificationUspUseCase,
} from "../domain/usecases/searchClassificationUspUseCase";
import {
  ISearchPaymentAccountsUseCase,
  SearchPaymentAccountsUseCase,
} from "../domain/usecases/searchPaymentAccountsUseCase";
import {
  ISearchProvidersUseCase,
  SearchProvidersUseCase,
} from "../domain/usecases/searchProvidersUseCase";
import {
  IToggleCompanyUseCase,
  ToggleCompanyUseCase,
} from "../domain/usecases/toggleCompanyUseCase";

export type MakeCompany = IListCompaniesUseCase &
  IToggleCompanyUseCase &
  ICheckCnpjUseCase &
  IGetCompanyTaxesUseCase &
  ISaveCompanyTaxesUseCase &
  ISearchProvidersUseCase &
  ISearchClassificationAssessmentsUseCase &
  ISearchClassificationAccountsUseCase &
  ISearchClassificationUspUseCase &
  ISearchPaymentAccountsUseCase &
  IGetPaymentAccountUseCase &
  IGetClassificationAccountUseCase &
  IGetClassificationAssessmentUseCase &
  IGetClassificationUspUseCase &
  IGetProviderUseCase &
  IChangeSelectedCompaniesLockDatesContract &
  IChangeFilteredCompaniesLockDatesContract &
  IListCnaeContract &
  IListMunicipalActivityContract &
  IGetCompanyContract &
  ISaveCompanyContract &
  IUpdateCompanyContract &
  ISearchBrazilianStateContract &
  ISearchBrazilianCityContract &
  IUpdateCompanyInvoiceParametersContract &
  IExportCompaniesContract;

export function makeCompany(api: IApiService): MakeCompany {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listCompaniesService = new ListCompaniesService(
    getUserLocalService,
    api,
  );
  const listCompaniesUseCase = new ListCompaniesUseCase(listCompaniesService);

  const toggleCompanyService = new ToggleCompanyService(
    getUserLocalService,
    api,
  );
  const toggleCompanyUseCase = new ToggleCompanyUseCase(toggleCompanyService);

  const checkCnpjService = new CheckCnpjService(getUserLocalService, api);
  const checkCnpjUseCase = new CheckCnpjUseCase(checkCnpjService);

  const getCompanyTaxesService = new GetCompanyTaxesService(
    getUserLocalService,
    api,
  );
  const getCompanyTaxesUseCase = new GetCompanyTaxesUseCase(
    getCompanyTaxesService,
  );

  const saveCompanyTaxesService = new SaveCompanyTaxesService(
    getUserLocalService,
    api,
  );
  const saveCompanyTaxesUseCase = new SaveCompanyTaxesUseCase(
    saveCompanyTaxesService,
  );

  const searchProvidersService = new SearchProvidersService(
    getUserLocalService,
    api,
  );
  const searchProvidersUseCase = new SearchProvidersUseCase(
    searchProvidersService,
  );

  const searchClassificationAccountService =
    new SearchClassificationAccountsService(getUserLocalService, api);
  const searchClassificationAccountUseCase =
    new SearchClassificationAccountsUseCase(searchClassificationAccountService);

  const searchPaymentAccountsService = new SearchPaymentAccountsService(
    getUserLocalService,
    api,
  );
  const searchPaymentAccountsUseCase = new SearchPaymentAccountsUseCase(
    searchPaymentAccountsService,
  );

  const searchClassificationAssessmentsService =
    new SearchClassificationAsssesmentsService(getUserLocalService, api);
  const searchClassificationAssessmentsUseCase =
    new SearchClassificationAssessmentsUseCase(
      searchClassificationAssessmentsService,
    );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );
  const searchClassificationUspUseCase = new SearchClassificationUspUseCase(
    searchClassificationUspService,
  );

  const getProviderService = new GetProviderService(getUserLocalService, api);
  const getProviderUseCase = new GetProviderUseCase(getProviderService);

  const getClassificationAccountService = new GetClassificationAccountService(
    getUserLocalService,
    api,
  );
  const getClassificationAccountUseCase = new GetClassificationAccountUseCase(
    getClassificationAccountService,
  );

  const getClassificationAssessmentService =
    new GetClassificationAssessmentService(getUserLocalService, api);
  const getClassificationAssessmentUseCase =
    new GetClassificationAssessmentUseCase(getClassificationAssessmentService);

  const getClassificationUspService = new GetClassificationUspService(
    getUserLocalService,
    api,
  );
  const getClassificationUspUseCase = new GetClassificationUspUseCase(
    getClassificationUspService,
  );

  const getPaymentAccountService = new GetPaymentAccountService(
    getUserLocalService,
    api,
  );
  const getPaymentAccountUseCase = new GetPaymentAccountUseCase(
    getPaymentAccountService,
  );

  const changeSelectedCompaniesLockDatesService =
    new ChangeSelectedCompanyLockDatesService(getUserLocalService, api);

  const changeFilteredCompaniesLockDatesService =
    new ChangeFilteredCompaniesLockDatesService(getUserLocalService, api);

  const listCnaeService = new ListCnaeService(getUserLocalService, api);

  const listMunicipalActivityService = new ListMunicipalActivityService(
    getUserLocalService,
    api,
  );

  const getCompanyService = new GetCompanyService(getUserLocalService, api);

  const saveCompanyService = new SaveCompanyService(getUserLocalService, api);

  const updateCompanyService = new UpdateCompanyService(
    getUserLocalService,
    api,
  );

  const searchBrazilianStateService = new SearchBrazilianStateService(
    getUserLocalService,
    api,
  );

  const searchBrazilianCityService = new SearchBrazilianCityService(
    getUserLocalService,
    api,
  );

  const updateCompanyInvoiceParametersService =
    new UpdateCompanyInvoiceParametersService(getUserLocalService, api);

  const exportCompaniesService = new ExportCompaniesService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  return {
    listCompanies(payload, companyGroupId, actives) {
      return listCompaniesUseCase.listCompanies(
        payload,
        companyGroupId,
        actives,
      );
    },
    toggleCompany(companyId) {
      return toggleCompanyUseCase.toggleCompany(companyId);
    },
    checkCnpj(cnpj, id) {
      return checkCnpjUseCase.checkCnpj(cnpj, id);
    },
    getCompanyTaxes(companyId) {
      return getCompanyTaxesUseCase.getCompanyTaxes(companyId);
    },
    saveCompanyTaxes(companyId, payload) {
      return saveCompanyTaxesUseCase.saveCompanyTaxes(companyId, payload);
    },
    searchProviders(search, companyGroupId, active) {
      return searchProvidersUseCase.searchProviders(
        search,
        companyGroupId,
        active,
      );
    },
    searchClassificationAssessments(search, companyGroupId) {
      return searchClassificationAssessmentsUseCase.searchClassificationAssessments(
        search,
        companyGroupId,
      );
    },
    searchClassificationAccounts(providerId, companyGroupId, active) {
      return searchClassificationAccountUseCase.searchClassificationAccounts(
        providerId,
        companyGroupId,
        active,
      );
    },
    searchClassificationUsp(search, companyGroupId, active) {
      return searchClassificationUspUseCase.searchClassificationUsp(
        search,
        companyGroupId,
        active,
      );
    },
    searchPaymentAccounts(search, companyGroupId, companyId, active) {
      return searchPaymentAccountsUseCase.searchPaymentAccounts(
        search,
        companyGroupId,
        companyId,
        active,
      );
    },
    getClassificationAccount(id) {
      return getClassificationAccountUseCase.getClassificationAccount(id);
    },
    getClassificationAssessment(id) {
      return getClassificationAssessmentUseCase.getClassificationAssessment(id);
    },
    getClassificationUsp(id) {
      return getClassificationUspUseCase.getClassificationUsp(id);
    },
    getPaymentAccount(id) {
      return getPaymentAccountUseCase.getPaymentAccount(id);
    },
    getProvider(id) {
      return getProviderUseCase.getProvider(id);
    },
    changeSelectedCompaniesLockDates(params) {
      return changeSelectedCompaniesLockDatesService.changeSelectedCompaniesLockDates(
        params,
      );
    },
    changeFilteredCompaniesLockDates(params) {
      return changeFilteredCompaniesLockDatesService.changeFilteredCompaniesLockDates(
        params,
      );
    },
    listCnae(actives) {
      return listCnaeService.listCnae(actives);
    },
    listMunicipalActivity(actives) {
      return listMunicipalActivityService.listMunicipalActivity(actives);
    },
    getCompany(companyId) {
      return getCompanyService.getCompany(companyId);
    },
    saveCompany(payload) {
      return saveCompanyService.saveCompany(payload);
    },
    updateCompany(payload) {
      return updateCompanyService.updateCompany(payload);
    },
    searchBrazilianState(params) {
      return searchBrazilianStateService.searchBrazilianState(params);
    },
    searchBrazilianCity(params) {
      return searchBrazilianCityService.searchBrazilianCity(params);
    },
    updateCompanyInvoiceParameters(invoiceParametersFormValues) {
      return updateCompanyInvoiceParametersService.updateCompanyInvoiceParameters(
        invoiceParametersFormValues,
      );
    },
    exportCompanies(params) {
      return exportCompaniesService.exportCompanies(params);
    },
  };
}
