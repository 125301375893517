import { ReactNode, useState } from "react";
import { Tooltip, TooltipProps } from "primereact/tooltip";

interface IconTooltipProps extends TooltipProps {
  icon: ReactNode;
  text: ReactNode;
  title?: ReactNode;
  className?: string;
}

export function IconTooltip(props: IconTooltipProps) {
  const {
    icon,
    text,
    title,
    position = "top",
    className = "default-icon-tooltip",
    ...rest
  } = props;

  const [targetRef, setTargetRef] = useState<HTMLSpanElement | null>(null);

  const targetRefCallback = (originalRef: HTMLSpanElement | null) => {
    setTargetRef(originalRef);
  };

  return (
    <>
      <span
        ref={targetRefCallback}
        style={{ display: "flex", alignItems: "center", cursor: "help" }}
      >
        {typeof icon === "string" ? <i className={icon} /> : icon}
      </span>
      <Tooltip
        position={position}
        className={className}
        target={targetRef || ""}
        {...rest}
      >
        {title ? <div className="default-title">{title}</div> : null}
        <div className="default-text">{text}</div>
      </Tooltip>
    </>
  );
}
