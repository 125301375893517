import { ParsePanoramaAngularService } from "../../../../advTable/data/services/parsePanoramaAngularService";
import { TransformPanoramaAngularService } from "../../../../advTable/data/services/transformPanoramaAngularService";
import { AdvGeneratePayloadExportUseCase } from "../../../../advTable/domain/usecases/advGeneratePayloadExportUseCase";
import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { FetchRpaParamOptionsService } from "../../../../core/data/services/fetchRpaParamOptionsService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { ListAttachmentTypesService } from "../../../../core/data/services/listAttachmentTypesService";
import { SearchBrazilianCityService } from "../../../../core/data/services/searchBrazilianCityService";
import { SearchBrazilianStateService } from "../../../../core/data/services/searchBrazilianStateService";
import { SearchClassificationAccountService } from "../../../../core/data/services/searchClassificationAccountService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchCustomerService } from "../../../../core/data/services/searchCustomerService";
import { SearchPaymentAccountService } from "../../../../core/data/services/searchPaymentAccountService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SearchUserService } from "../../../../core/data/services/searchUserService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchBrazilianCityContract } from "../../../../core/domain/contracts/searchBrazilianCityContract";
import { ISearchBrazilianStateContract } from "../../../../core/domain/contracts/searchBrazilianStateContract";
import { ISearchClassificationAccountContract } from "../../../../core/domain/contracts/searchClassificationAccountContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchCustomerContract } from "../../../../core/domain/contracts/searchCustomerContract";
import { ISearchPaymentAccountContract } from "../../../../core/domain/contracts/searchPaymentAccountContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { ISearchUserContract } from "../../../../core/domain/contracts/searchUserContract";
import { AccountReceivableHasTaxesService } from "../data/services/accountReceivableHasTaxesContract";
import { BulkTerminateAccountService } from "../data/services/bulkTerminateAccountsService";
import { CancelAccountReceivableService } from "../data/services/cancelAccountReceivableService";
import { CheckReturnInvoiceTaxesService } from "../data/services/checkReturnInvoiceTaxesService";
import { DeleteAccountsReceivablePanoramaService } from "../data/services/deleteAccountsReceivablePanoramaService";
import { ExportAccountsReceivableService } from "../data/services/exportAccountsReceivableService";
import { FetchCnaeService } from "../data/services/fetchCnaeService";
import { FetchServiceFrameworkService } from "../data/services/fetchServiceFrameworkService";
import { GenerateAccountReceivableInvoicesService } from "../data/services/generateAccountReceivableInvoicesService";
import { GetAccountReceivableImportFileTemplateService } from "../data/services/getAccountReceivableImportFileTemplateService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { GetStoredAccountsReceivablePanoramaIdService } from "../data/services/getStoredAccountsReceivablePanoramaIdService";
import { ImportAccountsReceivableService } from "../data/services/importAccountsReceivableService";
import { ListAccountsReceivablePanoramasService } from "../data/services/listAccountsReceivablePanoramasService";
import { ListAccountsReceivableService } from "../data/services/listAccountsReceivableService";
import { RemoveAccountTerminationService } from "../data/services/removeAccountTerminationService";
import { RemoveBulkTerminateAccountsService } from "../data/services/removeBulkTerminateAccountsService";
import { RemoveParcelTerminationService } from "../data/services/removeParcelTerminationService";
import { ReturnAccountReceivableInvoiceTaxesService } from "../data/services/returnAccountReceivableInvoiceTaxesService";
import { ReturnPartialAccountReceivableService } from "../data/services/returnPartialAccountReceivableService";
import { ReturnTotalAccountReceivableService } from "../data/services/returnTotalAccountReceivableService";
import { SaveAccountsReceivablePanoramaService } from "../data/services/saveAccountsReceivablePanoramaService";
import { StoreAccountReceivableIdToDuplicateService } from "../data/services/storeAccountReceivableIdToDuplicateService";
import { StoreAccountsReceivablePanoamaIdService } from "../data/services/storeAccountsReceivablePanoamaIdService";
import { TerminateAccountReceivableParcelService } from "../data/services/terminateAccountReceivableParcelService";
import { UndoReturnAccountReceivableService } from "../data/services/undoReturnAccountReceivableService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { IAccountReceivableHasTaxesContract } from "../domain/contracts/accountReceivableHasTaxesContract";
import { IBulkTerminateAccountsContract } from "../domain/contracts/bulkTerminateAccountsContract";
import { ICancelAccountReceivableContract } from "../domain/contracts/cancelAccountReceivableContract";
import { ICheckReturnInvoiceTaxesContract } from "../domain/contracts/checkReturnInvoiceTaxesContract";
import { IDeleteAccountsReceivablePanoramaContract } from "../domain/contracts/deleteAccountsReceivablePanoramaContract";
import { IExportAccountsReceivableContract } from "../domain/contracts/exportAccountsReceivableContract";
import { IFetchCnaeContract } from "../domain/contracts/fetchCnaeContract";
import { IFetchServiceFrameworkContract } from "../domain/contracts/fetchServiceFrameworkContract";
import { IGenerateAccountReceivableInvoicesContract } from "../domain/contracts/generateAccountReceivableInvoicesContract";
import { IGetAccountReceivableImportFileTemplateContract } from "../domain/contracts/getAccountReceivableImportFileTemplateContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IGetStoredAccountsReceivablePanoramaIdContract } from "../domain/contracts/getStoredAccountsReceivablePanoramaIdContract";
import { IImportAccountsReceivableContract } from "../domain/contracts/importAccountsReceivableContract";
import { IListAccountsReceivableContract } from "../domain/contracts/listAccountsReceivableContract";
import { IListAccountsReceivablePanoramasContract } from "../domain/contracts/listAccountsReceivablePanoramasContract";
import { IRemoveAccountTerminationContract } from "../domain/contracts/removeAccountTerminationContract";
import { IRemoveBulkTerminateAccountsContract } from "../domain/contracts/removeBulkTerminateAccountsContract";
import { IRemoveParcelTerminationContract } from "../domain/contracts/removeParcelTerminationContract";
import { IReturnAccountReceivableInvoiceTaxesContract } from "../domain/contracts/returnAccountReceivableInvoiceTaxesContract";
import { IReturnPartialAccountReceivableContract } from "../domain/contracts/returnPartialAccountReceivableContract";
import { IReturnTotalAccountReceivableContract } from "../domain/contracts/returnTotalAccountReceivableContract";
import { ISaveAccountsReceivablePanoramaContract } from "../domain/contracts/saveAccountsReceivablePanoramaContract";
import { IStoreAccountReceivableIdToDuplicateContract } from "../domain/contracts/storeAccountReceivableIdToDuplicateContract";
import { IStoreAccountsReceivablePanoamaIdContract } from "../domain/contracts/storeAccountsReceivablePanoamaIdContract";
import { ITerminateAccountReceivableParcelContract } from "../domain/contracts/terminateAccountReceivableParcelContract";
import { IUndoReturnAccountReceivableContract } from "../domain/contracts/undoReturnAccountReceivableContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";

export type MakeAccountsReceivable = IListAccountsReceivableContract &
  IGetStoredAccountsReceivablePanoramaIdContract &
  IListAccountsReceivablePanoramasContract &
  ISaveAccountsReceivablePanoramaContract &
  IDeleteAccountsReceivablePanoramaContract &
  IRemoveParcelTerminationContract &
  IRemoveAccountTerminationContract &
  ICancelAccountReceivableContract &
  IStoreAccountReceivableIdToDuplicateContract &
  IExportAccountsReceivableContract &
  IUndoReturnAccountReceivableContract &
  IReturnTotalAccountReceivableContract &
  IReturnPartialAccountReceivableContract &
  ICheckReturnInvoiceTaxesContract &
  IReturnAccountReceivableInvoiceTaxesContract &
  IGetAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListAttachmentTypesContract &
  IUploadAttachmentsContract &
  ITerminateAccountReceivableParcelContract &
  IBulkTerminateAccountsContract &
  IStoreAccountsReceivablePanoamaIdContract &
  IAccountReceivableHasTaxesContract &
  IGetAccountReceivableImportFileTemplateContract &
  IImportAccountsReceivableContract &
  IFetchServiceFrameworkContract &
  IFetchCnaeContract &
  IGenerateAccountReceivableInvoicesContract &
  IFetchRpaParamOptionsContract &
  ISearchClassificationAccountContract &
  ISearchClassificationUspContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchCustomerContract &
  ISearchPaymentAccountContract &
  ISearchProjectContract &
  ISearchUserContract &
  ISearchBrazilianStateContract &
  ISearchBrazilianCityContract &
  IRemoveBulkTerminateAccountsContract;

export function makeAccountsReceivable(
  api: IApiService,
  authApi: IApiService,
): MakeAccountsReceivable {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listAccountsReceivableSvc = new ListAccountsReceivableService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const storeAccountsReceivablePanoamaIdSvc =
    new StoreAccountsReceivablePanoamaIdService();

  const parsePanoramaAngularSvc = new ParsePanoramaAngularService();

  const listAccountsReceivablePanoramasSvc =
    new ListAccountsReceivablePanoramasService(
      parsePanoramaAngularSvc,
      getUserLocalService,
      api,
    );

  const getStoredAccountsReceivablePanoramaIdSvc =
    new GetStoredAccountsReceivablePanoramaIdService();

  const transformPanoramaAngularSvc = new TransformPanoramaAngularService();
  const saveAccountsReceivablePanoSvc =
    new SaveAccountsReceivablePanoramaService(
      transformPanoramaAngularSvc,
      getUserLocalService,
      api,
    );

  const removeTerminationSvc = new RemoveParcelTerminationService(
    getUserLocalService,
    api,
  );

  const removeAccountTerminationSvc = new RemoveAccountTerminationService(
    getUserLocalService,
    api,
  );

  const delAccountsReceivablePanoramaSvc =
    new DeleteAccountsReceivablePanoramaService(getUserLocalService, api);

  const cancelAccountReceivableSvc = new CancelAccountReceivableService(
    getUserLocalService,
    api,
  );

  const storeAccountReceivableIdToDuplicateSvc =
    new StoreAccountReceivableIdToDuplicateService();

  const advGeneratePayloadExportUseCase = new AdvGeneratePayloadExportUseCase();

  const exportAccountsReceivableSvc = new ExportAccountsReceivableService(
    advGeneratePayloadExportUseCase,
    setServerSideService,
    getUserLocalService,
    api,
  );

  const undoReturnAccountReceivableSvc = new UndoReturnAccountReceivableService(
    getUserLocalService,
    api,
  );

  const returnTotalAccountReceivableSvc =
    new ReturnTotalAccountReceivableService(getUserLocalService, api);

  const returnPartialAccountReceivableSvc =
    new ReturnPartialAccountReceivableService(getUserLocalService, api);

  const getAttachmentsSvc = new GetAttachmentsService(getUserLocalService, api);

  const getStorageFilebyIdSvc = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesSvc = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const uploadAttachmentSvc = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const terminateAccReceivableParcelSvc =
    new TerminateAccountReceivableParcelService(getUserLocalService, api);

  const bulkTerminateAccountSvc = new BulkTerminateAccountService(
    getUserLocalService,
    api,
  );

  const fetchRpaParamOptionsSvc = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const accountReceivableHasTaxesSvc = new AccountReceivableHasTaxesService(
    getUserLocalService,
    api,
  );

  const chkReturnInvoiceTaxesSvc = new CheckReturnInvoiceTaxesService(
    getUserLocalService,
    api,
  );

  const returnAccountReceivableInvoiceTaxesSvc =
    new ReturnAccountReceivableInvoiceTaxesService(getUserLocalService, api);

  const getAccountReceivableImportFileTemplateSvc =
    new GetAccountReceivableImportFileTemplateService(getUserLocalService, api);

  const importAccountsReceivableSvc = new ImportAccountsReceivableService(
    getUserLocalService,
    api,
  );

  const fetchCnaeSvc = new FetchCnaeService(getUserLocalService, api);

  const fetchServiceFrameworkSvc = new FetchServiceFrameworkService(
    getUserLocalService,
    api,
  );

  const genAccountReceivableInvoicesSvc =
    new GenerateAccountReceivableInvoicesService(getUserLocalService, api);

  const searchClassificationAccountService =
    new SearchClassificationAccountService(getUserLocalService, api);

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchCustomerService = new SearchCustomerService(
    getUserLocalService,
    api,
  );

  const searchPaymentAccountService = new SearchPaymentAccountService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchUserService = new SearchUserService(getUserLocalService, authApi);

  const searchBrazilianStateService = new SearchBrazilianStateService(
    getUserLocalService,
    api,
  );

  const searchBrazilianCityService = new SearchBrazilianCityService(
    getUserLocalService,
    api,
  );
  const removeBulkTerminateAccountsService =
    new RemoveBulkTerminateAccountsService(getUserLocalService, api);

  return {
    listAccountsReceivable(companyGroupId, payload) {
      return listAccountsReceivableSvc.listAccountsReceivable(
        companyGroupId,
        payload,
      );
    },
    storeAccountsReceivablePanoamaId(panoramaId) {
      storeAccountsReceivablePanoamaIdSvc.storeAccountsReceivablePanoamaId(
        panoramaId,
      );
    },
    getStoredAccountsReceivablePanoramaId() {
      return getStoredAccountsReceivablePanoramaIdSvc.getStoredAccountsReceivablePanoramaId();
    },
    listAccountsReceivablePanoramas(userId, columns) {
      return listAccountsReceivablePanoramasSvc.listAccountsReceivablePanoramas(
        userId,
        columns,
      );
    },
    saveAccountsReceivablePanorama(columns, panorama) {
      return saveAccountsReceivablePanoSvc.saveAccountsReceivablePanorama(
        columns,
        panorama,
      );
    },
    deleteAccountsReceivablePanorama(panoramaId) {
      return delAccountsReceivablePanoramaSvc.deleteAccountsReceivablePanorama(
        panoramaId,
      );
    },
    removeParcelTermination(companyGroupId, accountReceivableId) {
      return removeTerminationSvc.removeParcelTermination(
        companyGroupId,
        accountReceivableId,
      );
    },
    removeAccountTermination(companyGroupId, accountReceivableId) {
      return removeAccountTerminationSvc.removeAccountTermination(
        companyGroupId,
        accountReceivableId,
      );
    },
    cancelAccountReceivableContract(accountReceivableId, reason) {
      return cancelAccountReceivableSvc.cancelAccountReceivableContract(
        accountReceivableId,
        reason,
      );
    },
    storeAccountReceivableIdToDuplicate(accountReceivableId) {
      storeAccountReceivableIdToDuplicateSvc.storeAccountReceivableIdToDuplicate(
        accountReceivableId,
      );
    },
    exportAccountsReceivable(
      currentCompanyGroupId,
      pfsEvent,
      selectedColumns,
      orderedColNames,
    ) {
      return exportAccountsReceivableSvc.exportAccountsReceivable(
        currentCompanyGroupId,
        pfsEvent,
        selectedColumns,
        orderedColNames,
      );
    },
    undoReturn(companyGroupId, accountReceivableId) {
      return undoReturnAccountReceivableSvc.undoReturn(
        companyGroupId,
        accountReceivableId,
      );
    },
    returnTotalAccountReceivable(returnTotalAccountReceivableParams) {
      return returnTotalAccountReceivableSvc.returnTotalAccountReceivable(
        returnTotalAccountReceivableParams,
      );
    },
    returnPartialAccountReceivable(returnPartialAccountReceivableParams) {
      return returnPartialAccountReceivableSvc.returnPartialAccountReceivable(
        returnPartialAccountReceivableParams,
      );
    },
    getAttachments(accountReceivableListItem) {
      return getAttachmentsSvc.getAttachments(accountReceivableListItem);
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdSvc.getStorageFilebyId(storageFileId);
    },
    listAttachmentTypes() {
      return listAttachmentTypesSvc.listAttachmentTypes();
    },
    uploadAttachments(
      accountReceivableId,
      attachmentList,
      uploadProgressCallback,
    ) {
      return uploadAttachmentSvc.uploadAttachments(
        accountReceivableId,
        attachmentList,
        uploadProgressCallback,
      );
    },
    terminateAccountReceivableParcel(params) {
      return terminateAccReceivableParcelSvc.terminateAccountReceivableParcel(
        params,
      );
    },
    bulkTerminateAccounts(params) {
      return bulkTerminateAccountSvc.bulkTerminateAccounts(params);
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsSvc.fetchRpaParamOptions();
    },
    accountReceivableHasTaxes(accountReceivableId) {
      return accountReceivableHasTaxesSvc.accountReceivableHasTaxes(
        accountReceivableId,
      );
    },
    checkReturnInvoiceTaxes(accountReceivableId) {
      return chkReturnInvoiceTaxesSvc.checkReturnInvoiceTaxes(
        accountReceivableId,
      );
    },
    returnAccountReceivableInvoiceTaxes(params) {
      return returnAccountReceivableInvoiceTaxesSvc.returnAccountReceivableInvoiceTaxes(
        params,
      );
    },
    getAccountReceivableImportFileTemplate() {
      return getAccountReceivableImportFileTemplateSvc.getAccountReceivableImportFileTemplate();
    },
    importAccountsReceivable(file) {
      return importAccountsReceivableSvc.importAccountsReceivable(file);
    },
    fetchCnae() {
      return fetchCnaeSvc.fetchCnae();
    },
    fetchServiceFramework() {
      return fetchServiceFrameworkSvc.fetchServiceFramework();
    },
    generateAccountReceivableInvoices(data) {
      return genAccountReceivableInvoicesSvc.generateAccountReceivableInvoices(
        data,
      );
    },
    searchClassificationAccount(params) {
      return searchClassificationAccountService.searchClassificationAccount(
        params,
      );
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchCustomer(params) {
      return searchCustomerService.searchCustomer(params);
    },
    searchPaymentAccount(params) {
      return searchPaymentAccountService.searchPaymentAccount(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchUser(params) {
      return searchUserService.searchUser(params);
    },
    searchBrazilianState(params) {
      return searchBrazilianStateService.searchBrazilianState(params);
    },
    searchBrazilianCity(params) {
      return searchBrazilianCityService.searchBrazilianCity(params);
    },
    removeBulkTerminateAccounts(accountPayableIds) {
      return removeBulkTerminateAccountsService.removeBulkTerminateAccounts(
        accountPayableIds,
      );
    },
  };
}
