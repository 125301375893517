import {
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { FaExclamationCircle, FaSpinner } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";

import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { useClassName } from "../../../../core/presentation/hooks/useClassName";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import {
  ISimpleColumn,
  SimpleColumn,
  SimpleTableColumn,
} from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import { SimpleTable } from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationAccountEntity } from "../../../domain/entities/classificationAccountEntity";
import { ICompanyEntity } from "../../../domain/entities/companyEntity";

import { Container } from "./styles";
import { MakeProvider } from "../../../main/makeProvider";
import { IProviderEntity } from "../../../domain/entities/providerEntity";
import { ClassificationAccountTab } from "../ClassificationAccountTab";

interface ClassificationAccountLinkFormModalProps {
  isOpen: boolean;
  isRequired?: boolean;
  useProvider: MakeProvider;
  providerEntity?: IProviderEntity;
  onRequestClose: (hasLinked?: boolean) => void;
}
interface ClassificationAccountModalState {
  search: string;
  isLoading: boolean;
  expandedRows?: DataTableExpandedRows;
  isLoaddingButton: boolean;
  linkedClsAccounts: IClassificationAccountEntity[];
  showSelectedOnly: boolean;
  tableData?: IResponseEntity<ICompanyEntity[]>;
}

export function ClassificationAccountLinkFormModal({
  isOpen,
  useProvider,
  providerEntity,
  onRequestClose,
  isRequired = false,
}: ClassificationAccountLinkFormModalProps) {
  const {
    searchCompany,
    getClassificationAccountLinked,
    saveClassificationAccountLinked,
  } = useProvider;

  const dialog = useSoulDialog();

  const { generatePayload } = useTables();

  const [state, setState] = useState<ClassificationAccountModalState>({
    search: "",
    isLoading: false,
    isLoaddingButton: false,
    linkedClsAccounts: [],
    showSelectedOnly: false,
  });

  const columns = useMemo<SimpleTableColumn[]>(
    () =>
      [
        {
          expander: true,
          style: {
            flex: "0 0 3.5rem",
          },
          bodyTemplate(
            rowData: unknown,
            options: Record<string, Record<string, unknown>>,
          ) {
            return options.expander.element;
          },
        },
        {
          header: "Empresa",
          field: "assumedName",
        },
      ].map(colParams => new SimpleColumn(colParams)),
    [],
  );

  const handleClose = useCallback(() => {
    onRequestClose(false);
  }, [onRequestClose]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(prevState => ({ ...prevState, search: e.target.value }));
  };

  const getListCompany = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
      const data = await searchCompany(payload);
      setState(prevState => ({
        ...prevState,
        tableData: data,
        isLoading: false,
      }));
    },
    [generatePayload, columns, searchCompany],
  );

  const handleAfterOpen = useCallback(async () => {
    if (providerEntity && providerEntity.id) {
      const classificatonAccountsLinked = await getClassificationAccountLinked(
        providerEntity?.id,
      );
      setState(prevState => ({
        ...prevState,
        linkedClsAccounts: classificatonAccountsLinked,
      }));
    }
  }, [providerEntity, getClassificationAccountLinked]);

  const handleSubmit = useCallback(async () => {
    if (state.linkedClsAccounts?.length === 0) {
      return;
    }

    const linkedClsAccountsIdArray = {
      classificationAccounts: state.linkedClsAccounts.map(
        account => account.id,
      ),
    };

    if (providerEntity && state.linkedClsAccounts) {
      setState(prevState => ({
        ...prevState,
        isLoaddingButton: true,
      }));

      try {
        await saveClassificationAccountLinked(
          providerEntity.id,
          linkedClsAccountsIdArray,
        );

        setState(prevState => ({
          ...prevState,
          isLoaddingButton: false,
        }));

        await dialog.fire({
          title: "Feito!",
          text: "Classificação(ões) contábil(eis) vinculada(s) com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        });

        onRequestClose(true);
      } catch {
        dialog.close();
      }
    }
  }, [
    dialog,
    onRequestClose,
    providerEntity,
    saveClassificationAccountLinked,
    state.linkedClsAccounts,
  ]);

  const submitClassName = useClassName(
    {
      "form-button": true,
      "invalid-bkg": !!(state.linkedClsAccounts?.length === 0),
      "green-bkg": !!state.linkedClsAccounts?.length,
    },
    [state.linkedClsAccounts?.length],
  );

  const handleRowToggle = async ({ data }: DataTableRowToggleParams) => {
    setState(prevState => ({
      ...prevState,
      expandedRows: data as DataTableExpandedRows,
    }));
  };

  // Callback para receber dados do componente filho
  const handleSelectionUpdate = (info: IClassificationAccountEntity[]) => {
    setState(prevState => ({
      ...prevState,
      linkedClsAccounts: info,
    }));
  };

  const handleInvalidOnlyChange = async (value: boolean) => {
    setState(prevState => ({
      ...prevState,
      showSelectedOnly: value,
    }));
    return value;
  };

  const filteredTableData = () => {
    const filteredData = state.tableData?.data.filter(obj =>
      state.linkedClsAccounts
        .map(element => element.companyId)
        .includes(obj.id),
    );
    return {
      recordsTotal: 0,
      recordsFiltered: filteredData?.length || 0,
      data: filteredData || [],
    };
  };

  const rowExpansionTemplate = (data: ICompanyEntity) => {
    return (
      <ClassificationAccountTab
        companyId={data.id}
        useProvider={useProvider}
        search={state.search}
        linkedClsAccounts={state.linkedClsAccounts}
        onSelectionUpdate={handleSelectionUpdate}
        showSelectedOnly={state.showSelectedOnly}
      />
    );
  };

  const handleAfterClose = () => {
    setState({
      search: "",
      isLoading: false,
      isLoaddingButton: false,
      linkedClsAccounts: [],
      showSelectedOnly: false,
      tableData: undefined,
      expandedRows: undefined,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      onRequestClose={handleClose}
      shouldCloseOnEsc={!state.isLoading}
      shouldCloseOnOverlayClick={!state.isLoading}
      onAfterClose={handleAfterClose}
      onAfterOpen={handleAfterOpen}
    >
      <Container>
        <div className="react-modal-header">
          <h4>Vínculo de classificações contábeis ao cliente</h4>
          {!state.isLoading ? (
            <button
              className="react-modal-close"
              type="button"
              id="btn-cross-class-account"
              data-testid="btn-cross-class-account"
              onClick={handleClose}
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        <div className="classification-accounts-header">
          {isRequired ? (
            <div className="required-warning">
              <FaExclamationCircle />
              <p>
                Para prosseguir é obrigatório vincular pelo menos uma
                Classificação Contábil ao Cliente.
              </p>
            </div>
          ) : null}
          <div className="card card-header-border">
            <p>Vinculando classificações contábeis ao cliente</p>
            <h3>{providerEntity?.name}</h3>
            <h5>
              Clique sobre a classificação contábil e depois clique em &ldquo;
              Salvar&ldquo; para vinculá-la ao cliente.
            </h5>
          </div>
        </div>
        <div className="react-modal-body">
          <div className="table-header">
            <InputSearch value={state.search} onChange={handleSearchChange} />
            <label>
              <Toggle
                id="btn-toggle-invalid"
                value={state.showSelectedOnly}
                data-testid="btn-toggle-invalid"
                onChange={handleInvalidOnlyChange}
              />
              Exibir somente selecionados
            </label>
          </div>
          <div className="simple-table-wrapper">
            <SimpleTable
              paginator={false}
              rowHover
              dataKey="id"
              columns={columns}
              getList={getListCompany}
              data={
                state.showSelectedOnly ? filteredTableData() : state.tableData
              }
              responsiveLayout="scroll"
              tableClassName="main-table"
              onRowToggle={handleRowToggle}
              expandedRows={state.expandedRows}
              emptyMessage="Nenhum registro encontrado"
              rowExpansionTemplate={rowExpansionTemplate}
              selectionMode="checkbox"
              loading={state.isLoading}
              globalFilter={state.search}
            />
          </div>
        </div>
        <div className="react-modal-footer">
          <button
            type="button"
            className="form-button red-bkg"
            id="btn-close-class-account"
            onClick={handleClose}
          >
            Fechar
          </button>
          <button
            type="submit"
            className={submitClassName}
            id="btn-save-class-account"
            disabled={
              state.isLoaddingButton || state.linkedClsAccounts?.length === 0
            }
            onClick={handleSubmit}
          >
            Salvar {state.isLoaddingButton && <FaSpinner className="spinner" />}
          </button>
        </div>
      </Container>
    </Modal>
  );
}
