export const GERAL_COMPANY_ID = "00000000-0000-0000-0000-000000000000";

export interface ICompanyEntity {
  assumedName: string;
  id: string;
  active: boolean;
}

export class CompanyEntity implements ICompanyEntity {
  assumedName = "";
  id = "";
  active = true;

  constructor(params?: Partial<ICompanyEntity>) {
    Object.assign(this, params);
  }

  static create(params?: Partial<ICompanyEntity>) {
    return new CompanyEntity(params);
  }
}
