import { ChangeEvent, useCallback, useRef, useState } from "react";
import { FaCaretDown, FaFileExcel, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IApiService } from "../../../../core/data/services/apiService";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { Page } from "../../../../core/presentation/components/Page/styles";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useIsMounted } from "../../../../core/presentation/hooks/useIsMounted";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import {
  IResponseEntity,
  ResponseEntity,
} from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationUspEntity } from "../../../domain/entities/classificationUspEntity";
import {
  makeClassificationUsp,
  MakeClassificationUsp,
} from "../../../main/makeClassificationUsp";
import { useClassificationUspGrid } from "../../hooks/useClassificationUspGrid";
import { ClassificationUspFormModal } from "../ClassificationUspModal";
import { Container } from "./styles";
import { GridOptionsContainer } from "../../../../core/presentation/components/GridOptionsContainer";
import { Toggle } from "../../../../core/presentation/components/Toggle";
import { SoulDropdown } from "../../../../core/presentation/components/SoulDropdown";
import { ExportingModalContent } from "../../../../core/presentation/components/ExportingModalContent";

interface ClassificationUspPageProps {
  useClassificationUsp: MakeClassificationUsp;
}

export function ClassificationUspPage({
  useClassificationUsp,
}: ClassificationUspPageProps) {
  const table = useRef<ISimpleTableHandle>(null);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [isOpenClassificationUspModal, setIsOpenClassificationUspModal] =
    useState<boolean>(false);
  const [currentClassificationUspId, setCurrentClassificationUspId] =
    useState("");
  const [showActivesOnly, setShowActivesOnly] = useState<boolean>(false);
  const [pfsEvent, setPfsEvent] = useState<IPFSEventEntity>(
    new PFSEventEntity(),
  );

  const openClassificationUspFormModal = useCallback((currentId = "") => {
    setCurrentClassificationUspId(currentId);
    setIsOpenClassificationUspModal(true);
  }, []);

  const closeClassificationUspFormModal = useCallback(() => {
    setIsOpenClassificationUspModal(false);
    setCurrentClassificationUspId("");
    table.current?.reload();
  }, []);

  const {
    listClassificationUsp,
    toggleClassificationUsp,
    exportClassificationUsp,
  } = useClassificationUsp;

  const dialog = useSoulDialog();

  const toggle = useCallback(
    async currentId => {
      try {
        const { active } = await toggleClassificationUsp(currentId);
        const text = active ? "ativada" : "inativada";

        await dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Classificação USP ${text} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleClassificationUsp],
  );

  const copyId = useCallback((id: string) => {
    navigator.clipboard.writeText(id);
    toast.success(
      "O ID da Classificação USP foi copiado para sua área de transferência",
      {
        autoClose: 2000,
      },
    );
  }, []);

  const {
    currentCompanyGroup: { id },
  } = useCurrentCompanyGroup();
  const { columns } = useClassificationUspGrid({
    openEditModal: openClassificationUspFormModal,
    toggle,
    copyId,
  });
  const { generatePayload } = useTables();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    IResponseEntity<IClassificationUspEntity[]> | undefined
  >();

  const mountedRef = useIsMounted();

  const getList = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      if (!mountedRef.current) {
        return;
      }

      setPfsEvent(_pfsEvent);

      if (!id) {
        setData(new ResponseEntity({ data: [] }));
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const clsUspList = await listClassificationUsp(
          id,
          payload,
          showActivesOnly,
        );

        if (!mountedRef.current) {
          return;
        }

        setData(clsUspList);
      } catch {
        setData(new ResponseEntity({ data: [] }));
      } finally {
        setLoading(false);

        // Isso é necessário pois temos elementos dinamicos
        // com tooltip e o ReactTooltip precisa escanea-los
        ReactTooltip.rebuild();
      }
    },
    [
      columns,
      generatePayload,
      id,
      listClassificationUsp,
      mountedRef,
      showActivesOnly,
    ],
  );

  const handleShowActivesOnly = async (value: boolean) => {
    setShowActivesOnly(prevState => !prevState);

    return value;
  };

  const handleExportSheet = async () => {
    dialog.fire({
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      html: <ExportingModalContent />,
    });

    const payload = generatePayload(pfsEvent, columns as ISimpleColumn[]);

    try {
      await exportClassificationUsp({ payload, showActivesOnly });
    } finally {
      dialog.close();
    }
  };

  const debounceTime = useDebounceTime();

  const handleSearchOnChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSearch(value);

      debounceTime(() => {
        setGlobalFilter(value);
      }, 700);
    },
    [debounceTime],
  );

  const allowedProfiles = useAllowedProfiles();

  return (
    <Container>
      <Page>
        <header>
          <InputSearch
            id="txt-search"
            data-testid="txt-search"
            value={search}
            onChange={handleSearchOnChange}
          />
          <GridOptionsContainer>
            <label className="actives-only">
              <Toggle
                id="btn-toggle-invalid"
                value={showActivesOnly}
                onChange={handleShowActivesOnly}
              />
              Exibir somente ativos
            </label>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-place="top"
                  data-event="none"
                  data-effect="solid"
                  data-event-off="click"
                  data-testid="btn-options"
                  className="default-button options-context"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                className="dropdown-item"
                type="button"
                onClick={handleExportSheet}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para excel</span>
              </button>
            </SoulDropdown>
            {allowedProfiles(
              EUserProfile.financialManagement,
              EUserProfile.supervisor,
            ) ? (
              <button
                type="button"
                className="default-button"
                id="btn-add"
                onClick={() => openClassificationUspFormModal()}
              >
                <FaPlus /> Adicionar Classificação USP
              </button>
            ) : null}
          </GridOptionsContainer>
          <ClassificationUspFormModal
            isOpen={isOpenClassificationUspModal}
            onRequestClose={closeClassificationUspFormModal}
            currentId={currentClassificationUspId}
            useClassificationUsp={useClassificationUsp}
          />
        </header>
        <article className="no-padding">
          <SimpleTable<IClassificationUspEntity>
            tableRef={table}
            data={data}
            loading={loading}
            columns={columns}
            globalFilter={globalFilter}
            getList={getList}
          />
        </article>
      </Page>
    </Container>
  );
}

interface ClassificationUspFactoryProps {
  api: IApiService;
}

export function ClassificationUspFactory({
  api,
}: ClassificationUspFactoryProps) {
  return (
    <ClassificationUspPage useClassificationUsp={makeClassificationUsp(api)} />
  );
}
