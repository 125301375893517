import { format, parse } from "date-fns";
import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IReturnTotalAccountReceivableContract } from "../../domain/contracts/returnTotalAccountReceivableContract";
import { EReturnStatus } from "../../domain/entities/accountReceivableListItemEntity";
import {
  IReturnAccountErrorEntity,
  IReturnAccountReceivableFormEntity,
} from "../../domain/entities/returnAccountReceivableFormEntity";
import {
  IReturnAccountReceivablePayloadModel,
  IReturnAccountReceivableResponseModel,
} from "../models/returnAccountReceivableModels";

export class ReturnTotalAccountReceivableService
  implements IReturnTotalAccountReceivableContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async returnTotalAccountReceivable(
    params: IReturnAccountReceivableFormEntity,
  ): Promise<void> {
    const {
      returnDate,
      returnValue,
      taxReturnDate,
      costCenterLinks,
      accountReceivableId,
    } = params;

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsReceivable/${accountReceivableId}/Return`;

    const payload: IReturnAccountReceivablePayloadModel = {
      returnValue,
      returnDate: this.formatDate(returnDate),
      returnStatus: EReturnStatus.FullyReturned,
      taxReturnDate: taxReturnDate ? this.formatDate(taxReturnDate) : undefined,
    };

    if (costCenterLinks) {
      payload.costCenterLinks = {
        costCenter: costCenterLinks.costCenterDestination?.name || "",

        costCenterIdInactive: costCenterLinks.costCenterDisabled.id,
        nameInactive: costCenterLinks.costCenterDisabled.name,

        costCenterIdActive: costCenterLinks.costCenterDestination?.id || "",
        nameActive: costCenterLinks.costCenterDestination?.name || "",
      };
    }

    const response = await this.api.put<IReturnAccountReceivableResponseModel>(
      url,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    if (!response.success) {
      this.handleReturnErrors(response);
    }
  }

  private formatDate(date: string) {
    const parsedDate = parse(date, "ddMMyyyy", new Date());
    return format(parsedDate, "yyyy-MM-dd");
  }

  private handleReturnErrors(response: IReturnAccountReceivableResponseModel) {
    const companyLockUntil = response?.companyLockUntil
      ? format(new Date(response.companyLockUntil), "dd/MM/yyyy")
      : "";

    const hasCostCenterLinksError =
      response?.costCenterLinks?.nameInactive &&
      response?.costCenterLinks?.costCenterIdInactive;

    const costCenterLinks = hasCostCenterLinksError
      ? {
          costCenterDestination: null,
          costCenterDisabled: {
            name: response.costCenterLinks.nameInactive,
            id: response.costCenterLinks.costCenterIdInactive,
          },
        }
      : null;

    const returnDateIsValid = Boolean(response?.returnDateIsValid);
    const taxReturnDateIsValid = Boolean(response?.taxReturnDateIsValid);
    const companyClosedForReleases =
      Boolean(response?.companyClosedForReleases) || false;

    const hasFormErrors =
      !!costCenterLinks ||
      !returnDateIsValid ||
      !taxReturnDateIsValid ||
      companyClosedForReleases;

    const error: IReturnAccountErrorEntity = {
      hasFormErrors,
      success: false,
      costCenterLinks,
      companyLockUntil,
      returnDateIsValid,
      taxReturnDateIsValid,
      companyClosedForReleases,
      message: response?.message || "",
    };

    throw error;
  }
}
