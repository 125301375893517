import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { ISaveClassificationAccountContract } from "../../domain/contracts/saveClassificationAccountContract";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";
import { IClassificationAccountFormEntity } from "../../domain/entities/classificationAccountFormEntity";

export class SaveClassificationAccountService
  implements ISaveClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  saveClassificationAccount(
    classificationAccountForm: IClassificationAccountFormEntity,
    companyId: string,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = `/ClassificationAccounts`;

    const payload = { ...classificationAccountForm, companyId };

    return this.api.post<IClassificationAccountEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
