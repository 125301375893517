import { IServerSideResponseModel } from "../../../core/data/models/serverSideResponseModel";
import { IApiService } from "../../../core/data/services/apiService";
import { EUserProfile } from "../../../core/domain/entities/userEntity";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ILinkedCompanyGroupEntity } from "../../domain/entities/linkedCompanyGroupEntity";
import { IListCompanyGroupsService } from "../../domain/usecases/listCompanyGroupsUseCase";

export class ListCompanyGroupsService implements IListCompanyGroupsService {
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private soulApi: IApiService,
  ) {}

  async listCompanyGroups(
    payload: IPayloadEntity,
  ): Promise<IResponseEntity<ILinkedCompanyGroupEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    let url = `/CompanyGroups/Actives?${params}`;

    if (userEntity?.profile === EUserProfile.supervisor) {
      url = `/CompanyGroups/Actives/Supervisor?${params}`;
    }

    const response = await this.soulApi.get<
      IServerSideResponseModel<ILinkedCompanyGroupEntity[]>
    >(url, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });

    return response;
  }
}
