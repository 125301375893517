import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --font-family: 'Open Sans', sans-serif;

    --text-muted: #8898aa;
    --text-muted-light: #f4f5f6cc;

    --invalid: #fb6340;
    --modal-background: #fff;

    --soul-color01: #090081;
    --soul-color02: #445fb5;
    --soul-color03: #283278;

    --color-danger: #f5365c;

    --soul-green-button: #2dce89;
    --soul-blue-button: #090081;
    --soul-red-button: var(--color-danger);
    --soul-red-button-light: var(--color-danger);
    --soul-yellow-button: #ffb881;
    --soul-invalid-button: #172b4d;
    --border-radius: 0.3125rem;
    --input-border-color: #ced4da;
    --highlight-color: #5e72e4;

    /* TODO - DEFINIR VARIAVEIS DE COR DO TEMA */
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0.65rem;
      height: 0.65rem;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c4c7e4;
      border-radius: 1rem;
      border: none;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: none;
    }
  }

  .__react_component_tooltip.app-tooltip {
    z-index: 9999;
    text-align: center;
  }

  html.dev-env {
    --hue: 135deg;
    height: 100%;

    .sidenav {
      filter: hue-rotate(var(--hue));

      img {
        filter: hue-rotate(calc(360deg - var(--hue)));
      }
    }

    .admin-header.bg-gradient-soul {
      background: linear-gradient(227deg,#ff8e24,#ff3000) !important;
    }
  }

  body {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;

    /* essa regra impede que o layout da tela quebre quando um swal
    abre numa pagina que usa a grid avancada que tem altura 100% */
    &.swal2-height-auto {
      height: 100% !important;
    }

    &.ReactModal__Body--open {
      overflow: hidden;
    }

    & > .react-modal-overlay {
      overflow: hidden;
    }

    .default-icon-tooltip {
      .p-tooltip-text {
        width: 320px;
        padding: unset;
        background-color: white;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);

        .default-title {
          color: #32325d;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.75rem;
          border-bottom: 1px solid var(--input-border-color);
        }

        .default-text {
          color: #525f7f;
          padding: 0.75rem;
          font-size: 0.875rem;
        }
      }

      .p-tooltip-arrow {
        display: none;
      }
    }

    .fee-autocomplete .p-autocomplete-items .p-autocomplete-item,
    .fee-dropdown.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {

      &:hover {
        color: white;
        background-color: var(--highlight-color);
      }

      &.p-highlight {

        color: white;
        font-weight: bold;
        background-color: var(--highlight-color);
      }
    }
  }

  .muted {
    color: var(--text-muted);
  }

  /* modal overlay styles */
  .react-modal-overlay {
    /* TODO - fazer regra que trave o overflow do body quando a modal estiver aberta */
    z-index: 1050;

    &.accounts-payable__modal--fusp-export {
      z-index: 900;
    }

    background: rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: grid;
    align-items: center;
    justify-content: center;

    overflow: auto;
  }

  .ReactModal__Overlay {
    position: fixed;
  }

  .ReactModal__Content {
    opacity: 0;
    transition: opacity .15s linear;
  }

  .ReactModal__Content--after-open{
    opacity: 1;
  }

  .ReactModal__Content--before-close{
    opacity: 0;
  }

  .react-modal-content {
    width: auto;
    background: var(--modal-background);
    position: relative;
    border-radius: 0.24rem;
    margin: 1.75rem 0;

    .react-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 1.25rem;

      h4 {
        font-size: 0.9375rem;
        font-weight: 600;
        color: #32325d;
      }

      .react-modal-close {
        background-color: transparent;
        height: 1.25rem;
        width: 1.25rem;
        line-height: 17px;
        border: none;
        font-size: 1rem;
        color: rgba(0,0,0,.6);
        opacity: 0.75;

        transition: filter 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .react-modal-body {
      padding: 1.5rem;

      .modal-content {
        padding: 1.5rem;
      }
    }

    .react-modal-footer {
      padding: 0 1.25rem 1.25rem;
    }

  }

  /* prime paginator custom styles */
  .p-paginator .p-dropdown {
    margin-right: 0.5rem;
  }

  /* prime dropdown custom styles */
  .p-dropdown-panel, .p-autocomplete-panel {
    /* ação necessária pra aparecer a caixa de resultado de pesquisa dos dropdowns */
    z-index: 1200 !important;
  }

  .p-dropdown, .p-autocomplete {
    border-radius: 0.25rem;
  }

  .p-dropdown-label {
    padding: 0;
    font-size: 0.875rem;
  }

  .p-dropdown-filter {
    padding: 0.25rem 1.75rem 0.25rem 0.5rem !important;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }

  .p-dropdown-item, .p-autocomplete-item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  .p-invalid {
    border-color: var(--invalid) !important;
  }

  .p-disabled, .p-component:disabled {
    opacity: 1;
    pointer-events: unset;
    background-color: #e9ecef !important;
    cursor: not-allowed !important;
  }

  [disabled] {
    opacity: 1;
    background-color: #e9ecef;
    cursor: not-allowed;
  }

  button {
    pointer-events: auto;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    &[disabled] {
      opacity: 0.5;
    }

    &.default-button, &.outline-button {
      color: #fff;
      background-color: var(--soul-color01);
      border-color: var(--soul-color01);
      border-radius: 0.375rem;
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      border-style: solid;

      svg {
        margin-right: 0.5rem;
      }

      :hover:not(:disabled) {
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
      }

      &.options-context {
        background-color: var(--soul-color02);
        border-color: var(--soul-color02);
        gap: 0.5rem;
        padding-right: 0;
      }
    }

    &.default-button {
      margin-left: auto;
    }

    &.outline-button {
      color: var(--soul-color01);
      background-color: transparent;
      border-color: var(--soul-color01);
      border-style: solid;
      border-width: 1px;
      padding: 0.3rem;
      transition: all 0.15s ease-out;

      &.tool {
        &:not(:last-child):first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
          border-radius: 0;
        }

        &:not(:first-child):last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &:hover:not(:disabled) {
        background-color: var(--soul-color01);
        color: #fff;
      }

      svg {
        margin-right: 0;
      }
    }

    &.form-button {
      padding: 0.625rem 1.25rem;
      border-radius: 0.375rem;
      display: inline-block;
      font-weight: 600;
      color: white;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      line-height: 1.5;
      letter-spacing: 0.025em;
      box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

      transition: all 0.15s ease;

      :not(:last-child) {
        margin-right: 0.75rem;
      }

      :hover {
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
      }

      &.red-bkg {
        background-color: var(--soul-red-button);
      }

      &.green-bkg {
        background-color: var(--soul-green-button);

        &.no-margin {
          margin-right: unset;
        }
      }

      &.blue-bkg {
        background-color: var(--soul-blue-button);

      }

      &.invalid-bkg {
        background-color: var(--soul-invalid-button);
      }

      &.blue-bkg {
        background-color: #3649f5;
      }

      svg {
        font-size: 0.875rem;
        vertical-align: middle;
      }
    }
  }

  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    justify-content: space-between;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }


  /* padrão de formulário */
  .form-container {
    .form-row {
      display: flex;
      margin: 0 -0.3125rem 0 -0.3125rem;

      > .form-control {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 0.3125rem 0 0.3125rem;

        /* TODO - verificar se essa configuração não afetará outra parte do sistema. */
        &:not(.col-12) &+.form-control {
          margin-top: 1rem;
        }

        > span:first-child {
          color: #525f7f;
          font-size: 0.869rem;
          font-weight: 600;
          line-height: 1.5;
          margin-bottom: 0.5rem;

          &.no-margin {
            margin-bottom: unset;
          }

          small {
            font-weight: 400;
            color: var(--text-muted);
          }
        }

        > .custom-input.isInvalid {
          border-style: solid;
          border-width: 1px;
          border-color: var(--invalid);
        }

        > input:not([type=checkbox]),
        textarea,
        select, .form-input, .p-dropdown, .p-autocomplete > input {
          font-family: var(--font-family);
          width: 100%;
          padding: 0.25rem 0.5rem 0.25rem 0.5rem !important;
          font-size: 0.875rem;
          border-radius: 0.25rem;
          line-height: 1.5;
          border-style: solid;
          border-width: 1px;
          border-color: var(--input-border-color);
          height: calc(1.5em + .5rem + 2px);
          color: #495057 !important;
          background-color: #fff;

          :focus {
            outline: unset;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
            border-color: rgba(50, 151, 211, 0.25);
          }

          ::placeholder {
            color: #d1d1d9;
          }

          :disabled {
            background-color: #e9ecef;
            color: #495057 !important;
          }

          &.isInvalid {
            border-color: var(--invalid);

            :focus {
              box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
            }

          }
        }

      }

      & + .form-row {
        margin-top: 1rem;

        &.no-margin {
            margin-top: unset;
          }
      }
    }

    & + .btn-clear-filter {
      margin-left: 0.5rem;
    }

  }

  .col-12 {
    flex: 0 1 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
  }

  .p-overlaypanel .p-overlaypanel-content {
    padding: 0;
  }

  .col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .shadow {
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15)!important
  }

  /* todo - fazer padding responsivo para telas menores */
  @media (min-width: 768px) {
    .container-fluid {
      padding-left: 2.4375rem;
      padding-right: 2.4375rem;
    }
  }

  /* espaçamento padrão dos SweetAlert2 (Swal) */
  .swal2-title {
    font-size: 1.25em;
    font-weight: 600;
  }

  .swal2-html-container {
    font-size: 1.125em;
    margin: 1em 1.25em 0;

    .import-file-error-dialog {
      .import-file-error-message {
        margin-bottom: 1rem;
      }

      .import-file-error-container {
        overflow: hidden;
        overflow-y: auto;
        height: calc(38vh - 32px);

        .import-file-error-warning {
          gap: 15px;
          display: flex;
          align-items: center;


          color: var(--color-danger);
          font-size: .875rem;

          border-radius: 0.375rem;
          background-color: #f8d7da;

          margin-bottom: 1rem;
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  /* código dos botões agrupados usados nas grids (será necessario ajustar pro estilo do soul). */
  .btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }

  .btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn {
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.375rem;
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner {
    animation: spinner .6s linear infinite;
  }

  .text-truncate {
    min-width: 0;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .p-progress-spinner {
    width: 50px;
    height: 50px;

    svg {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }

    @keyframes p-progress-spinner-color {
      100%,
      0% {
        stroke: #140fb8;
      }
    }
  }

  /* Padrão para ícones */
  .green-icon {
    color: var(--soul-green-button);
    fill: var(--soul-green-button) !important;

    svg {
      fill: var(--soul-green-button) !important;
    }
  }

  .red-icon {
    color: var(--soul-red-button);
    fill: var(--soul-red-button) !important;

    svg {
      fill: var(--soul-red-button) !important;
    }
  }

  .yellow-icon {
    color: var(--soul-yellow-button);
    fill: var(--soul-yellow-button) !important;

    svg {
      fill: var(--soul-yellow-button) !important;
    }
  }

  .cost-center-report-manager-context-menu {
    .p-menuitem.disabled {
      opacity: 0.5;

      .p-menuitem-link {
        cursor: default;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  /** Padrão para formulários */
  .soul-form {
    .form-header {
      font-size: 0.875rem;
      font-weight: 600;
      color: #525f7f;
    }
  }

  .input-search-providers {
    .p-autocomplete-items {
      .p-autocomplete-item {
        display: flex;
        padding: unset;

        span {
          padding: 0.75rem 1.25rem;

          &.selected {
            font-weight: bold;
          }

          &.no-registers {
            gap: 0.5rem;
            display: flex;
            align-items: center;
          }

        }

        &:has(.selected), &:has(.no-registers), &:hover {
          color: white;
          background-color: var(--highlight-color);
        }

        /** Botão de "Adicionar Fornecedor" */
        button {
          width: 100%;
          border: none;
          text-align: left;
          background-color: white;
          padding: 0.75rem 1.25rem;

          gap:0.5rem;
          display: flex;
          align-items: center;

          color: white;
          background-color: var(--highlight-color);
        }
      }
    }
  }

  .soul-auto-complete {
    .p-autocomplete-items {
      .p-autocomplete-item {
        .no-registers {
          gap: 0.5rem;
          display: flex;
          line-height: 1rem;
          align-items: center;
        }

        &:has(.selected) {
          font-weight: bold;
        }

        &:has(.selected), &:has(.no-registers), &:hover {
          color: white;
          background-color: var(--highlight-color);
        }
      }
    }
  }

  .multiselect-panel {
    border: 1px solid var(--input-border-color);

    .p-multiselect-items {
      padding: unset;

      .p-multiselect-item {
        padding: 0.5rem 0.8rem;

        &.empty-message {
          pointer-events: none;


          & :first-child {
            display: none;
          }


        }
      }
    }
  }

  /* estilizacao especifica do tooltip do relatorio de centro
  de custos, precisa ser no gloal pois é renderizado no body */
  .cost-center-report__toolbar__tooltip--large {
    width: 200px;
    text-align: center;
  }
`;
