import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { FetchCommercialProposalEnums } from "../../../../core/data/services/fetchCommercialProposalEnumsService";
import { GenerateCommercialProposalAttachmentService } from "../../../../core/data/services/generateCommercialProposalAttachmentService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { SearchClassificationAssessmentService } from "../../../../core/data/services/searchClassificationAssessmentService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchCostCenterService } from "../../../../core/data/services/searchCostCenterService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { IFetchCommercialProposalEnumsContract } from "../../../../core/domain/contracts/fetchCommercialProposalEnumsContract";
import { IFetchRpaParamOptionsContract } from "../../../../core/domain/contracts/fetchRpaParamOptionsContract";
import { IGenerateCommercialProposalAttachmentContract } from "../../../../core/domain/contracts/generateCommercialProposalAttachmentContract";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationAssessmentContract } from "../../../../core/domain/contracts/searchClassificationAssessmentContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchCostCenterContract } from "../../../../core/domain/contracts/searchCostCenterContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { CheckDocumentNumberService } from "../data/services/checkDocumentNumberService";
import { FetchAccountPayablePaymentsMethodsService } from "../data/services/fetchAccountPayablePaymentsMethodsService";
import { FetchDocumentStatusService } from "../data/services/fetchDocumentStatusService";
import { FetchRpaParamOptionsService } from "../data/services/fetchRpaParamOptionsService";
import { GenerateAttachmentRpaService } from "../data/services/generateAttachmentRpaService";
import { GenerateAttachmentVariableAdditionalService } from "../data/services/generateAttachmentVariableAdditionalService";
import { GetAccountPayableByIdService } from "../data/services/getAccountPayableByIdService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetProviderByIdService } from "../data/services/getProviderByIdService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { ListActiveCostCentersService } from "../data/services/listActiveCostCentersService";
import { ListAttachmentTypesService } from "../data/services/listAttachmentTypesService";
import { PostNewAccountPayableService } from "../data/services/postNewAccountPayableService";
import { ProviderHasBankingDataService } from "../data/services/providerHasBankingDataService";
import { ProviderHasClassificationAccountService } from "../data/services/providerHasClassificationAccountService";
import { SearchCompanyPaymentAccountsService } from "../data/services/searchCompanyPaymentAccountsService";
import { SearchProviderClassificationAccountByCompanyIdService } from "../data/services/searchProviderClassificationAccountByCompanyIdService";
import { SearchProviderClassificationAccountService } from "../data/services/searchProviderClassificationAccountService";
import { SearchProviderService } from "../data/services/searchProviderService";
import { UpdateAccountPayableService } from "../data/services/updateAccountPayableService";
import { UpdateAttachmentsBarcodeService } from "../data/services/updateAttachmentsBarcodeService";
import { UploadAttachmentService } from "../data/services/uploadAttachmentService";
import { ICheckDocumentNumberContract } from "../domain/contracts/checkDocumentNumberContract";
import { IFetchAccountPayablePaymentsMethodsContract } from "../domain/contracts/fetchAccountPayablePaymentsMethodsContract";
import { IFetchDocumentStatusContract } from "../domain/contracts/fetchDocumentStatusContract";
import { IGenerateAttachmentRpaContract } from "../domain/contracts/generateAttachmentRpaContract";
import { IGenerateAttachmentVariableAdditionalContract } from "../domain/contracts/generateAttachmentVariableAdditionalContract";
import { IGetAccountPayableByIdContract } from "../domain/contracts/getAccountPayableByIdContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetProviderByIdContract } from "../domain/contracts/getProviderByIdContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IListActiveCostCentersContract } from "../domain/contracts/listActiveCostCentersContract";
import { IPostNewAccountPayableContract } from "../domain/contracts/postNewAccountPayableContract";
import { IProviderHasBankingDataContract } from "../domain/contracts/providerHasBankingDataContract";
import { IProviderHasClassificationAccountContract } from "../domain/contracts/providerHasClassificationAccountContract";
import { ISearchCompanyPaymentAccountsContract } from "../domain/contracts/searchCompanyPaymentAccountsContract";
import { ISearchProviderClassificationAccountByCompanyIdContract } from "../domain/contracts/searchProviderClassificationAccountByCompanyIdContract";
import { ISearchProviderClassificationAccountContract } from "../domain/contracts/searchProviderClassificationAccountContract";
import { ISearchProviderContract } from "../domain/contracts/searchProviderContract";
import { IUpdateAccountPayableContract } from "../domain/contracts/updateAccountPayableContract";
import { IUpdateAttachmentsBarcodeContract } from "../domain/contracts/updateAttachmentsBarcodeContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";

export type MakeAccountsPayableForm = IFetchDocumentStatusContract &
  ISearchProviderClassificationAccountContract &
  ISearchCompanyPaymentAccountsContract &
  ICheckDocumentNumberContract &
  ISearchProviderContract &
  IGetAttachmentsContract &
  IGetStorageFilebyIdContract &
  IListAttachmentTypesContract &
  IUploadAttachmentsContract &
  IUpdateAttachmentsBarcodeContract &
  IGenerateAttachmentRpaContract &
  IGenerateAttachmentVariableAdditionalContract &
  IFetchRpaParamOptionsContract &
  IPostNewAccountPayableContract &
  IGetAccountPayableByIdContract &
  IUpdateAccountPayableContract &
  IListActiveCostCentersContract &
  IProviderHasClassificationAccountContract &
  IGetProviderByIdContract &
  IProviderHasBankingDataContract &
  ISearchClassificationAssessmentContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchCostCenterContract &
  ISearchProjectContract &
  ISearchClassificationUspContract &
  ISearchProviderClassificationAccountByCompanyIdContract &
  IFetchCommercialProposalEnumsContract &
  IGenerateCommercialProposalAttachmentContract &
  IFetchAccountPayablePaymentsMethodsContract;

export function makeAccountsPayableForm(
  api: IApiService,
  barcodeApi: IApiService,
): MakeAccountsPayableForm {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const fetchAccountPayablePaymentsMethods =
    new FetchAccountPayablePaymentsMethodsService(getUserLocalService, api);

  const fetchDocumentStatusSvc = new FetchDocumentStatusService(
    getUserLocalService,
    api,
  );

  const srcCompanyPaymentAccountsSvc = new SearchCompanyPaymentAccountsService(
    getUserLocalService,
    api,
  );

  const srcProviderSvc = new SearchProviderService(getUserLocalService, api);

  const srcProviderClassificationAccountSvc =
    new SearchProviderClassificationAccountService(getUserLocalService, api);

  const chkDocNumberSvc = new CheckDocumentNumberService(
    getUserLocalService,
    api,
  );

  const fetchRpaParamOptionsSvc = new FetchRpaParamOptionsService(
    getUserLocalService,
    api,
  );

  const genAttachmentRpaSvc = new GenerateAttachmentRpaService(
    getUserLocalService,
    api,
  );

  const genAttachmentVariableAdditionalvc =
    new GenerateAttachmentVariableAdditionalService(getUserLocalService, api);

  const getAttachmentsSvc = new GetAttachmentsService(getUserLocalService, api);

  const getStorageFilebyIdSvc = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesSvc = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const updtAttachmentsBarcodeSvc = new UpdateAttachmentsBarcodeService(
    getUserLocalService,
    api,
    barcodeApi,
  );

  const uploadAttachmentSvc = new UploadAttachmentService(
    getUserLocalService,
    api,
  );

  const postNewAccountPayableSvc = new PostNewAccountPayableService(
    getUserLocalService,
    api,
  );

  const getAccountPayableByIdSvc = new GetAccountPayableByIdService(
    getUserLocalService,
    api,
  );

  const updtAccountPayableSvc = new UpdateAccountPayableService(
    getUserLocalService,
    api,
  );

  const listActiveCostCentersSvc = new ListActiveCostCentersService(
    getUserLocalService,
    api,
  );

  const providerHasClassificationAccountSvc =
    new ProviderHasClassificationAccountService(getUserLocalService, api);

  const getProviderByIdSvc = new GetProviderByIdService(
    getUserLocalService,
    api,
  );

  const svc = new ProviderHasBankingDataService(getUserLocalService, api);

  const searchClassificationAssessmentService =
    new SearchClassificationAssessmentService(getUserLocalService, api);

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchCostCenterService = new SearchCostCenterService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchProviderClassificationAccountByCompanyIdService =
    new SearchProviderClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  const fetchCommercialProposalEnumsService = new FetchCommercialProposalEnums(
    getUserLocalService,
    api,
  );

  const generateCommercialProposalAttachmentService =
    new GenerateCommercialProposalAttachmentService(getUserLocalService, api);

  return {
    fetchAccountPayablePaymentsMethods() {
      return fetchAccountPayablePaymentsMethods.fetchAccountPayablePaymentsMethods();
    },
    fetchDocumentStatus() {
      return fetchDocumentStatusSvc.fetchDocumentStatus();
    },
    searchCompanyPaymentAccounts(
      companyGroupId,
      companyId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return srcCompanyPaymentAccountsSvc.searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProvider(companyGroupId, search, fetchMinLength, onlyActive = false) {
      return srcProviderSvc.searchProvider(
        companyGroupId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchProviderClassificationAccount(
      companyGroupId,
      providerId,
      search,
      fetchMinLength,
      onlyActive = false,
    ) {
      return srcProviderClassificationAccountSvc.searchProviderClassificationAccount(
        companyGroupId,
        providerId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    checkDocumentNumber({
      companyGroupId,
      documentNumber,
      providerId,
      accountPayableId,
    }) {
      return chkDocNumberSvc.checkDocumentNumber({
        companyGroupId,
        documentNumber,
        providerId,
        accountPayableId,
      });
    },
    fetchRpaParamOptions() {
      return fetchRpaParamOptionsSvc.fetchRpaParamOptions();
    },
    generateAttachmentRpa(generateAttachmentRpaParams) {
      return genAttachmentRpaSvc.generateAttachmentRpa(
        generateAttachmentRpaParams,
      );
    },
    generateAttachmentVariableAdditional(accountPayableFormEntity) {
      return genAttachmentVariableAdditionalvc.generateAttachmentVariableAdditional(
        accountPayableFormEntity,
      );
    },
    getAttachments(accountPayableFormEntity) {
      return getAttachmentsSvc.getAttachments(accountPayableFormEntity);
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdSvc.getStorageFilebyId(storageFileId);
    },
    listAttachmentTypes() {
      return listAttachmentTypesSvc.listAttachmentTypes();
    },
    updateAttachmentsBarcode(companyGroupId, attachmentFormInput) {
      return updtAttachmentsBarcodeSvc.updateAttachmentsBarcode(
        companyGroupId,
        attachmentFormInput,
      );
    },
    uploadAttachments(params) {
      return uploadAttachmentSvc.uploadAttachments(params);
    },
    postNewAccountPayable(formEntity) {
      return postNewAccountPayableSvc.postNewAccountPayable(formEntity);
    },
    getAccountPayableById(accountPayableId) {
      return getAccountPayableByIdSvc.getAccountPayableById(accountPayableId);
    },
    updateAccountPayable(formEntity) {
      return updtAccountPayableSvc.updateAccountPayable(formEntity);
    },
    listActiveCostCenters(companyGroupId) {
      return listActiveCostCentersSvc.listActiveCostCenters(companyGroupId);
    },
    providerHasClassificationAccount(companyGroupId, providerId) {
      return providerHasClassificationAccountSvc.providerHasClassificationAccount(
        companyGroupId,
        providerId,
      );
    },
    getProviderById(providerId) {
      return getProviderByIdSvc.getProviderById(providerId);
    },
    providerHasBankingData(providerId) {
      return svc.providerHasBankingData(providerId);
    },
    searchClassificationAssessment(params) {
      return searchClassificationAssessmentService.searchClassificationAssessment(
        params,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchCostCenter(params) {
      return searchCostCenterService.searchCostCenter(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchProviderClassificationAccountByCompanyId(
      providerId,
      companyId,
      search,
    ) {
      return searchProviderClassificationAccountByCompanyIdService.searchProviderClassificationAccountByCompanyId(
        providerId,
        companyId,
        search,
      );
    },
    fetchCommercialProposalEnums() {
      return fetchCommercialProposalEnumsService.fetchCommercialProposalEnums();
    },
    generateCommercialProposalAttachment(params) {
      return generateCommercialProposalAttachmentService.generateCommercialProposalAttachment(
        params,
      );
    },
  };
}
