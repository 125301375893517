import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { EUserProfile } from "../../../../core/domain/entities/userEntity";
import { MakeCore } from "../../../../core/main/makeCore";
import { InputSearch } from "../../../../core/presentation/components/InputSearch";
import { useAllowedProfiles } from "../../../../core/presentation/hooks/useAllowedProfiles";
import { useDebounceTime } from "../../../../core/presentation/hooks/useDebounceTime";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { useTables } from "../../../../core/presentation/hooks/useTables";
import {
  IPFSEventEntity,
  PFSEventEntity,
} from "../../../../simpleTable/domain/entities/PSFEventEntity";
import { IResponseEntity } from "../../../../simpleTable/domain/entities/responseEntity";
import { ISimpleColumn } from "../../../../simpleTable/domain/entities/simpleColumnEntity";
import {
  ISimpleTableHandle,
  SimpleTable,
} from "../../../../simpleTable/presentation/components/SimpleTable";
import { IClassificationAccountByCompanyIdEntity } from "../../../domain/entities/classificationAccountByCompanyIdEntity";
import {
  GERAL_COMPANY_ID,
  ICompanyEntity,
} from "../../../domain/entities/companyEntity";
import { MakeClassificationAccounts } from "../../../main/makeClassificationAccounts";
import { useClassificationAccountsGrid } from "../../hooks/useClassificationAccountsGrid";
import { ClassificationAccountFormModal } from "../ClassificationAccountFormModal";

interface ClassificationAccountsTabProps {
  company: ICompanyEntity;
  useClassficationAccounts: MakeClassificationAccounts;
  useCore: MakeCore;
}

export function ClassificationAccountsTab({
  company,
  useClassficationAccounts,
  useCore,
}: ClassificationAccountsTabProps) {
  const { listClassificationAccountByCompanyId, toggleClassificationAccount } =
    useClassficationAccounts;

  const dialog = useSoulDialog();
  const { generatePayload } = useTables();
  const [isLoading, setIsLoading] = useState(false);

  /** Estados que controlam o input de filtro global */
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState<string>();

  /** Referência da tabela */
  const table = useRef<ISimpleTableHandle>(null);

  const { companyGroupList } = useCurrentCompanyGroup();

  const debounceTime = useDebounceTime();
  const allowedProfiles = useAllowedProfiles();

  const [classificationAccountsData, setClassificationAccountsData] =
    useState<IResponseEntity<IClassificationAccountByCompanyIdEntity[]>>();

  /** Armazena os dados da classificação selecionada */
  const [currentClassificationAccount, setCurrentClassificationAccount] =
    useState<Partial<IClassificationAccountByCompanyIdEntity>>();

  /**
   * Alterna o status de uma classificação contábil. (soft delete)
   */
  const toggle = useCallback(
    async (classificationAccountId: string, actionText: string) => {
      try {
        await toggleClassificationAccount(classificationAccountId);

        dialog.fire({
          icon: "success",
          title: "Feito!",
          text: `Classificação Contábil ${actionText} com sucesso!`,
        });

        table.current?.reload();
      } catch {
        dialog.close();
      }
    },
    [dialog, toggleClassificationAccount],
  );

  /**
   * Lida com a abertura dos modais de edição/criação de uma classificação
   * contábil.
   */
  const openClassificationAccountModal = useCallback(
    (info: IClassificationAccountByCompanyIdEntity) => {
      setCurrentClassificationAccount(info);
    },
    [],
  );
  /**
   * Lida com a ação de fechar o modal ativo.
   */
  const closeClassificationAccountModal = useCallback(
    (shouldRefresh: boolean | unknown) => {
      if (typeof shouldRefresh === "boolean" && shouldRefresh)
        table.current?.reload();

      setCurrentClassificationAccount(undefined);
    },
    [],
  );

  const { columns } = useClassificationAccountsGrid({
    company,
    toggle,
    openClassificationAccountModal,
  });

  /**
   * Lida com as mudanças no input de filtro global da tabela.
   */
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      debounceTime(() => {
        setGlobalFilter(e.target.value);
      }, 700);
    },
    [debounceTime],
  );

  const getListClassificationAccountByCompanyId = useCallback(
    async (_pfsEvent: IPFSEventEntity = new PFSEventEntity()) => {
      setIsLoading(true);
      try {
        const payload = generatePayload(_pfsEvent, columns as ISimpleColumn[]);
        const data = await listClassificationAccountByCompanyId(
          payload,
          company.id,
          search,
        );
        setClassificationAccountsData(data);
      } catch (err) {
        return;
      } finally {
        setIsLoading(false);
      }
    },
    [
      columns,
      company.id,
      generatePayload,
      listClassificationAccountByCompanyId,
      search,
    ],
  );

  /**
   * Permissão que verifica se o usuário pode adicionar uma classificação.
   * */
  const canAdd = useMemo(
    () =>
      companyGroupList.length &&
      allowedProfiles(
        EUserProfile.financialAccounting,
        EUserProfile.supervisor,
      ),
    [allowedProfiles, companyGroupList.length],
  );

  const isGeralCompany = company.id === GERAL_COMPANY_ID;

  const isCompanyActive = company.active;

  return (
    <article className="no-padding fill-height">
      <article className="accordion-tab-header">
        <InputSearch
          value={search}
          id="txt-search"
          data-testid="txt-search"
          onChange={handleSearchChange}
        />
        {canAdd && !isGeralCompany && isCompanyActive ? (
          <button
            id="btn-add"
            type="button"
            data-testid="btn-add"
            className="default-button"
            onClick={() => {
              setCurrentClassificationAccount({ id: "", active: true });
            }}
          >
            <FaPlus /> Adicionar Classificação Contábil
          </button>
        ) : null}
      </article>
      <ClassificationAccountFormModal
        useCore={useCore}
        isOpen={!!currentClassificationAccount}
        onClose={closeClassificationAccountModal}
        useClassificationAccounts={useClassficationAccounts}
        currentClassificationAccount={currentClassificationAccount}
        company={company}
      />
      <SimpleTable
        data={classificationAccountsData}
        tableRef={table}
        columns={columns}
        loading={isLoading}
        getList={getListClassificationAccountByCompanyId}
        globalFilter={globalFilter}
      />
    </article>
  );
}
