import { IApiService } from "../../../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../../../core/domain/usecases/getUserLocalUseCase";
import { IChangeInconsistentStatusContract } from "../../domain/contracts/changeInconsistentStatusContract";
import { IAccountReceivableFormEntity } from "../../domain/entities/accountReceivableFormEntity";

export class ChangeInconsistentStatusService
  implements IChangeInconsistentStatusContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  async changeInconsistentStatus(formValues: IAccountReceivableFormEntity) {
    const receivableId = formValues.id;

    const userEntity = this.getUserLocalService.get();
    const url = `/AccountsReceivable/${receivableId}/ChangeInconsistentStatus`;

    const updatedData = {
      value: formValues.value,
      companyId: formValues.company?.rawValue,
      customerId: formValues.customer?.rawValue,
      costCenterId: formValues.costCenter?.rawValue,
      projectId: formValues.project?.rawValue || null,
      documentNumber: formValues.documentNumber,
      paymentAccountId: formValues.paymentAccount?.rawValue,
      classificationUspId: formValues.classificationUsp?.rawValue || null,
      classificationAccountId: formValues.classificationAccount?.rawValue,
      classificationAssessmentId: formValues.classificationAssessment?.rawValue,
      competencyId: formValues.competency?.rawValue || null,
    };

    return this.api.put(url, updatedData, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
