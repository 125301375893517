import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IUpdateClassificationAccountContract } from "../../domain/contracts/updateClassificationAccountContract";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";
import { IClassificationAccountFormEntity } from "../../domain/entities/classificationAccountFormEntity";

export class UpdateClassificationAccountService
  implements IUpdateClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  updateClassificationAccount(
    classificationAccountForm: IClassificationAccountFormEntity,
    companyId: string,
    classificationAccountId: string,
  ) {
    const userEntity = this.getUserLocalService.get();
    const url = "/ClassificationAccounts";

    const payload = {
      ...classificationAccountForm,
      companyId,
      classificationAccountId,
    };

    return this.api.put<IClassificationAccountEntity>(url, payload, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
