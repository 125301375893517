import { IApiService } from "../../../core/data/services/apiService";
import { GetUserLocalService } from "../../../core/data/services/getUserLocalService";
import { IResponseEntity } from "../../../simpleTable/domain/entities/responseEntity";
import { IPayloadEntity } from "../../../simpleTable/domain/entities/simplePayloadEntity";
import { ICustomerEntity } from "../../domain/entities/customerEntity";
import { IListCustomersService } from "../../domain/usecases/listCustomersUseCase";

export class ListCustomersService implements IListCustomersService {
  constructor(
    private getUserLocalService: GetUserLocalService,
    private api: IApiService,
  ) {}

  async listCustomers(
    companyGroupId: string,
    payload: IPayloadEntity,
    actives = false,
  ): Promise<IResponseEntity<ICustomerEntity[]>> {
    const userEntity = this.getUserLocalService.get();

    const params = new URLSearchParams({
      body: JSON.stringify(payload),
    });

    const url = `/CompanyGroups/${companyGroupId}/Customers${
      actives ? "/Actives" : ""
    }?${params}`;

    const response = await this.api.get<IResponseEntity<ICustomerEntity[]>>(
      url,
      {
        headers: {
          Authorization: `Bearer ${userEntity?.token}`,
        },
      },
    );

    return response;
  }
}
