import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { SetServerSideService } from "../../core/data/services/setServerSideService";
import { GetClassificationAccountService } from "../data/services/getClassificationAccountService";
import { ListClassificationAccountByCompanyIdService } from "../data/services/listClassificationAccountByCompanyIdService";
import { ListClassificationAccountsService } from "../data/services/listClassificationAccountsService";
import { SaveClassificationAccountService } from "../data/services/saveClassificationAccountService";
import { SearchCompanyService } from "../data/services/searchCompanyService";
import { ToggleClassificationAccountService } from "../data/services/toggleClassificationAccountService";
import { UpdateClassificationAccountService } from "../data/services/updateClassificationAccountService";
import { IGetClassificationAccountContract } from "../domain/contracts/getClassificationAccountContract";
import { IListClassificationAccountByCompanyIdContract } from "../domain/contracts/listClassificationAccountByCompanyIdContract";
import { IListClassificationAccountsContract } from "../domain/contracts/listClassificationAccountsContract";
import { ISaveClassificationAccountContract } from "../domain/contracts/saveClassificationAccountContract";
import { ISearchCompanyContract } from "../domain/contracts/searchCompanyContract";
import { IToggleClassificationAccountContract } from "../domain/contracts/toggleClassificationAccountContract";
import { IUpdateClassificationAccountContract } from "../domain/contracts/updateClassificationAccountContract";

export type MakeClassificationAccounts = IListClassificationAccountsContract &
  IGetClassificationAccountContract &
  ISaveClassificationAccountContract &
  IUpdateClassificationAccountContract &
  IToggleClassificationAccountContract &
  ISearchCompanyContract &
  IListClassificationAccountByCompanyIdContract;

export function makeClassificationAccounts(
  api: IApiService,
): MakeClassificationAccounts {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const listClassificationAccountsService =
    new ListClassificationAccountsService(getUserLocalService, api);

  const getClassificationAccountsService = new GetClassificationAccountService(
    getUserLocalService,
    api,
  );
  const saveClassificationAccountsService =
    new SaveClassificationAccountService(getUserLocalService, api);

  const updateClassificationAccountsService =
    new UpdateClassificationAccountService(getUserLocalService, api);

  const toggleClassificationAccountsService =
    new ToggleClassificationAccountService(getUserLocalService, api);

  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    setServerSideService,
    getUserLocalService,
    api,
  );

  const listClassificationAccountByCompanyIdService =
    new ListClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  return {
    listClassificationAccounts(payload, companyGroupId, actives) {
      return listClassificationAccountsService.listClassificationAccounts(
        payload,
        companyGroupId,
        actives,
      );
    },
    getClassificationAccount(classificationAccountId) {
      return getClassificationAccountsService.getClassificationAccount(
        classificationAccountId,
      );
    },
    saveClassificationAccount(classificationAccountForm, companyId) {
      return saveClassificationAccountsService.saveClassificationAccount(
        classificationAccountForm,
        companyId,
      );
    },
    updateClassificationAccount(
      classificationAccountForm,
      companyId,
      classificationAccountId,
    ) {
      return updateClassificationAccountsService.updateClassificationAccount(
        classificationAccountForm,
        companyId,
        classificationAccountId,
      );
    },
    toggleClassificationAccount(classificationAccountId) {
      return toggleClassificationAccountsService.toggleClassificationAccount(
        classificationAccountId,
      );
    },
    searchCompany() {
      return searchCompanyService.searchCompany();
    },
    listClassificationAccountByCompanyId(payload, companyId, searchTerm) {
      return listClassificationAccountByCompanyIdService.listClassificationAccountByCompanyId(
        payload,
        companyId,
        searchTerm,
      );
    },
  };
}
