import { IApiService } from "../../../core/data/services/apiService";
import { IGetUserLocalService } from "../../../core/domain/usecases/getUserLocalUseCase";
import { IToggleClassificationAccountContract } from "../../domain/contracts/toggleClassificationAccountContract";
import { IClassificationAccountEntity } from "../../domain/entities/classificationAccountEntity";

export class ToggleClassificationAccountService
  implements IToggleClassificationAccountContract
{
  constructor(
    private getUserLocalService: IGetUserLocalService,
    private api: IApiService,
  ) {}

  toggleClassificationAccount(classificationAccountId: string) {
    const userEntity = this.getUserLocalService.get();
    const url = `/ClassificationAccounts/${classificationAccountId}/ChangeStatus`;

    return this.api.patch<IClassificationAccountEntity>(url, null, {
      headers: {
        Authorization: `Bearer ${userEntity?.token}`,
      },
    });
  }
}
