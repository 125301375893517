import { isAfter, isBefore, parse } from "date-fns";
import { InputMask } from "primereact/inputmask";
import { ChangeEvent, useCallback, useState } from "react";
import {
  Controller,
  FieldErrors,
  FormProvider,
  useForm,
} from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IClassificationAccountEntity } from "../../../../classificationAccount/domain/entities/classificationAccountEntity";
import { IClassificationAssessmentEntity } from "../../../../classificationAssessment/domain/entities/classificationAssessment";
import { IClassificationUspEntity } from "../../../../classificationUsp/domain/entities/classificationUspEntity";
import { Checkbox } from "../../../../core/presentation/components/Checkbox";
import { IconTooltip } from "../../../../core/presentation/components/IconTooltip";
import { InputPercentage } from "../../../../core/presentation/components/InputPercentage";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { useDateValidator } from "../../../../core/presentation/hooks/useDateValidator";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { IProviderEntity } from "../../../../provider/domain/entities/providerEntity";
import { MakeProvider } from "../../../../provider/main/makeProvider";
import { IPaymentAccountEntity } from "../../../../transactions/paymentAccountTransactions/domain/entities/paymentAccountEntity";
import { EGenerateTaxStatus } from "../../../domain/entities/companyTaxEntity";
import { ICompanyTaxFormEntity } from "../../../domain/entities/companyTaxFormEntity";
import { MakeCompany } from "../../../main/makeCompany";
import { useCompanyTaxForm } from "../../hooks/useCompanyTaxForm";
import { SearchField, SearchFieldName } from "../SearchField";
import { SearchProvidersField } from "../SearchProvidersField";
import { Container } from "./style";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import {
  EDaysOfWeek,
  daysOfWeek,
} from "../../../domain/entities/daysOfWeekEnum";

interface AutoCompleteFormValues {
  provider: IProviderEntity;
  paymentAccount: IPaymentAccountEntity;
  classificationUsp?: IClassificationUspEntity;
  classificationAccount?: IClassificationAccountEntity;
  classificationAsssesment: IClassificationAssessmentEntity;
}

export type CompanyTaxFormModalProps = {
  isOpen: boolean;
  companyId: string;
  taxesNames: string[];
  useCompany: MakeCompany;
  formMode: "EDIT" | "NEW";
  useProvider: MakeProvider;
  currentTax?: ICompanyTaxFormEntity;
  onClose: (taxForm?: ICompanyTaxFormEntity) => void;
};

export function CompanyTaxFormModal(props: CompanyTaxFormModalProps) {
  const {
    isOpen,
    onClose,
    formMode,
    companyId,
    useCompany,
    taxesNames,
    currentTax,
    useProvider,
  } = props;

  const {
    getProvider,
    searchProviders,
    getPaymentAccount,
    getClassificationUsp,
    searchPaymentAccounts,
    searchClassificationUsp,
    getClassificationAccount,
    getClassificationAssessment,
    searchClassificationAccounts,
    searchClassificationAssessments,
  } = useCompany;

  const [isLoading, setIsLoading] = useState(false);

  /** Armazena os valores de cada campo de autocomplete utilizado */
  const [autoCompleteValues, setAutoCompleteValues] =
    useState<AutoCompleteFormValues>();

  const dialog = useSoulDialog();
  const dateValidator = useDateValidator();
  const createTaxForm = useCompanyTaxForm();
  const {
    currentCompanyGroup: { id: companyGroupId },
  } = useCurrentCompanyGroup();

  const taxForm = useForm<ICompanyTaxFormEntity>({
    mode: "all",
    defaultValues: createTaxForm(),
  });

  const {
    watch,
    reset,
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
  } = taxForm;

  const nameWatcher = watch("name");
  const isUspWatcher = watch("isUsp");
  const endDateWatcher = watch("endDate");
  const startDateWatcher = watch("startDate");
  const providerWatcher = watch("providerId");
  const initialWeekWatcher = watch("fuspTax.initialWeekDay");

  const [isWeeklyTaxes, setIsWeeklyTaxes] = useState(() => {
    return formMode === "NEW" ? false : !!currentTax?.fuspTax;
  });

  /**
   * Lida com a abertura do modal de formulário. Carrega os valores dos inputs
   * designados. Ignora essa etapa caso seja uma adição da taxa.
   */
  const handleModalOpen = useCallback(async () => {
    /** Caso seja a criação de uma taxa, essa etapa é ignorada. */
    if (formMode === "NEW") return;
    if (!currentTax) return;

    const {
      isUsp,
      providerId,
      paymentAccountId,
      classificationUspId,
      classificationAccountId,
      classificationAssessmentId,
    } = currentTax;

    setIsLoading(true);

    try {
      const response = await Promise.all([
        getProvider(providerId),
        getPaymentAccount(paymentAccountId),
        getClassificationAccount(classificationAccountId),
        getClassificationAssessment(classificationAssessmentId),
        isUsp ? getClassificationUsp(classificationUspId) : undefined,
      ]);
      setAutoCompleteValues({
        provider: response[0],
        paymentAccount: response[1],
        classificationAccount: response[2],
        classificationAsssesment: response[3],
        classificationUsp: response[4],
      });

      reset(currentTax);
    } finally {
      setIsLoading(false);
    }
  }, [
    reset,
    formMode,
    currentTax,
    getProvider,
    getPaymentAccount,
    getClassificationUsp,
    getClassificationAccount,
    getClassificationAssessment,
  ]);

  /**
   * Verifica o nome digitado em relação a nomes existentes, evitando que taxas
   * com nomes iguais sejam criadas.
   */
  const verifyUniqueValue = useCallback(
    (value: string) => {
      const lowerCaseNames = taxesNames.map(n => n.toLowerCase());

      return !lowerCaseNames
        .filter(n =>
          formMode === "NEW" ? true : n !== nameWatcher.toLowerCase(),
        )
        .includes(value.toLowerCase());
    },
    [formMode, nameWatcher, taxesNames],
  );

  /**
   * Valida a data inicial baseada na data final. A ausência de valor no campo
   * de data final automaticamente valida o campo.
   */
  const validateStartDate = useCallback((value: string, endDate: string) => {
    if (!endDate) return true;
    if (value === endDate) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(endDate, "ddMMyyyy", new Date());

    return isBefore(date, dateToCompare);
  }, []);

  /**
   * Valida a data final baseada na data inicial.
   */
  const validateEndDate = useCallback((value: string, startDate: string) => {
    if (!value) return true;
    if (value === startDate) return true;

    const date = parse(value, "ddMMyyyy", new Date());
    const dateToCompare = parse(startDate, "ddMMyyyy", new Date());

    return isAfter(date, dateToCompare);
  }, []);

  /**
   * Define e realiza a busca no devido campo do formulário.
   * UGLY - Não é typesafe, além de utilizar um switch para definir o caso de uso.
   */
  const searchFieldFunction = useCallback(
    async (query: string, name: SearchFieldName) => {
      let request;
      switch (name) {
        case "classificationAssessmentId":
          request = searchClassificationAssessments(query, companyGroupId);
          break;
        case "classificationAccountId":
          request = searchClassificationAccounts(
            providerWatcher,
            companyGroupId,
          );
          break;
        case "paymentAccountId":
          request = searchPaymentAccounts(query, companyGroupId, companyId);
          break;
        case "classificationUspId":
          request = searchClassificationUsp(query, companyGroupId);
          break;
        default:
          request = { data: [] };
      }

      const response = await request;

      return response.data;
    },
    [
      companyId,
      companyGroupId,
      providerWatcher,
      searchPaymentAccounts,
      searchClassificationUsp,
      searchClassificationAccounts,
      searchClassificationAssessments,
    ],
  );

  /**
   * Reseta o campo de classificação contábil.
   */
  const resetSearchField = useCallback(
    (field: "classificationUspId" | "classificationAccountId") => {
      resetField(field, { defaultValue: "" });
      setAutoCompleteValues(v => {
        if (!v) return v;
        const autoCompleteField =
          field === "classificationUspId"
            ? "classificationUsp"
            : "classificationAccount";

        return {
          ...v,
          [autoCompleteField]: undefined,
        };
      });
    },
    [resetField],
  );

  /**
   * Lida com a submissão da nova taxa.
   */
  const handleSaveNewTax = useCallback(
    (value: ICompanyTaxFormEntity) => {
      dialog.fire({
        icon: "success",
        title: "Feito!",
        text: `Taxa ${
          formMode === "NEW" ? "adicionada" : "atualizada"
        } com sucesso!`,
      });
      setAutoCompleteValues(undefined);
      reset({});

      let fuspTax = null;

      if (isWeeklyTaxes) {
        const chargeWeekDay = Number(value.fuspTax?.chargeWeekDay);
        const initialWeekDay = Number(value.fuspTax?.initialWeekDay);

        fuspTax = {
          chargeWeekDay,
          initialWeekDay,
        };
      }

      const formValues = {
        ...value,
        fuspTax,
      };

      onClose(formValues);
    },
    [dialog, formMode, isWeeklyTaxes, onClose, reset],
  );

  /**
   * Lida com o fechamento do formulário de taxa.
   */
  const handleClose = useCallback(() => {
    setAutoCompleteValues(undefined);
    setIsWeeklyTaxes(false);
    reset({});
    onClose();
  }, [onClose, reset]);

  /**
   * O campo "Fim da cobrança do lançamento" sempre deve ser o valor inserido no
   * campo "Início da cobrança do lançamento" mais sete dias à frente.
   */
  const calculateFinalWeekDay = () => {
    if (typeof initialWeekWatcher !== "number" && !initialWeekWatcher) {
      return "";
    }

    const initialWeekDayNumber = Number(initialWeekWatcher);

    if (initialWeekDayNumber === EDaysOfWeek.Sunday) {
      return EDaysOfWeek.Saturday;
    }

    return initialWeekDayNumber - 1;
  };

  /**
   * Caso o usuário desmarque o checkbox de "Cobrar taxas semanalmente", uma
   * janela de diálogo aparece e reseta os valores do campo.
   */
  const handleIsWeeklyTaxesChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;

    if (!checked) {
      await dialog.fire({
        icon: "warning",
        title: "Atenção",
        html: (
          <>
            Se for <strong>retirada</strong> a seleção do campo Cobrar taxas
            semanalmente, as próximas taxas <strong>serão geradas</strong> no
            modelo padrão de geração automática.
          </>
        ),
      });

      resetField("fuspTax", { defaultValue: null });
    }

    setIsWeeklyTaxes(checked);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={!isLoading}
      onAfterOpen={handleModalOpen}
      className="react-modal-content"
      shouldCloseOnOverlayClick={!isLoading}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>{formMode === "NEW" ? "Adicionar Taxa" : "Atualizar Taxa"}</h4>
          {!isLoading ? (
            <button
              type="button"
              onClick={handleClose}
              id="btn-taxes-crud-cross"
              data-testid="btn-taxes-crud-cross"
              className="react-modal-close"
            >
              <IoMdClose />
            </button>
          ) : null}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!isLoading ? (
          <>
            <div className="info-card">
              Preencha os campos para
              {formMode === "NEW" ? " adicionar uma " : " atualizar a "}
              taxa
            </div>
            <div className="react-modal-body">
              <form
                id="company-new-tax-form"
                onSubmit={handleSubmit(handleSaveNewTax)}
              >
                <FormProvider {...taxForm}>
                  <div className="form-container">
                    <div className="form-row">
                      <label className="col-4 form-control">
                        <span>Taxa</span>
                        <input
                          id="txt-name"
                          placeholder="Taxa"
                          data-testid="txt-name"
                          className={errors?.name ? "isInvalid" : ""}
                          {...register("name", {
                            required: true,
                            validate: {
                              unique: v => verifyUniqueValue(v),
                            },
                          })}
                        />
                        <InvalidFeedback
                          condition={errors?.name?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={errors?.name?.type === "unique"}
                          message="Já temos uma taxa cadastrada com este nome"
                        />
                      </label>
                      <label className="col-2 form-control">
                        <span>Valor (%)</span>
                        <Controller
                          name="tax"
                          control={control}
                          rules={{ required: true, min: 1 }}
                          render={({ field }) => {
                            return (
                              <InputPercentage
                                {...field}
                                id="txt-tax"
                                precision={2}
                                placeholder="0,00%"
                                data-testid="txt-tax"
                                className={errors?.tax ? "isInvalid" : ""}
                              />
                            );
                          }}
                        />
                        <InvalidFeedback
                          condition={errors?.tax?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={errors?.tax?.type === "min"}
                          message="Esse campo deve possuir um valor maior que 1,00"
                        />
                      </label>
                      <label className="col-2 form-control">
                        <span>Data Inicial</span>
                        <Controller
                          name="startDate"
                          control={control}
                          rules={{
                            required: true,
                            validate: {
                              invalidDate: v => !dateValidator(v),
                              isBefore: v =>
                                validateStartDate(v, endDateWatcher),
                            },
                          }}
                          render={({ field }) => {
                            return (
                              <InputMask
                                unmask
                                {...field}
                                id="txt-startDate"
                                mask="99/99/9999"
                                placeholder="Data Inicial"
                                data-testid="txt-startDate"
                                className={errors?.startDate ? "isInvalid" : ""}
                              />
                            );
                          }}
                        />
                        <InvalidFeedback
                          condition={errors?.startDate?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                        <InvalidFeedback
                          condition={errors?.startDate?.type === "invalidDate"}
                          message="Data inválida"
                        />
                        <InvalidFeedback
                          condition={errors?.startDate?.type === "isBefore"}
                          message="Data inicial deve ser menor ou igual a final"
                        />
                      </label>
                      <div>
                        <label htmlFor="endDate" className="col-4 form-control">
                          <span className="smaller-label">
                            Data Final <small>(opcional)</small>{" "}
                            <IconTooltip
                              icon="pi pi-question-circle"
                              title="Data Final"
                              text="É um campo opcional. Se não preenchido, assumiremos que essa taxa não possui uma data definida como término de sua validade."
                            />
                          </span>
                        </label>
                        <div className="form-row gap">
                          <div className="col-6 form-control">
                            <Controller
                              name="endDate"
                              control={control}
                              rules={{
                                validate: {
                                  invalidDate: v => !dateValidator(v),
                                  isAfter: v =>
                                    validateEndDate(v, startDateWatcher),
                                },
                              }}
                              render={({ field }) => {
                                return (
                                  <InputMask
                                    unmask
                                    {...field}
                                    id="txt-endDate"
                                    mask="99/99/9999"
                                    placeholder="Data Final"
                                    data-testid="txt-endDate"
                                    className={
                                      errors?.endDate ? "isInvalid" : ""
                                    }
                                  />
                                );
                              }}
                            />
                            <InvalidFeedback
                              condition={
                                errors?.endDate?.type === "invalidDate"
                              }
                              message="Data inválida"
                            />
                            <InvalidFeedback
                              condition={errors?.endDate?.type === "isAfter"}
                              message="Data final deve ser maior ou igual a inicial"
                            />
                          </div>
                          <label className="col-4 form-control">
                            <Controller
                              name="isUsp"
                              control={control}
                              render={({ field }) => {
                                const onChange = (
                                  e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                  resetSearchField("classificationUspId");
                                  field.onChange(e);
                                };
                                const checkboxField = {
                                  ...field,
                                  onChange,
                                  checked: !!field?.value,
                                  value: field.value ? "true" : "false",
                                };
                                return (
                                  <Checkbox
                                    {...checkboxField}
                                    id="chk-isUsp"
                                    label="É USP?"
                                    data-testid="chk-isUsp"
                                  />
                                );
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <label className="col-8 form-control">
                        <span>Fornecedor</span>
                        <SearchProvidersField
                          readonly={false}
                          useProvider={useProvider}
                          isInvalid={!!errors.providerId}
                          searchProviders={searchProviders}
                          value={autoCompleteValues?.provider}
                          resetClassificationAccount={() => {
                            resetSearchField("classificationAccountId");
                          }}
                        />
                      </label>
                      <label className="col-4 form-control">
                        <span className="with-tooltip">
                          Gerada na
                          <IconTooltip
                            icon="pi pi-question-circle"
                            title="Gerada na"
                            text="É um campo obrigatório. Define em que momento essa taxa será gerada conforme ações ocorridas na Conta a Receber."
                          />
                        </span>
                        <select
                          defaultValue=""
                          id="sel-generateTax"
                          data-testid="sel-generateTax"
                          className={errors?.generateTax ? "isInvalid" : ""}
                          {...register("generateTax", {
                            required: true,
                            valueAsNumber: true,
                          })}
                        >
                          <option value="" hidden disabled>
                            Selecione...
                          </option>
                          <option value={EGenerateTaxStatus.Terminated}>
                            Baixa
                          </option>
                          <option value={EGenerateTaxStatus.NfIssue}>
                            Emissão da NF
                          </option>
                        </select>
                        <InvalidFeedback
                          condition={errors?.generateTax?.type === "required"}
                          message="Esse campo é obrigatório"
                        />
                      </label>
                    </div>
                    <div className="form-row">
                      <label className="col-6 form-control">
                        <span>Classificação Contábil</span>
                        <SearchField
                          disabled={!providerWatcher}
                          search={searchFieldFunction}
                          id="sel-classificationAccount"
                          name="classificationAccountId"
                          placeholder="Classificação Contábil"
                          isInvalid={!!errors?.classificationAccountId}
                          value={autoCompleteValues?.classificationAccount}
                        />
                        {!providerWatcher ? (
                          <em className="accounts-info">
                            Selecione um Fornecedor para habilitar esse campo
                          </em>
                        ) : null}
                      </label>
                      <label className="col-6 form-control">
                        <span>Conta de Pagamento</span>
                        <SearchField
                          id="sel-paymentAccount"
                          name="paymentAccountId"
                          search={searchFieldFunction}
                          placeholder="Conta de Pagamento"
                          isInvalid={!!errors?.paymentAccountId}
                          value={autoCompleteValues?.paymentAccount}
                        />
                      </label>
                    </div>
                    <div className="form-row">
                      <label className="col-6 form-control">
                        <span>Classificação de Rateio</span>
                        <SearchField
                          search={searchFieldFunction}
                          id="sel-classificationAssessment"
                          name="classificationAssessmentId"
                          placeholder="Classificação de Rateio"
                          isInvalid={!!errors?.classificationAssessmentId}
                          value={autoCompleteValues?.classificationAsssesment}
                        />
                      </label>
                      {isUspWatcher ? (
                        <label className="col-6 form-control">
                          <span>Classificação USP</span>
                          <SearchField
                            id="sel-classificationUsp"
                            name="classificationUspId"
                            search={searchFieldFunction}
                            placeholder="Classificação USP"
                            isInvalid={!!errors?.classificationUspId}
                            value={autoCompleteValues?.classificationUsp}
                          />
                        </label>
                      ) : null}
                    </div>

                    <div className="weekly-taxes-container">
                      <div className="form-row">
                        <label className="col-4 form-control">
                          <Checkbox
                            id="chk-weekly-taxes"
                            checked={isWeeklyTaxes}
                            data-testid="chk-weekly-taxes"
                            label="Cobrar taxas semanalmente"
                            onChange={handleIsWeeklyTaxesChange}
                          />
                        </label>
                      </div>
                      {isWeeklyTaxes && (
                        <div className="form-row">
                          <label className="col-4 form-control">
                            <span className="with-tooltip">
                              Início da cobrança do lançamento
                              <IconTooltip
                                icon="pi pi-question-circle"
                                title="Início da cobrança do lançamento"
                                text="É um campo obrigatório. Lançamentos a partir desse dia serão considerados para o acumulado de taxas."
                              />
                            </span>
                            <select
                              className={
                                (errors?.fuspTax as FieldErrors)?.initialWeekDay
                                  ? "isInvalid"
                                  : ""
                              }
                              {...register("fuspTax.initialWeekDay", {
                                required: isWeeklyTaxes,
                              })}
                            >
                              <option value="" disabled hidden>
                                Início da cobrança do lançamento
                              </option>
                              {daysOfWeek.map(dayOfWeek => {
                                return (
                                  <option
                                    key={`initialWeekDay-${dayOfWeek.key}`}
                                    value={dayOfWeek.key}
                                  >
                                    {dayOfWeek.value}
                                  </option>
                                );
                              })}
                            </select>
                            <InvalidFeedback
                              condition={
                                (errors?.fuspTax as FieldErrors)?.initialWeekDay
                                  ?.type === "required"
                              }
                              message="Esse campo é obrigatório"
                            />
                          </label>
                          <label className="col-4 form-control">
                            <span className="with-tooltip">
                              Fim da cobrança do lançamento
                              <IconTooltip
                                icon="pi pi-question-circle"
                                title="Fim da cobrança do lançamento"
                                text="É um campo obrigatório. Lançamentos até esse dia serão considerados para o acumulado de taxas."
                              />
                            </span>
                            <select disabled value={calculateFinalWeekDay()}>
                              <option value="" disabled hidden>
                                Fim da cobrança do lançamento
                              </option>
                              {daysOfWeek.map(dayOfWeek => {
                                return (
                                  <option
                                    key={`finalWeekDay-${dayOfWeek.key}`}
                                    value={dayOfWeek.key}
                                  >
                                    {dayOfWeek.value}
                                  </option>
                                );
                              })}
                            </select>
                          </label>
                          <label className="col-4 form-control">
                            <span className="with-tooltip">
                              Dia da semana da cobrança da taxa
                              <IconTooltip
                                icon="pi pi-question-circle"
                                title="Dia da semana da cobrança da taxa"
                                text="É um campo obrigatório. As taxas acumuladas com base no período informado nos campos anteriores serão lançadas nesse dia da semana seguinte."
                              />
                            </span>
                            <select
                              className={
                                (errors?.fuspTax as FieldErrors)?.chargeWeekDay
                                  ? "isInvalid"
                                  : ""
                              }
                              {...register("fuspTax.chargeWeekDay", {
                                required: isWeeklyTaxes,
                              })}
                            >
                              <option value="" disabled hidden>
                                Dia da semana da cobrança da taxa
                              </option>
                              {daysOfWeek.map(dayOfWeek => {
                                return (
                                  <option
                                    key={`chargeWeekDay-${dayOfWeek.key}`}
                                    value={dayOfWeek.key}
                                  >
                                    {dayOfWeek.value}
                                  </option>
                                );
                              })}
                            </select>
                            <InvalidFeedback
                              condition={
                                (errors?.fuspTax as FieldErrors)?.chargeWeekDay
                                  ?.type === "required"
                              }
                              message="Esse campo é obrigatório"
                            />
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </FormProvider>
              </form>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                onClick={handleClose}
                id="btn-taxes-crud-close"
                className="form-button red-bkg"
                data-testid="btn-taxes-crud-close"
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-taxes-crud-save"
                form="company-new-tax-form"
                data-testid="btn-taxes-crud-save"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Tudo certo
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
