import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { MouseEvent, useEffect, useMemo, useRef } from "react";
import {
  FaCaretDown,
  FaDatabase,
  FaFileArchive,
  FaFileExcel,
} from "react-icons/fa";
import { RiFilterOffFill } from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import { SoulDropdown } from "../../../../../core/presentation/components/SoulDropdown";
import { IPanoramaEntity } from "../../../../../advTable/domain/entities/panoramaEntity";
import { IFiltersValue } from "../../../domain/contracts/listPaymentAccountReportContract";
import { MakePaymentAccountReport } from "../../../main/makePaymentAccountReport";
import { useToolbar } from "../../hooks/useToolbar";
import {
  ContainerForm,
  FilterToolsContainer,
  PageToolsContainer,
} from "./styles";
import { IPFSEventEntity } from "../../../../../simpleTable/domain/entities/PSFEventEntity";
import { IAdvTableColumn } from "../../../../../advTable/domain/entities/advTableColumn";
import { PaymentAccountReportPanoramaFormModal } from "../PanoramaFormModal";
import { useDebounceTime } from "../../../../../core/presentation/hooks/useDebounceTime";
import { useOnClickOutside } from "../../../../../core/presentation/hooks/useOnClickOutside";
import { Balance } from "../../../../../core/presentation/components/Balance";

export interface IUseToolbarParams {
  isGridFiltered: boolean;
  filters: IFiltersValue;
  onFiltersChange(value: IFiltersValue): void;
  usePaymentAccountReport: MakePaymentAccountReport;
  onPanoramaChange(panorama: IPanoramaEntity, shouldUpdate?: boolean): void;
}

const EMPTY = [
  {
    rawValue: "EMPTY",
    label: "Nenhum registro encontrado",
  },
];

interface ToolbarProps {
  filters: IFiltersValue;
  pfsEvent: IPFSEventEntity;
  disableSheetExportation?: boolean;
  showClearButton?: boolean;
  balance: number | undefined;
  orderedColumnNames: string[];
  selectedColumns: IAdvTableColumn[];
  usePaymentAccountReport: MakePaymentAccountReport;
  onClearButtonClick(event: MouseEvent<HTMLButtonElement>): void;
  onPanoramaChange(panorama: IPanoramaEntity, shouldUpdate?: boolean): void;
  onFiltersChange(value: IFiltersValue): void;
  onExportButtonClick(): void;
  onExportFiles(): void;
  hasSelectedData: boolean;
  genarateAccountFile(): void;
}

export function Toolbar({
  filters,
  balance,
  pfsEvent,
  disableSheetExportation,
  selectedColumns,
  orderedColumnNames,
  usePaymentAccountReport,
  showClearButton = false,
  onExportButtonClick,
  onClearButtonClick,
  onPanoramaChange,
  onFiltersChange,
  onExportFiles,
  genarateAccountFile,
  hasSelectedData,
}: ToolbarProps) {
  const debounceTime = useDebounceTime();

  const tooltipContainerRef = useRef<HTMLButtonElement>(null);

  const isGridFiltered = useMemo(() => {
    const keys = Object.keys(pfsEvent.filters);

    if (keys.length === 1 && keys.includes("global")) {
      return false;
    }

    return !!keys.length;
  }, [pfsEvent.filters]);

  const {
    dates,
    loading,
    isEndDateValid,
    panoramaOptions,
    selectedPanorama,
    isStartDateValid,
    handleSavePanoramaButtonOnClick,
    handlePanoramaDropdownOnChange,
    renderPanoramaOption,
    handleOnFilter,
    handleOnChange,
    handleSubmit,
    setDates,
    reportFilterDate,
    setReportFilterDate,
    entityToFilter,
    handleEntityToFilterChange,
    selectedEntity,
    entityOptions,
    isPanoramaModalOpen,
    setIsPanoramaModalOpen,
    handleOnPanoramaSave,
  } = useToolbar({
    isGridFiltered,
    filters,
    onPanoramaChange,
    onFiltersChange,
    usePaymentAccountReport,
  });

  /**
   * Quando houverem dados selecionados na tabela, exibe o tooltip que orienta
   * a exportação de anexos.
   */
  useEffect(() => {
    if (tooltipContainerRef.current) {
      const method = hasSelectedData ? "show" : "hide";
      ReactTooltip[method](tooltipContainerRef.current);
    }
  }, [hasSelectedData]);

  /**
   * Responsável por ocultar o tooltip de orientação para exportação de anexos
   * caso o usuário clique em quaisquer elementos que não sejam checkboxes de
   * seleção da tabela.
   */
  useOnClickOutside(tooltipContainerRef, tar => {
    const target = tar as HTMLElement;

    if (target?.classList?.value?.includes("p-checkbox")) {
      return;
    }

    if (tooltipContainerRef.current) {
      ReactTooltip.hide(tooltipContainerRef.current);
    }
  });

  return (
    <ContainerForm onSubmit={handleSubmit}>
      <div className="page-tools">
        <PageToolsContainer>
          <div>
            <Dropdown
              id="sel-panorama"
              optionLabel="name"
              data-testid="sel-panorama"
              options={panoramaOptions}
              placeholder="Padrão do sistema"
              filterPlaceholder="Pesquise um panorama"
              emptyMessage="Nenhum registro encontrado."
              emptyFilterMessage="Nenhum registro encontrado."
              itemTemplate={renderPanoramaOption}
              panelStyle={{ width: "230px" }}
              filter
              value={selectedPanorama}
              onChange={handlePanoramaDropdownOnChange}
            />
            {loading.panoramas && <i className="pi pi-spin pi-spinner" />}
          </div>
          <div>
            <SoulDropdown
              toggler={
                <button
                  type="button"
                  id="btn-options"
                  data-testid="btn-options"
                  ref={tooltipContainerRef}
                  className="default-button options-context"
                  data-event="none"
                  data-place="left"
                  data-effect="solid"
                  data-event-off="click"
                  data-tip-disable={!hasSelectedData}
                  data-tip="Clique aqui para exportar anexo(s) selecionado(s)"
                >
                  <span>Opções</span>
                  <FaCaretDown className="context-dropdown-icon" />
                </button>
              }
            >
              <button
                type="button"
                id="btn-pano-save"
                className="dropdown-item"
                data-testid="btn-pano-save"
                onClick={handleSavePanoramaButtonOnClick}
              >
                <FaDatabase />
                <span title="Panorama">Salvar Panorama</span>
              </button>
              <button
                type="button"
                id="btn-export-excel"
                className="dropdown-item"
                data-testid="btn-export-excel"
                onClick={onExportButtonClick}
                disabled={disableSheetExportation}
              >
                <FaFileExcel />
                <span title="Exportar">Exportar para Excel</span>
              </button>
              <button
                type="button"
                onClick={onExportFiles}
                className="dropdown-item"
                id="btn-export-attachments"
                disabled={!hasSelectedData}
                data-testid="btn-export-attachments"
              >
                <FaFileArchive />
                <span title="Exportar anexos">Exportar anexos</span>
              </button>
              <button
                type="button"
                onClick={genarateAccountFile}
                className="dropdown-item"
                id="btn-generate-account-file"
                disabled={disableSheetExportation}
                data-testid="btn-generate-account-file"
              >
                <FaFileExcel />
                <span title="Exportar anexos">Gerar Arq. Contábil</span>
              </button>
            </SoulDropdown>
          </div>
        </PageToolsContainer>
      </div>
      <div className="filter-tools">
        <FilterToolsContainer>
          {showClearButton && (
            <div className="clr-filter-btn-wrapper">
              <button
                id="btn-clear"
                data-testid="btn-clear"
                className="default-button clear-filter-button"
                type="button"
                onClick={onClearButtonClick}
              >
                <RiFilterOffFill />
                Limpar filtros
              </button>
            </div>
          )}
          <div>
            <select
              id="sel-entity-type"
              value={entityToFilter}
              data-testid="sel-entity-type"
              onChange={handleEntityToFilterChange}
            >
              <option value="">Filtrar por...</option>
              <option value="project">Projetos</option>
              <option value="paymentAccount">Contas de Pagamento</option>
            </select>
          </div>
          {entityToFilter ? (
            <div className="cc-filter-dropdown-wrapper">
              <Dropdown
                filter
                showClear
                showFilterClear
                id="sel-entity"
                dataKey="rawValue"
                optionLabel="label"
                value={selectedEntity}
                data-testid="sel-entity"
                onChange={handleOnChange}
                emptyMessage="Nenhum registro encontrado."
                emptyFilterMessage="Nenhum registro encontrado."
                options={entityOptions?.length ? entityOptions : EMPTY}
                onFilter={e => {
                  debounceTime(() => {
                    handleOnFilter(e);
                  }, 700);
                }}
                filterPlaceholder={
                  entityToFilter === "project"
                    ? "Projeto"
                    : "Conta de Pagamento"
                }
                placeholder={
                  entityToFilter === "project"
                    ? "Projeto"
                    : "Conta de Pagamento"
                }
              />
              {loading.entityOptions && <i className="pi pi-spin pi-spinner" />}
            </div>
          ) : null}
          <div>
            <select
              id="sel-rule"
              data-tesid="sel-rule"
              value={reportFilterDate}
              onChange={e => {
                setReportFilterDate(e.target.value);
              }}
            >
              <option value="1">Regra PADRÃO</option>
              <option value="2">Regra FEALQ</option>
            </select>
          </div>
          <div className="start-dt-input-wrapper">
            <InputMask
              mask="99/99/9999"
              id="txt-start-date"
              value={dates.startDate}
              placeholder="Data inicial"
              data-testid="txt-start-date"
              className={!isStartDateValid ? "isInvalid" : ""}
              onChange={({ value }) => setDates({ ...dates, startDate: value })}
            />
          </div>
          <div className="end-dt-input-wrapper">
            <InputMask
              value={dates.endDate}
              id="txt-end-date"
              mask="99/99/9999"
              placeholder="Data final"
              data-testid="txt-end-date"
              className={!isEndDateValid ? "isInvalid" : ""}
              onChange={({ value }) => setDates({ ...dates, endDate: value })}
            />
          </div>
          <div className="show-btn-wrapper">
            <button
              type="submit"
              className="default-button filter-button"
              disabled={!isStartDateValid || !isEndDateValid}
            >
              Mostrar
            </button>
          </div>
        </FilterToolsContainer>
      </div>
      <div className="balance-tool">
        <Balance value={balance} label="Saldo" />
      </div>
      {isPanoramaModalOpen && (
        <PaymentAccountReportPanoramaFormModal
          pfsEvent={pfsEvent}
          isOpen={isPanoramaModalOpen}
          selectedColumns={selectedColumns}
          orderedColumnNames={orderedColumnNames}
          usePaymentAccountReport={usePaymentAccountReport}
          onPanoramaSave={handleOnPanoramaSave}
          onRequestClose={() => {
            setIsPanoramaModalOpen(false);
          }}
        />
      )}
    </ContainerForm>
  );
}
