import { StatusCodes } from "http-status-codes";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SoulRoutes } from "../../../../../../admin/domain/entities/soulRoutes";
import { useCurrentCompanyGroup } from "../../../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import {
  ApiService,
  IApiError,
  IApiService,
} from "../../../../../../core/data/services/apiService";
import { ViaCepApiService } from "../../../../../../core/data/services/viaCepApiService";
import { IEnum } from "../../../../../../core/domain/entities/enum";
import { EUserProfile } from "../../../../../../core/domain/entities/userEntity";
import { Card } from "../../../../../../core/presentation/components/Card/styles";
import { Page } from "../../../../../../core/presentation/components/Page/styles";
import { SoulSpinner } from "../../../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../../../core/presentation/hooks/useSoulDialog";
import { useUserLocal } from "../../../../../../core/presentation/hooks/useUserLocal";
import {
  MakeProvider,
  makeProvider,
} from "../../../../../../provider/main/makeProvider";
import {
  EFormMode,
  EPaymentRequestDestination,
  EPaymentRequestStatus,
} from "../../../domain/entities/paymentRequestEnums";
import {
  IPaymentRequestFormEntity,
  PaymentRequestFormEntity,
} from "../../../domain/entities/paymentRequestFormEntity";
import {
  MakePaymentRequestForm,
  makePaymentRequestForm,
} from "../../../main/makePaymentRequestForm";
import { FormSectionAccountInfo } from "../FormSectionAccountInfo";
import { FormSectionAditionalInfo } from "../FormSectionAditionalInfo";
import { FormSectionCompanyData } from "../FormSectionCompanyData";
import { FormSectionFooter } from "../FormSectionFooter";
import { SectionAssessment } from "../SectionAssessment";
import { SectionAttachment } from "../SectionAttachment";
import { Container } from "./styles";

interface IEditPaymentRequestPageState {
  mode: EFormMode;
  isLoading: boolean;
  options: {
    paymentMethods: IEnum[];
    documentStatuses: IEnum[];
    userDestinations: EPaymentRequestDestination[];
  };
}

interface IEditPaymentRequestPageProps {
  useProvider: MakeProvider;
  usePaymentRequestForm: MakePaymentRequestForm;
}

function EditPaymentRequestPage(props: IEditPaymentRequestPageProps) {
  const { usePaymentRequestForm, useProvider } = props;

  const {
    getPaymentRequest,
    fetchPaymentMethods,
    fetchDocumentStatus,
    fetchPaymentRequestsDestinations,
  } = usePaymentRequestForm;

  const [state, setState] = useState<IEditPaymentRequestPageState>({
    isLoading: true,
    mode: EFormMode.Edit,
    options: {
      paymentMethods: [],
      documentStatuses: [],
      userDestinations: [],
    },
  });

  const readonly = [EFormMode.EditAttachments, EFormMode.Readonly].includes(
    state.mode,
  );

  const dialog = useSoulDialog();
  const navigate = useNavigate();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const { paymentRequestId } = useParams<"paymentRequestId">();
  const {
    user: { profile },
  } = useUserLocal();

  const isRequesterOrManager = [
    EUserProfile.manager,
    EUserProfile.requester,
  ].includes(profile);

  const form = useForm<IPaymentRequestFormEntity>({
    mode: "all",
    defaultValues: new PaymentRequestFormEntity(),
  });

  const { reset } = form;

  /**
   * O modo do formulário é **estritamente** definido baseado no status da
   * solicitação.
   * - Para os status *Aprovado* e *Pago*, apenas são permitidas edições
   * nos anexos. Isso acontece para **todos** os perfis.
   * - Para o status *Solicitado*, os perfis *Supervisor* e *Gestor* podem
   * alterar os anexos. Os demais perfis, no entanto, não acessam solicitações
   * nesse status por essa rota de edição, mas sim pela rota `review`.
   * - Para o status *Não solicitado*, os perfis *Supervisor* e *Gestor* podem
   * editar a solicitação inteira. Os demais perfis **não podem** acessar
   * solicitações nesse status.
   * - Para o status *Reprovado*, os perfis *Supervisor* e *Gestor* podem
   * editar toda a solicitação. Os demais perfis podem apenas **visualizar**.
   * - Para o status *Cancelado*, apenas a visualização é permitida. Isso
   * acontece para **todos** os perfis.
   */
  const defineFormMode = useCallback(
    (requestStatus: EPaymentRequestStatus) => {
      const rejectedMode = isRequesterOrManager
        ? EFormMode.Edit
        : EFormMode.Readonly;

      const statusAndFormModes = {
        [EPaymentRequestStatus.Approved]: EFormMode.EditAttachments,
        [EPaymentRequestStatus.Paid]: EFormMode.EditAttachments,
        [EPaymentRequestStatus.Requested]: EFormMode.EditAttachments,
        [EPaymentRequestStatus.NotRequested]: EFormMode.Edit,
        [EPaymentRequestStatus.Rejected]: rejectedMode,
        [EPaymentRequestStatus.Canceled]: EFormMode.Readonly,
      };

      return statusAndFormModes[requestStatus];
    },
    [isRequesterOrManager],
  );

  const loadFormData = useCallback(async () => {
    if (!paymentRequestId) {
      navigate(SoulRoutes.PAYMENT_REQUESTS.path);
      return;
    }

    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const paymentRequest = await getPaymentRequest(
        paymentRequestId,
        currentCompanyGroup.id,
      );

      reset({ ...paymentRequest, id: paymentRequestId });

      const mode = defineFormMode(paymentRequest.status);
      const paymentMethodOptions = await fetchPaymentMethods();
      const documentStatusOptions = await fetchDocumentStatus();
      const userDestinations = await fetchPaymentRequestsDestinations();

      setState(prevState => ({
        ...prevState,
        mode,
        isLoading: false,
        options: {
          userDestinations,
          paymentMethods: paymentMethodOptions,
          documentStatuses: documentStatusOptions,
        },
      }));
    } catch (err) {
      const typedError = err as IApiError;

      if (!typedError?.response && err instanceof Error) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          text: err?.message || "Algo deu errado.",
        });
      } else if (typedError?.response?.status === StatusCodes.NOT_FOUND) {
        await dialog.fire({
          icon: "error",
          title: "Opa!",
          html: (
            <>
              Solicitação de pagamento não encontrada.
              <br />
              Por favor, verifique os dados e tente novamente.
            </>
          ),
        });
      }

      navigate(SoulRoutes.PAYMENT_REQUESTS.path, { replace: true });
    }
  }, [
    reset,
    dialog,
    navigate,
    defineFormMode,
    paymentRequestId,
    getPaymentRequest,
    fetchPaymentMethods,
    fetchDocumentStatus,
    currentCompanyGroup,
    fetchPaymentRequestsDestinations,
  ]);

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  const defineTitle = () => {
    if (state.isLoading) {
      return "Carregando...";
    }

    return state.mode === EFormMode.Readonly
      ? "Visualizando solicitação"
      : "Editando solicitação";
  };

  return (
    <Container>
      <Page>
        <header>
          <h4>{defineTitle()}</h4>
        </header>
        {state.isLoading && (
          <div className="loading-container">
            <SoulSpinner
              style={{
                width: "50px",
                height: "50px",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            />
          </div>
        )}
        {!state.isLoading && (
          <FormProvider {...form}>
            <article className="custom-article fill-height">
              <form className="form-container">
                <div className="row">
                  <div className="col-6">
                    <Card>
                      <section>
                        <FormSectionCompanyData
                          readonly={readonly}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <FormSectionAccountInfo
                          readonly={readonly}
                          options={state.options}
                          useProvider={useProvider}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <FormSectionAditionalInfo
                          readonly={readonly}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                        <SectionAttachment
                          isRequesterOrManager={isRequesterOrManager}
                          readonly={state.mode === EFormMode.Readonly}
                          usePaymentRequestForm={usePaymentRequestForm}
                        />
                      </section>
                    </Card>
                  </div>
                  <div className="col-6 right-col">
                    <SectionAssessment
                      readonly={readonly}
                      usePaymentRequestForm={usePaymentRequestForm}
                    />
                  </div>
                </div>
                <FormSectionFooter
                  mode={state.mode}
                  useProvider={useProvider}
                  isRequesterOrManager={isRequesterOrManager}
                  readonly={state.mode === EFormMode.Readonly}
                  usePaymentRequestForm={usePaymentRequestForm}
                  availableDestinations={state.options.userDestinations}
                />
              </form>
            </article>
          </FormProvider>
        )}
      </Page>
    </Container>
  );
}

interface IEditPaymentRequestPageFactoryProps {
  api: IApiService;
}

export function EditPaymentRequestPageFactory({
  api,
}: IEditPaymentRequestPageFactoryProps) {
  const { REACT_APP_SERVER_URL, REACT_APP_API_VERSION } = process.env;

  const baseUrl = `${REACT_APP_SERVER_URL}/api/v${REACT_APP_API_VERSION}`;
  const barcodeApi = new ApiService(baseUrl);

  const viaCepApi = new ViaCepApiService();

  const useProvider = makeProvider(api, viaCepApi);
  const useAccountsPayableForm = makePaymentRequestForm(api, barcodeApi);

  return (
    <EditPaymentRequestPage
      useProvider={useProvider}
      usePaymentRequestForm={useAccountsPayableForm}
    />
  );
}
