import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #f6f9fc;

  .p-datatable .p-paginator {
    display: flex;
    &.p-component {
      padding-right: unset;

      .current-page-report {
        padding-right: unset;
      }

      .rows-per-page-dropdown-wrapper {
        min-width: unset;
      }
    }
  }
  & > div {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;
