import { IApiService } from "../../core/data/services/apiService";
import { DecryptService } from "../../core/data/services/decryptService";
import { GetUserLocalService } from "../../core/data/services/getUserLocalService";
import { GetClassificationUspService } from "../data/services/getClassificationUspService";
import { ListClassificationUspService } from "../data/services/listClassificationsUspService";
import { SaveClassificationUspService } from "../data/services/saveClassificationUspService";
import { ToggleClassificationUspService } from "../data/services/toggleClassificationUspService";
import { UpdateClassificationUspService } from "../data/services/updateClassificationUspService";
import { ExportClassificationUspService } from "../data/services/exportClassificationUspService";
import { IGetClassificationUspContract } from "../domain/contracts/getClassificationUspContract";
import { ISaveClassificationUspContract } from "../domain/contracts/saveClassificationUspContract";
import { IUpdateClassificationUspContract } from "../domain/contracts/updateClassificationUspContract";
import { IExportClassificationUspContract } from "../domain/contracts/exportClassificationUspContract";
import {
  IListClassificationUspUseCase,
  ListClassificationUspUseCase,
} from "../domain/usecases/listClassificationsUspUseCase";
import {
  IToggleClassificationUspUseCase,
  ToggleClassificationUspUseCase,
} from "../domain/usecases/toggleClassificationUspUseCase";
import { SetServerSideService } from "../../core/data/services/setServerSideService";

export type MakeClassificationUsp = IListClassificationUspUseCase &
  IToggleClassificationUspUseCase &
  IGetClassificationUspContract &
  ISaveClassificationUspContract &
  IUpdateClassificationUspContract &
  IExportClassificationUspContract;

export function makeClassificationUsp(api: IApiService): MakeClassificationUsp {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const listClassificationUspService = new ListClassificationUspService(
    getUserLocalService,
    api,
  );

  const listClassificationUspUseCase = new ListClassificationUspUseCase(
    listClassificationUspService,
  );

  const toggleClassificationUspService = new ToggleClassificationUspService(
    getUserLocalService,
    api,
  );
  const toggleClassificationUspUseCase = new ToggleClassificationUspUseCase(
    toggleClassificationUspService,
  );

  const getClassificationUspService = new GetClassificationUspService(
    getUserLocalService,
    api,
  );

  const saveClassificationUspService = new SaveClassificationUspService(
    getUserLocalService,
    api,
  );

  const updateClassificationUspService = new UpdateClassificationUspService(
    getUserLocalService,
    api,
  );

  const exportClassificationUspService = new ExportClassificationUspService(
    getUserLocalService,
    api,
    setServerSideService,
  );

  return {
    listClassificationUsp(companyGroupId, payload, showActivesOnly) {
      return listClassificationUspUseCase.listClassificationUsp(
        companyGroupId,
        payload,
        showActivesOnly,
      );
    },
    toggleClassificationUsp(id) {
      return toggleClassificationUspUseCase.toggleClassificationUsp(id);
    },
    getClassificationUsp(id) {
      return getClassificationUspService.getClassificationUsp(id);
    },
    saveClassificationUsp(formValues) {
      return saveClassificationUspService.saveClassificationUsp(formValues);
    },
    updateClassificationUsp(formValues) {
      return updateClassificationUspService.updateClassificationUsp(formValues);
    },
    exportClassificationUsp(params) {
      return exportClassificationUspService.exportClassificationUsp(params);
    },
  };
}
